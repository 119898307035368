import reducerUtils from "../../../store/reducerUtils";
import produce from "immer";
import TeamActions from "./teamActionTypes";

const initialState = {
  team: null,
};

export const teamSelectors = {
  getTeam: (state) => state.team.team,
};

const onTeamLoaded = produce((draft, action) => {
  draft.team = action.team;
});

const handlers = {
  [TeamActions.TEAM_LOADED]: onTeamLoaded,
};

const teamReducer = reducerUtils.createReducer(initialState, handlers);

export default teamReducer;
