import React, { useState } from "react";
import "./ChatbotWidgetPage.scss";
import Spinner from "../../../assets/icons/Spinner";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { chatbotWidgetSelectors } from "./store/chatbotWidgetReducer";
import WidgetPromptControl from "./WidgetPromptControl";
import FirstMessage from "./FirstMessage";
import WidgetChatMessage from "./WidgetChatMessage";
import ChatbotReplyLoadingState from "./ChatbotReplyLoadingState";
import ChatbotReplyErrorState from "./ChatbotReplyErrorState";
import useChatbotWebsocket from "./ws/useChatbotWebsocket";
import ChatbotWidgetActions from "./store/chatbotWidgetActions";
import { scrollToWidgetChatBottom } from "../../../components/chats/details/chatScrollUtils";
import { useIntl } from "react-intl";
import Notify from "../../../components/shared/Notify";
import label from "../../../i18n/label";
import { loadWidgetChatbot } from "./store/chatbotActionCreators";
import backend from "../../../backend/backend";
import { getClientId, getDevInfo } from "./client/client_info";

import WelcomeMessage from "./messages/WelcomeMessage";
import NotFoundState from "./not-found/NotFoundState";

function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str));
}

function UnicodeDecodeB64(str) {
  return decodeURIComponent(atob(str));
}

const ChatbotWidgetPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { chatbotId } = useParams();
  const ws = useChatbotWebsocket();
  const chatbot = useSelector(chatbotWidgetSelectors.getChatbot);
  const chat = useSelector(chatbotWidgetSelectors.getChat);
  const messages = useSelector(chatbotWidgetSelectors.getMessages);
  const replyInProgress = useSelector(
    chatbotWidgetSelectors.getReplyInProgress
  );
  const replyError = useSelector(chatbotWidgetSelectors.getReplyError);
  const [notFoundError, setNotFoundError] = useState(false);

  const queryString = window.location.search;
  const paramsStr = new URLSearchParams(queryString).get("widget_params");

  const widgetParams = JSON.parse(UnicodeDecodeB64(paramsStr));

  console.log("Got widget params: ", widgetParams);

  useSelector(() => {
    if (!chatbot) {
      const clientId = getClientId();
      dispatch(
        loadWidgetChatbot(chatbotId, clientId, intl, null, (error) => {
          if (error.status === 404) {
            setNotFoundError(true);
          }
        })
      );
    }
  }, [dispatch, chatbotId, chatbot]);

  const onMessagePosted = async (message, onSuccess, onError) => {
    dispatch({
      type: ChatbotWidgetActions.POSTED_MESSAGE,
      message,
    });

    setTimeout(scrollToWidgetChatBottom, 600);

    ws.onmessage = function (event) {
      var message = event.data;
      const obj = JSON.parse(message);
      console.log("[WS] got ", obj);
      if (obj.error === true) {
        //Notify.error(obj.message);
        dispatch({
          type: ChatbotWidgetActions.MESSAGE_PART_ERROR,
          error: obj,
        });
        onError && onError(obj);
        return;
      }

      if (obj.type === "POST_CHAT_MESSAGE_REPLY_CHUNK" && obj.content) {
        dispatch({
          type: ChatbotWidgetActions.GOT_MESSAGE_PART,
          content: obj.content,
        });
        setTimeout(scrollToWidgetChatBottom, 300);
      }
      if (obj.type === "POST_CHAT_MESSAGE_REPLY_FINISH") {
        console.log("[WS] FINISHED");
        dispatch({
          type: ChatbotWidgetActions.FINISHED_MESSAGE_PARTS,
          response: obj.messages,
        });
        onSuccess && onSuccess();
        setTimeout(scrollToWidgetChatBottom, 300);
      }
      /*
      if (obj.content === null) {
        console.log("[WS] FINISHED");
      }
      */

      setTimeout(scrollToWidgetChatBottom, 250);
    };
    ws.onerror = function (error) {
      console.log("[WS] Error: " + error);
      Notify.error(label(intl, "error_unexpected"));
      dispatch({
        type: ChatbotWidgetActions.MESSAGE_PART_ERROR,
        error: error,
      });
      onError && onError(error);
    };

    try {
      let chatId = chat?.uid;
      if (!chatId) {
        const devInfo = getDevInfo();
        const metadata = {
          ...devInfo,
        };
        delete metadata["tz"];
        delete metadata["lang"];

        const newChat = await backend.createWidgetChat(chatbot.chatbot.uid, {
          clientId: widgetParams.clientId,
          pageUrl: widgetParams.pageUrl,
          tz: devInfo.tz,
          lang: devInfo.lang,
          metadata: JSON.stringify(metadata),
        });
        dispatch({
          type: ChatbotWidgetActions.CHAT_LOADED,
          chat: newChat,
        });
        chatId = newChat.uid;
      }
      ws.send(
        JSON.stringify({
          type: "POST_CHAT_MESSAGE",
          chatUid: chatId,
          role: "user",
          content: message,
        })
      );
    } catch (err) {
      console.error("Failed to process request", err);
      Notify.error(label(intl, "error_unexpected"));
    }
  };

  const loading = !chatbot || chatbot.chatbot.uid !== chatbotId;
  console.log("Chatbot widget page: ", loading, chatbot, chatbotId);

  if (notFoundError) {
    return (
      <div className="chatbot_widget_page">
        <NotFoundState />
      </div>
    );
  }

  return (
    <div className="chatbot_widget_page">
      <div className="chat_area">
        <div className="messages_container">
          <div className="messages_container_wrapper">
            {loading && <Spinner />}
            {!loading && messages.length === 0 && <WelcomeMessage />}
            {!loading && messages?.length > 0 && (
              <div className="messages_feed">
                {chatbot && chatbot.firstMessage && (
                  <FirstMessage chatbot={chatbot} />
                )}
                {messages &&
                  messages.map((item) => (
                    <WidgetChatMessage
                      key={item.uid ?? item.content}
                      message={item}
                    />
                  ))}
                {replyInProgress && <ChatbotReplyLoadingState />}
                {replyError && (
                  <ChatbotReplyErrorState replyError={replyError} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="prompt_control_container">
          <div className="prompt_control_max_width">
            {!loading && <WidgetPromptControl onSubmit={onMessagePosted} />}
          </div>
        </div>
      </div>
      {/*
      <div className="artifact_area">
        <ArtifactsEmptyState />
      </div>
      */}
    </div>
  );
};

export default ChatbotWidgetPage;
