import { MonitorIcon, SmartphoneIcon, TabletIcon } from "lucide-react";

/*
| ""
  | "desktop"
  | "smartphone"
  | "tablet"
  | "television"
  | "smart display"
  | "camera"
  | "car"
  | "console"
  | "portable media player"
  | "phablet"
  | "wearable"
  | "smart speaker"
  | "feature phone"
  | "peripheral"
*/

const DeviceTypeIcon = ({ name, color = "#000", size = "24" }) => {
  if (name === "desktop") {
    return <MonitorIcon color={color} size={size} />;
  } else if (name === "smartphone") {
    return <SmartphoneIcon color={color} size={size} />;
  } else if (name === "tablet") {
    return <TabletIcon color={color} size={size} />;
  }
  return null;
};

export default DeviceTypeIcon;
