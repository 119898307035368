const PromptActions = {
  CREATED: "prompt/created",
  LOADED: "prompt/loaded",
  UPDATED: "prompt/updated",
  LIST_LOADED: "prompts/loaded",
  LIST_CHANGED: "prompts/changed",
  VERSIONS_LOADED: "prompt-versions/loaded",
  VERSIONS_CHANGED: "prompt-versions/changed",
  VERSION_LOADED: "prompt-version/loaded",
};

export default PromptActions;
