import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";

import "./ChatPage.scss";
import { chatSelectors } from "../store/chatReducer";
import {
  deleteChat,
  loadChat,
  postChatMessage,
} from "../store/chatActionCreators";
import PromptControl from "../prompt-form/PromptControl";
import ChatMessage from "./ChatMessage";
import ReplyLoadingState from "./ReplyLoadingState";
import ModelMessage from "./ModelMessage";
import TrashIcon from "../../../assets/icons/TrashIcon";
import UploadIcon from "../../../assets/icons/UploadIcon";
import SettingsIcon from "../../../assets/icons/SettignsIcon";
import ChatSettingsDialog from "./settings/ChatSettingsDialog";
import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";
import ExternalLinkIcon from "../../../assets/icons/ExternalLinkIcon";
import ReplyErrorState from "./ReplyErrorState";
import {
  getDescriptorById,
  modelVendor,
} from "../../../model/model-providers/modelDescriptors";
import EnterKeyDialog from "./enter-key/EnterKeyDialog";
import { localKeysRegistry } from "../../../model/model-keys/modelKeysRegistry";
import CopyLinkDialog from "./copy-link/CopyLinkDialog";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";
import { scrollToChatBottom } from "./chatScrollUtils";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import authStore from "../../auth/AuthStore";
import useWebsocket from "../../shared/ws/useWebsocket";
import ChatActions from "../store/chatActions";
import backend from "../../../backend/backend";

const ChatPage = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { chatId } = useParams();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  const ws = useWebsocket();
  const chat = useSelector(chatSelectors.getChat);
  const messages = useSelector(chatSelectors.getMessages);
  const replyInProgress = useSelector(chatSelectors.getReplyInProgress);
  const replyError = useSelector(chatSelectors.getReplyError);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showLinkCopy, setShowLinkCopy] = useState(false);

  useEffect(() => {
    if (!chat || chat.uid !== chatId) {
      dispatch(loadChat(intl, workspaceId, chatId));
    }
  }, [dispatch, intl, workspaceId, chatId, chat]);

  useEffect(() => {
    console.log("WEBSOCKET API");
  }, [chatId]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        Notify.error(label(intl, "error.invalid_files_dropped"));
        return;
      }
      onFilesUpload(acceptedFiles);
    },
    maxSize: 100 * 1024 * 1024,
    noClick: true,
    noKeyboard: true,
    accept: ".pdf,.rtf,.doc,.docx,.xls,.xslx,.csv,.ppt,.pptx,.txt",
  });

  const onFilesUpload = (files) => {
    console.log("Uploading: ", files);
    setIsUploading(true);

    backend
      .uploadChatFile(workspaceId, chatId, files[0])
      .then((resp) => {
        setIsUploading(false);
        dispatch({
          type: ChatActions.MESSAGE_FILE_UPLOADED,
          message: resp,
        });
        setTimeout(scrollToChatBottom, 250);
        // dispatch(loadWorkspaceFonts(intl, workspaceId));
      })
      .catch(() => {
        Notify.error(label(intl, "error_file_upload"));
        setIsUploading(false);
      });
    /*
    setIsUploading(true);
    const requests = files.map((file, index) => {
      return backend.createWorkspaceFont(workspaceId, file);
    });
    Promise.all(requests)
      .then(() => {
        setIsUploading(false);
        dispatch(loadWorkspaceFonts(intl, workspaceId));
      })
      .catch(() => {
        Notify.error(label(intl, "error_font_upload"));
        setIsUploading(false);
      });
      */
  };

  const onMessagePosted = (message, onSuccess, onError) => {
    dispatch({
      type: ChatActions.POSTED_MESSAGE,
      message,
    });

    setTimeout(scrollToChatBottom, 300);

    ws.onmessage = function (event) {
      var message = event.data;
      const obj = JSON.parse(message);
      console.log("[WS] got ", obj);
      if (obj.error === true) {
        //Notify.error(obj.message);
        dispatch({
          type: ChatActions.MESSAGE_PART_ERROR,
          error: obj,
        });
        onError && onError(obj);
        return;
      }

      if (obj.type === "POST_CHAT_MESSAGE_REPLY_CHUNK" && obj.content) {
        dispatch({
          type: ChatActions.GOT_MESSAGE_PART,
          content: obj.content,
        });
      }
      if (obj.type === "POST_CHAT_MESSAGE_REPLY_FINISH") {
        console.log("[WS] FINISHED");
        dispatch({
          type: ChatActions.FINISHED_MESSAGE_PARTS,
          response: obj.messages,
        });
        onSuccess && onSuccess();
      }
      /*
      if (obj.content === null) {
        console.log("[WS] FINISHED");
      }
      */

      setTimeout(scrollToChatBottom, 250);
    };
    ws.onerror = function (error) {
      console.log("[WS] Error: " + error);
      Notify.error(label(intl, "error_unexpected"));
      dispatch({
        type: ChatActions.MESSAGE_PART_ERROR,
        error: error,
      });
      onError && onError(error);
    };

    ws.send(
      JSON.stringify({
        type: "POST_CHAT_MESSAGE",
        chatUid: chatId,
        workspaceUid: workspaceId,
        role: "user",
        content: message,
      })
    );
    /**
     
     */

    /*
    dispatch(
      postChatMessage(
        intl,
        workspaceId,
        chat,
        message,
        messages,
        () => {
          onSuccess && onSuccess();
          scrollToChatBottom();
        },
        () => {
          scrollToChatBottom();
          onError && onError();
        }
      )
    );
    */
  };

  const onDeleteConfirmed = () => {
    const onSuccess = () => {
      setShowDeleteConfirm(false);
      nav(linkBuilder.linkToChats());
    };
    dispatch(
      deleteChat(intl, workspaceId, chat.uid, onSuccess, () =>
        setShowDeleteConfirm(false)
      )
    );
  };

  const loading = !chat || chat.uid !== chatId;

  return (
    <>
      <div className="body_content card">
        <div className="content_header">
          {!loading && (
            <div className="content_header_body">
              <div className="breadcrumbs">
                <div
                  onClick={() =>
                    nav(
                      chat.parentFolderUid
                        ? linkBuilder.linkToChatFolder(chat.parentFolderUid)
                        : linkBuilder.linkToChats()
                    )
                  }
                >
                  <ChevronLeftIcon
                    size={16}
                    strokeWidth="2"
                    color="rgb(103, 116, 142)"
                  />
                  <Message
                    id={
                      chat.parentFolderUid ? "back_to_folder" : "back_to_chats"
                    }
                  />
                </div>
              </div>
              <div className="title_row">
                <>
                  <h1 className="chat_title">{chat?.name}</h1>

                  <div className="title_actions">
                    <div className="confirm_container">
                      {showDeleteConfirm && (
                        <>
                          <div
                            className="cover"
                            onClick={() => setShowDeleteConfirm(false)}
                          ></div>
                          <div className="confirm_panel">
                            <button
                              className="btn bg-gradient-primary btn-sm"
                              onClick={onDeleteConfirmed}
                            >
                              <Message id="confirm_delete" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      className="btn no-btn tile-action"
                      onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                    >
                      <TrashIcon size="16" color="#344767" />
                      <Message id="delete" />
                    </button>
                    <button
                      className="btn no-btn tile-action"
                      onClick={() => setShowSettings(true)}
                    >
                      <SettingsIcon size="16" color="#344767" />
                      <Message id="settings" />
                    </button>
                    <button
                      className="btn no-btn tile-action"
                      onClick={() => setShowLinkCopy(true)}
                    >
                      <ExternalLinkIcon
                        size="16"
                        color="#344767"
                        strokeWidth="2"
                      />
                      <Message id="share_chat" />
                    </button>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
        <div
          className={
            "dropzone-wrapper " + (isDragActive ? "dropzone-drag-active" : "")
          }
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="content_container">
            <div className="content_container_absolute">
              <div className="content_width_container">
                {loading && <Spinner />}
                {!loading && (
                  <div className="messages_feed">
                    {chat && (
                      <ModelMessage
                        chat={chat}
                        setShowSettings={setShowSettings}
                      />
                    )}
                    {messages.map((item) => (
                      <ChatMessage
                        key={item.uid ?? item.content}
                        message={item}
                      />
                    ))}
                    {replyInProgress && <ReplyLoadingState />}
                    {replyError && <ReplyErrorState replyError={replyError} />}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="chat_actions_toolbar">
            {!replyInProgress && (
              <>
                <div
                  className="toolbar_action"
                  onClick={() => !isUploading && open()}
                >
                  {!isUploading && <UploadIcon color="#344767" size={20} />}
                  {isUploading && <Spinner color="#344767" size={20} />}
                  <Message id="chat_toolbar_upload" />
                </div>
              </>
            )}
          </div>
          <div className="prompt_control_container">
            <div className="prompt_control_max_width">
              {!loading && <PromptControl onSubmit={onMessagePosted} />}
            </div>
          </div>
        </div>
      </div>

      {showSettings && (
        <ChatSettingsDialog
          chat={chat}
          onClose={() => setShowSettings(false)}
        />
      )}

      {showLinkCopy && (
        <CopyLinkDialog chat={chat} onClose={() => setShowLinkCopy(false)} />
      )}
    </>
  );
};

export default ChatPage;
