import { useNavigate } from "react-router-dom";
import "./ChatbotListPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import ChatbotLayout from "../shared/ChatbotLayout";
import { chatbotSelectors } from "../store/chatbotReducer";
import { useEffect, useState } from "react";
import { loadChatbotList } from "../store/chatbotActionCreators";
import Message from "../../../i18n/Message";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import ChatbotTile from "./ChatbotTile";
import CreateChatbotDialog from "../chatbot-create/CreateChatbotDialog";
import ZeroState from "../shared/ZeroState";
import MessageSquareIcon from "../../../assets/icons/MessageSquareIcon";

const ChatbotListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const chatbots = useSelector(chatbotSelectors.getChatbots);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    if (!chatbots) {
      dispatch(loadChatbotList(intl));
    }
  }, [chatbots, intl, dispatch]);

  return (
    <ChatbotLayout contentPanelClassName="chatbot_list_page">
      <div className="breadcrumbs">&nbsp;</div>
      <h1>
        <Message id="chatbot_menu_chatbots" />
      </h1>
      <div className="actions_panel">
        <button
          className="btn bg-gradient-primary"
          onClick={() => setShowCreate(true)}
        >
          <PlusIcon size="18" color="#fff" />
          <Message id="create_chatbot" />
        </button>
      </div>
      <div className="body_content card">
        <div className="content_header">
          <div></div>
        </div>
        <div className="campaign_tiles chatbot_tiles_list">
          {!chatbots && <Spinner />}
          {chatbots && chatbots.length === 0 && (
            <div className="zero_state_container">
              <ZeroState
                icon={<MessageSquareIcon color="#67748e" size="36" />}
                textKey="chatbot_list_zero_state_text"
                buttonKey="chatbot_list_zero_state_button"
                onClick={() => setShowCreate(true)}
              />
            </div>
          )}
          {chatbots &&
            chatbots.map((item) => (
              <ChatbotTile key={item.chatbot.uid} chatbot={item.chatbot} />
            ))}
        </div>
      </div>
      {showCreate && (
        <CreateChatbotDialog onClose={() => setShowCreate(false)} />
      )}
    </ChatbotLayout>
  );
};

export default ChatbotListPage;
