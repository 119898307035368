import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import GridIcon from "../../../assets/icons/GridIcon";
import Message from "../../../i18n/Message";
import HubLayout from "../layout/HubLayout";

import "./HubWelcomePage.scss";
import { linkToHubJobDetails, linkToHubModels } from "../../../routes";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useDispatch, useSelector } from "react-redux";
import { hubSelectors } from "../store/hubReducer";
import { useEffect } from "react";
import { loadAccountBalance, loadJobs } from "../store/hubActionCreators";
import { useIntl } from "react-intl";
import { cropText, formatDurationMs } from "../utils/hubUtils";
import ActivityIcon from "../../../assets/icons/ActivityIcon";

const HubWelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const balance = useSelector(hubSelectors.getAccountBalance);
  const jobs = useSelector(hubSelectors.getJobs);

  useEffect(() => {
    if (!balance) {
      dispatch(loadAccountBalance(intl));
    }
  }, [dispatch, balance, intl]);

  useEffect(() => {
    if (!jobs) {
      dispatch(loadJobs(intl));
    }
  }, [dispatch, jobs, intl]);

  const navigateToModels = () => {
    navigate(linkToHubModels());
  };

  function openCheckout() {
    var productList = [{ quantity: 1, path: "promtleo-credits-50" }];
    var session = { products: productList, reset: true, checkout: true };
    /*
    window.gAiknUser = user;
    window.gAiknProd = productPath;
    window.fastspring.builder.recognize({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
    */
    window.isCreditsOrder = true;
    window.fastspring.builder.push(session);
  }

  console.log("Hub welcome balance, ", balance);

  return (
    <HubLayout contentPanelClassName="hub_welcome_content">
      <h3>
        <Message id="hub_welcome_title" />
      </h3>
      <div className="dashboard_grid">
        <div className="left">
          <div className="card">
            <div className="jobs_toolbar">
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={navigateToModels}
              >
                <Message id="hub_select_model" />
              </button>
              <a
                href="/api-docs"
                className="btn no-btn btn-sm"
                target="_blank"
                rel="noopener"
              >
                <FileTextIcon color="#67748e" />
                <Message id="hub_read_docs" />
              </a>
            </div>
            {!jobs ||
              (jobs.length === 0 && (
                <>
                  <div className="zero_state_body">
                    <div className="message_wrapper">
                      <div className="message_icon_wrapper">
                        <GridIcon color="#67748e" size="36" />
                      </div>
                      <div className="message_text">
                        <Message id="hub_welcome_zero_state_text" />
                      </div>
                      <div className="message_button">
                        <button
                          className="btn bg-gradient-primary"
                          onClick={navigateToModels}
                        >
                          <Message id="hub_welcome_zero_state_button" />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            {jobs && (
              <div className="recent_jobs_section">
                <h4>
                  <ActivityIcon color="#344767" size="18" />
                  <Message id="hub_recent_jobs" />
                </h4>
                <div className="recent_jobs_list">
                  <div className="job_list_headers">
                    <div className="">
                      <Message id="hub_recent_jobs_header_model" />
                    </div>
                    <div className="">
                      <Message id="hub_recent_jobs_header_input" />
                    </div>
                    <div className="">
                      <Message id="hub_recent_jobs_header_status" />
                    </div>
                    <div className="">
                      <Message id="hub_recent_jobs_header_queued" />
                    </div>
                    <div className="">
                      <Message id="hub_recent_jobs_header_running" />
                    </div>
                    <div className="">
                      <Message id="hub_recent_jobs_header_created" />
                    </div>
                  </div>
                  {jobs.map((job) => {
                    let statusClass = "bg-gradient-secondary";
                    if (job.status === "FINISHED") {
                      statusClass = "bg-dark";
                    } else if (job.status === "RUNNING") {
                      statusClass = "bg-warning";
                    }

                    return (
                      <div
                        className="job_row border-radius-lg"
                        onClick={() =>
                          navigate(
                            linkToHubJobDetails(
                              job.model.split("/")[1],
                              job.uid
                            )
                          )
                        }
                      >
                        <div className="model_name">{job.model}</div>
                        <div className="input_column" title={job.input}>
                          {cropText(job.input)}
                        </div>
                        <div className="status">
                          <span className={"badge badge-sm " + statusClass}>
                            {job.status}
                          </span>
                        </div>
                        <div className="timing">
                          {formatDurationMs(job.queuedMs)}
                        </div>
                        <div className="timing">
                          {formatDurationMs(job.runningMs)}
                        </div>
                        <div className="created">
                          {dayjs.utc(job.dateCreated).fromNow()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="right">
          <div className="card">
            <div className="card_title balance_title">
              <Message id="hub_account_balance" />
            </div>
            <div className="balance_amount">
              {balance &&
                balance.balance.toLocaleString() +
                  " " +
                  (balance.currency === "EUR" ? "EUR" : "USD")}
            </div>
            <div className="add_balance_container">
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={openCheckout}
              >
                <PlusIcon size={18} color="#344767" />
                <Message id="hub_add_funds_btn" />
              </button>
            </div>
          </div>
          <div className="card other_models"></div>
        </div>
      </div>
    </HubLayout>
  );
};

export default HubWelcomePage;
