import { useNavigate } from "react-router-dom";
import "./PromptSnapshots.scss";
import { getVersionClass } from "./versionStatus";
import Message from "../../../i18n/Message";
import dayjs from "dayjs";
import Spinner from "../../../assets/icons/Spinner";
import EditIcon from "../../../assets/icons/EditIcon";
import Edit2Icon from "../../../assets/icons/Edit2Icon";

import CheckIcon from "../../../assets/icons/CheckIcon";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const PromptSnapshots = ({ versions, version, prompt, snapshotInProgress }) => {
  const nav = useNavigate();
  const linkBuilder = useLinkBuilder();
  const isCentered = !versions;

  return (
    <div className="card prompt_snapshots_panel">
      <div className="card_title">
        <Message id="prompt_snapshots_title" />
      </div>
      <div
        className="prompt_snapshots_body"
        style={{ alignItems: isCentered ? "center" : "flex-start" }}
      >
        {!versions && (
          <div className="spinner_container">
            <Spinner />
          </div>
        )}
        {versions && versions.length === 0 && (
          <div className="zero_state">
            <EditIcon size="48" color="#8392ab" strokeWidth="1" />
            <div className="zero_text">
              <Message id="prompt_snapshots_zero_state" />
            </div>
          </div>
        )}
        <div className="snapshot_list">
          {snapshotInProgress && (
            <div key="new_snapshot" className="snapshot_item">
              <div className="thumb_container" onClick={() => {}}>
                <div className={"thumb draft"}>
                  <Spinner />
                </div>
              </div>
              <div className="info" onClick={() => {}}>
                <div className="title">...</div>
                <div className="subtitle">...</div>
              </div>
            </div>
          )}
          {versions &&
            versions.map((item) => {
              const selected = item.uid === version.uid;
              return (
                <div
                  className={"snapshot_item " + (selected ? "selected" : "")}
                  key={item.uid}
                  onClick={() => {
                    if (!selected) {
                      nav(
                        linkBuilder.linkToPromptVersion(prompt.uid, item.uid)
                      );
                    }
                  }}
                >
                  <div className="thumb_container" onClick={() => {}}>
                    <div
                      className={
                        "thumb " +
                        getVersionClass(item) +
                        " " +
                        (selected ? "selected" : "") +
                        (item.approved ? " approved" : "")
                      }
                    >
                      {!item.approved && (
                        <Edit2Icon
                          color={selected ? "#fff" : "#8392ab"}
                          size="20"
                        />
                      )}
                      {item.approved && (
                        <CheckIcon size="20" color="#8392ab" strokeWidth="2" />
                      )}
                    </div>
                  </div>
                  <div className="info" onClick={() => {}}>
                    <div className="title">
                      {dayjs(item.dateCreated).format("LLL")}
                    </div>
                    <div className="subtitle">
                      {dayjs(item.dateCreated).fromNow()}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PromptSnapshots;
