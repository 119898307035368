import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import HubActions from "./HubActions";

const initialState = {
  data: {
    // dashboard
    accountBalance: null,
    jobs: null,
    // model jobs
    jobsModelId: null,
    modelJobs: null,
    // job details
    job: null,
  },
};

export const hubSelectors = {
  getAccountBalance: (state) => state.hub.data.accountBalance,
  getJobs: (state) => state.hub.data.jobs,
  getJobsModelId: (state) => state.hub.data.jobsModelId,
  getModelJobs: (state) => state.hub.data.modelJobs,
  getJob: (state) => state.hub.data.job,
};

const onAccountBalanceLoaded = produce((draft, action) => {
  draft.data.accountBalance = action.data;
  console.log(
    "onAccountBalanceLoaded: ",
    action.data,
    draft.data.accountBalance
  );
});

const onJobsLoaded = produce((draft, action) => {
  draft.data.jobs = action.jobs;
});

const onModelJobsLoaded = produce((draft, action) => {
  draft.data.modelJobs = action.jobs;
  draft.data.jobsModelId = action.modelId;
});

const onJobDetailsLoaded = produce((draft, action) => {
  draft.data.job = action.job;
});

const handlers = {
  [HubActions.ACCOUNT_BALANCE_LOADED]: onAccountBalanceLoaded,
  [HubActions.JOBS_LOADED]: onJobsLoaded,
  [HubActions.MODEL_JOBS_LOADED]: onModelJobsLoaded,
  [HubActions.JOB_DETAILS_LOADED]: onJobDetailsLoaded,
};

const hubReducer = reducerUtils.createReducer(initialState, handlers);

export default hubReducer;
