import "./ChatItem.scss";
import dayjs from "dayjs";
import MessageSquareIcon from "../../../../assets/icons/MessageSquareIcon";
import { useNavigate } from "react-router";
import modelDescriptors from "../../../../model/model-providers/modelDescriptors";
import { shortenName } from "./nameUtils";
import { useLinkBuilder } from "../../../shared/link-builder/linkBuilderContext";

const ChatItem = ({ chat }) => {
  const nav = useNavigate();
  const linkBuilder = useLinkBuilder();

  return (
    <div
      className="chat_list_item chat_chat_item"
      onClick={() => nav(linkBuilder.linkToChat(chat.uid))}
    >
      <div className="item_icon">
        <MessageSquareIcon
          color="rgb(145,155,173)"
          size={36}
          strokeWidth="1.5"
        />
        {/*<FileTextIcon color="rgb(145,155,173)" size={36} strokeWidth="1.5" />*/}
      </div>
      <div className="item_name">{shortenName(chat.name)}</div>
      <div className="model_name">
        {modelDescriptors.find((item) => item.id === chat.modelId).name}
      </div>
      <div className="item_date">{dayjs(chat.dateUpdated).fromNow()}</div>
    </div>
  );
};

export default ChatItem;
