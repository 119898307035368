import { useState } from "react";
import "./PromptControl.scss";

import PromptTextarea from "./textarea/PromptTextarea";
import PromptTemplates from "./templates/PromptTemplates";
import Message from "../../../i18n/Message";
import promptControlViewType from "./promptControlViewType";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import MessageSquareIcon from "../../../assets/icons/MessageSquareIcon";

const PromptControl = ({ onSubmit }) => {
  const [view, setView] = useState(promptControlViewType.TEXTAREA);
  const navigateToView = (newViewType) => {
    setView(newViewType);
  };
  return (
    <div className="prompt_control">
      <div className="prompt_control_body">
        <PromptTextarea view={view} onSubmit={onSubmit} />

        {view === promptControlViewType.TEMPLATES && (
          <PromptTemplates
            onFinished={() => setView(promptControlViewType.TEXTAREA)}
            navigateToView={navigateToView}
          />
        )}
      </div>
      <div className="view_navigation">
        <div
          className={
            "nav_item nav_textarea " +
            (view === promptControlViewType.TEXTAREA ? "selected" : "")
          }
          onClick={() => setView(promptControlViewType.TEXTAREA)}
        >
          <MessageSquareIcon
            color={
              view === promptControlViewType.TEXTAREA ? "#344767" : "#67717d"
            }
            size={20}
          />
          <Message id="prompt_control_nav_textarea" />
        </div>
        <div
          className={
            "nav_item nav_templates " +
            (view === promptControlViewType.TEMPLATES ? "selected" : "")
          }
          onClick={() => setView(promptControlViewType.TEMPLATES)}
        >
          <FileTextIcon
            color={
              view === promptControlViewType.TEMPLATES ? "#344767" : "#67717d"
            }
            size={20}
          />
          <Message id="prompt_control_nav_prompts" />
        </div>
        <div className="nav_item last"></div>
      </div>
    </div>
  );
};

export default PromptControl;
