import { useNavigate, useParams } from "react-router-dom";
import Message from "../../../i18n/Message";
import HubLayout from "../layout/HubLayout";
import "./HubModelDetails.scss";
import modelRegistry from "../models/modelRegistry";
import { linkToHubModels } from "../../../routes";
import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";
import CodeIcon from "../../../assets/icons/CodeIcon";
import ModelApiConsole from "./ModelApiConsole";
import { useDispatch, useSelector } from "react-redux";
import { hubSelectors } from "../store/hubReducer";
import { useEffect } from "react";
import { loadJobsByModel } from "../store/hubActionCreators";
import { useIntl } from "react-intl";
import HubModelJobs from "./HubModelJobs";
import GridIcon from "../../../assets/icons/GridIcon";

const HubModelDetails = () => {
  const nav = useNavigate();
  const intl = useIntl();
  const { handle } = useParams();
  const dispatch = useDispatch();
  const modelJobs = useSelector(hubSelectors.getModelJobs);
  const jobsModelId = useSelector(hubSelectors.getJobsModelId);
  const model = modelRegistry.find(handle);
  const modelId = model.vendor + "/" + model.handle;
  useEffect(() => {
    if (!modelJobs || (jobsModelId && jobsModelId !== modelId)) {
      dispatch(loadJobsByModel(modelId, intl));
    }
  }, [intl, modelId, dispatch, modelJobs, jobsModelId, model]);

  return (
    <HubLayout contentPanelClassName="hub_model_details">
      <div className="breadcrumbs">
        <div onClick={() => nav(linkToHubModels())}>
          <ChevronLeftIcon
            size={16}
            strokeWidth="2"
            color="rgb(103, 116, 142)"
          />
          <Message id="hub_model_breacrumbs_back" />
        </div>
      </div>
      <h1 className="model_name">
        <GridIcon color="#344767" size="32" />
        {model.title}
      </h1>
      <div className="model_id">
        {model.vendor}/{model.handle}
      </div>
      <div className="model_api_console_card card">
        <div className="api_console_title">
          <Message id="hub_model_console_title" />
        </div>
        <div className="api_console_help">
          <Message id="hub_model_console_help" />
        </div>
        <div className="tab_list">
          <div className="tab_item selected">
            <CodeIcon size={18} color="#344767" />
            <Message id="hub_model_integrate_api" />
          </div>
        </div>
        <ModelApiConsole model={model} />
      </div>
      <HubModelJobs
        model={model}
        jobs={jobsModelId && jobsModelId === modelId ? modelJobs : []}
      />
    </HubLayout>
  );
};

export default HubModelDetails;
