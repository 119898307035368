import { Bot } from "lucide-react";

import "./WelcomeMessage.scss";
import Message from "../../../../i18n/Message";

const WelcomeMessage = () => {
  return (
    <div className="chatbot_welcome_message_container">
      <div className="chatbot_welcome_message_wrapper">
        <div className="chatbot_welcome_message_icon_wrapper">
          {/*<FlowsIcon color="#67748e" size="36" />*/}
          <Bot size={48} color="#67748e" />
        </div>
        <div className="chatbot_welcome_message_text">
          <Message id="widget_welcome_message" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
