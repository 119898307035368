import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage as Message, useIntl } from "react-intl";

import label from "../../i18n/label";
import { changePassword } from "./store/actionCreators";
import KeyIcon from "../../assets/icons/KeyIcon";
import Notify from "../shared/Notify";

const SettingsPassword = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    current: "",
    new1: "",
    new2: "",
    match: true,
  });

  const currentChange = (e) => {
    setForm({ ...form, current: e.target.value });
  };
  const new1Change = (e) => {
    setForm({
      ...form,
      new1: e.target.value,
      match: e.target.value === form.new2,
    });
  };
  const new2Change = (e) => {
    setForm({
      ...form,
      new2: e.target.value,
      match: e.target.value === form.new1,
    });
  };
  const onSubmit = (e) => {
    e && e.preventDefault();
    if (form.new1 !== form.new2) {
      setForm({ ...form, match: false });
      return;
    }
    const newForm = { ...form, current: "", new1: "", new2: "", match: true };

    dispatch(
      changePassword(
        intl,
        form.current,
        form.new1,
        () => {
          setForm(newForm);
          Notify.success(label(intl, "password_change_success"));
        },
        (err) => {
          Notify.error(label(intl, "error_invalid_password"));
          /*if (err.code && err.code === "invalid_password") {
            Notify.error(label(intl, "error_invalid_password"));
          } else {

            Notify.error(label(intl, "error_unexpected"));
          }*/
        }
      )
    );
  };
  return (
    <div className="change_password_panel">
      <form onSubmit={onSubmit}>
        <div>
          <input
            type="password"
            className="form-control"
            name="oldPassword"
            autoComplete="current-password"
            id="password"
            required
            value={form.current}
            onChange={currentChange}
            placeholder={label(intl, "current_password")}
          />
        </div>
        <div>
          <input
            type="password"
            className="form-control"
            name="password1"
            id="password1"
            autoComplete="new-password"
            required
            value={form.new1}
            onChange={new1Change}
            placeholder={label(intl, "new_password")}
          />
        </div>
        <div>
          <input
            type="password"
            className="form-control"
            name="password2"
            id="password2"
            autoComplete="new-password"
            required
            value={form.new2}
            onChange={new2Change}
            placeholder={label(intl, "confirm_new_password")}
          />
        </div>
        {!form.match && form.new1 && form.new2 && (
          <div className="password_match">
            <Message id="passwords_must_match" />
          </div>
        )}
        <button type="submit" className="btn btn-outline-dark btn-sm">
          <KeyIcon color="rgb(52, 71, 103)" />
          <Message id="update_password" />
        </button>
      </form>
    </div>
  );
};
export default SettingsPassword;
