import SharedPageLayout from "./SharedPageLayout";
import copy from "copy-to-clipboard";

import "./SharedFormPage.scss";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadFormSubmission } from "../store/formActionCreators";
import { POST } from "../../../backend/httpAPI";

import { useEffect, useRef, useState } from "react";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import parseParams from "../../prompt/parseParams";
import Spinner from "../../../assets/icons/Spinner";
import CheckSquareIcon from "../../../assets/icons/CheckSquareIcon";
import Message from "../../../i18n/Message";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import CopyIcon from "../../../assets/icons/CopyIcon";
import FormActions from "../store/FormActions";
import ExternalLinkIcon from "../../../assets/icons/ExternalLinkIcon";
import {
  loadSharedForm,
  loadSharedPromptVersion,
} from "./store/sharedFormActionCreators";
import { sharedFormSelectors } from "./store/sharedFormReducer";
import SharedFormActions from "./store/SharedFormActions";

const SharedFormPage = () => {
  const { formId, workspaceId, submissionId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const form = useSelector(sharedFormSelectors.getForm);
  const version = useSelector(sharedFormSelectors.getPromptVersion);
  const [params, setParams] = useState([]);
  const [formData, setFormData] = useState({});
  //const [response, setResponse] = useState(null);
  const response = useSelector(sharedFormSelectors.getFormResponse);
  const [loading, setLoading] = useState(false);
  const submission = useSelector(sharedFormSelectors.getFormSubmission);
  const [showShareConfirm, setShowShareConfirm] = useState(false);
  const shareInputRef = useRef();
  const [showShareBottomConfirm, setShowShareBottomConfirm] = useState(false);
  const shareBottomInputRef = useRef();

  useEffect(() => {
    if (showShareConfirm) {
      shareInputRef.current?.select();
    }
  }, [showShareConfirm, shareInputRef]);

  useEffect(() => {
    if (showShareBottomConfirm) {
      shareBottomInputRef.current?.select();
    }
  }, [showShareBottomConfirm, shareBottomInputRef]);

  useEffect(() => {
    if (!form || form?.sharedUid !== formId) {
      dispatch(loadSharedForm(intl, workspaceId, formId));
    }
  }, [form, formId, workspaceId, dispatch, intl]);

  useEffect(() => {
    if (form && (!version || form?.versionUid !== version.uid)) {
      dispatch(
        loadSharedPromptVersion(
          intl,
          workspaceId,
          form.templateUid,
          form.versionUid
        )
      );
    }
  }, [version, form, workspaceId]);

  useEffect(() => {
    if (submissionId && (!submission || submission.uid !== submissionId)) {
      dispatch(
        loadFormSubmission(intl, workspaceId, formId, submissionId, (resp) => {
          const input = JSON.parse(resp.input);
          setParams(Object.keys(input.params));
          setFormData(
            Object.keys(input.params).reduce((data, paramKey) => {
              data[paramKey] = input.params[paramKey];
              return data;
            }, {})
          );
          const output = JSON.parse(resp.output);
          dispatch({
            type: FormActions.RESPONSE_LOADED,
            formResponse: { ...output, submissionUid: submissionId },
          });
        })
      );
    }
  }, [intl, workspaceId, formId, submissionId, submission]);

  useEffect(() => {
    if (version && version.uid === form.versionUid) {
      const params = parseParams(version.content);
      setParams(params);
    }
  }, [version, form]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const req = {
      params: formData,
    };
    setLoading(true);
    POST("/public/" + workspaceId + "/shared-forms/" + form.sharedUid, req)
      .then((res) => {
        //setResponse(res);
        dispatch({
          type: SharedFormActions.RESPONSE_LOADED,
          formResponse: res,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to send API request", err);
        Notify.error(label(intl, "api_console_server_error"));
      });
  };

  const onCopyLink = () => {
    copy(shareInputRef.current.value);
    Notify.success(label(intl, "share_form_copy_link_success"));
    setShowShareConfirm(false);
  };

  const onCopyBottomLink = () => {
    copy(shareBottomInputRef.current.value);
    Notify.success(label(intl, "share_form_copy_link_success"));
    setShowShareBottomConfirm(false);
  };

  const notReady = !form || !version;
  return (
    <SharedPageLayout bodyClassName="shared_form_page card">
      {notReady && (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {!notReady && (
        <div className="form_row">
          <div className="card_header">
            <h1>{form.name}</h1>
          </div>
          {form.description && (
            <div className="form_description">{form.description}</div>
          )}
          <div className="form_body">
            <div className="form_fields">
              {params && params.length > 0 && (
                <form onSubmit={onFormSubmit}>
                  <div className="form_fields_container">
                    {params.map((param) => {
                      return (
                        <input
                          key={param}
                          className="form-control"
                          type="text"
                          required
                          placeholder={param}
                          value={formData[param] ?? ""}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              [param]: e.target.value,
                            }))
                          }
                        />
                      );
                    })}
                    <div className="form_actions">
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-outline-primary btn-sm"
                      >
                        {loading ? (
                          <Spinner />
                        ) : (
                          <CheckSquareIcon color="#cb0c9f" />
                        )}
                        <Message
                          id={
                            response
                              ? "shared_form_submit_again_button"
                              : "shared_form_submit_button"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="between">
              <ChevronDownIcon />
            </div>
            <div className="preview_form_output">
              {!response && (
                <div className="response_placeholder_container">
                  <Message id="shared_form_response_placeholder" />
                </div>
              )}
              {response && (
                <>
                  <div className="response_label">
                    <label>
                      <Message id="shared_form_submission_result" />
                    </label>
                    <div className="share_button_wrapper">
                      <div className="share_button_container">
                        <button
                          className="btn no-btn tile-action"
                          onClick={() => {
                            setShowShareConfirm(!showShareConfirm);
                          }}
                        >
                          <ExternalLinkIcon size="16" color="#344767" />
                          <Message id="share_form_result" />
                        </button>
                        <div className="share_container">
                          {showShareConfirm && (
                            <>
                              <div
                                className="cover"
                                onClick={() => setShowShareConfirm(false)}
                              ></div>
                              <div className="confirm_panel">
                                <div>
                                  <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    ref={shareInputRef}
                                    value={
                                      window.location.protocol +
                                      "//" +
                                      window.location.host +
                                      "/app/f/" +
                                      workspaceId +
                                      "/" +
                                      form.uid +
                                      "/" +
                                      response.submissionUid
                                    }
                                  />
                                </div>
                                <button
                                  className="btn bg-gradient-primary btn-sm share_form_button"
                                  onClick={onCopyLink}
                                >
                                  <CopyIcon color="#fff" />
                                  <Message id="copy" />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="choice_container">
                    {response.choices?.map((item) => {
                      return (
                        <div key={item.text} className="choice">
                          {item.text}
                        </div>
                      );
                    })}
                  </div>
                  <div className="share_bottom_button_container">
                    <button
                      className="btn btn-outline-secondary tile-action"
                      onClick={() => {
                        setShowShareBottomConfirm(!showShareBottomConfirm);
                      }}
                    >
                      <ExternalLinkIcon size="16" color="#344767" />
                      <Message id="share_form_result" />
                    </button>
                    <div className="share_bottom_container">
                      {showShareBottomConfirm && (
                        <>
                          <div
                            className="cover"
                            onClick={() => setShowShareBottomConfirm(false)}
                          ></div>
                          <div className="confirm_panel">
                            <div>
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                ref={shareBottomInputRef}
                                value={
                                  window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/app/f/" +
                                  workspaceId +
                                  "/" +
                                  formId +
                                  "/" +
                                  response.submissionUid
                                }
                              />
                            </div>
                            <button
                              className="btn bg-gradient-primary btn-sm share_form_button"
                              onClick={onCopyBottomLink}
                            >
                              <CopyIcon color="#fff" />
                              <Message id="copy" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </SharedPageLayout>
  );
};

export default SharedFormPage;
