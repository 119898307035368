import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import RefreshCwIcon from "../../../../assets/icons/RefreshCwIcon";

import Message from "../../../../i18n/Message";
import EndpointActions from "../../store/EndpointActions";
import { endpointSelectors } from "../../store/endpointReducer";

import EndpointRequest from "./EndpointRequest";

import "./EndpointRequestList.scss";

const EndpointRequestList = ({ endpoint }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();
  const requests = useSelector(endpointSelectors.getEndpointRequests);
  const [showForm, setShowForm] = useState(false);

  const [version, setVersion] = useState("");
  const [showRecordingIndex, setShowRecordingIndex] = useState(null);
  const [showVideoIndex, setShowVideoIndex] = useState(null);
  const promptUid = prompt.uid;
  /*
  const onQuestionAdded = () => {
    const newQuestions = [...questions, { text: question }];
    setQuestions(newQuestions);
    setQuestion("");
  };

  const onQuestionChanged = (index, question) => {
    setQuestions((current) => {
      const newQuestions = current.map((item, idx) =>
        idx === index ? { ...item, text: question } : item
      );
      return newQuestions;
    });
  };

  const onQuestionDeleted = (index) => {
    setQuestions((current) => {
      return current.filter((_, idx) => idx !== index);
    });
  };

  const onRecordingFinished = (questionIdx, asset) => {
    console.log("onRecordingFinished", questionIdx, asset);
    setQuestions((current) => {
      const newQuestions = current.map((item, idx) =>
        idx === questionIdx ? { ...item, vid: asset.uid } : item
      );
      return newQuestions;
    });
    setShowRecordingIndex(null);
  };
  const onVideoQuestionDelete = (questionIdx) => {
    setQuestions((current) => {
      const newQuestions = current.map((item, idx) =>
        idx === questionIdx ? { ...item, vid: null } : item
      );
      return newQuestions;
    });
    setShowVideoIndex(null);
  };
*/

  return (
    <>
      <div className="requests_row card">
        <div className="card_header">
          <div className="card_title">
            <Message id="endpoint_requests_title" />
          </div>
          <div className="card_actions">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() =>
                dispatch({ type: EndpointActions.REQUESTS_CHANGED })
              }
            >
              <RefreshCwIcon size="18" color="#cb0c9f" />
              <Message id="refresh_requests" />
            </button>
          </div>
        </div>
        <div className="prompt_versions_body">
          {(requests ?? []).map((request, idx) => (
            <EndpointRequest key={version.uid} request={request} index={idx} />
          ))}
        </div>
      </div>
    </>
  );
};

export default EndpointRequestList;
