import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

import "./ResetPasswordPage.scss";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import backend from "../../../backend/backend";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../assets/icons/Spinner";

const ResetPasswordPage = ({ loadingState = false }) => {
  const intl = useIntl();
  const nav = useNavigate();
  const { tokenId } = useParams();
  const [passwordForm, setPasswordForm] = useState(null);
  const [token, setToken] = useState(null);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [tokenError, setTokenError] = useState("");

  useEffect(() => {
    backend
      .getResetToken(tokenId)
      .then((token) => {
        setToken(token);
      })
      .catch(() => {
        setTokenError(label(intl, "reset_password_finish_invalid_token"));
      });
  }, [tokenId, intl]);

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const onButtonClicked = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (password1 && password2 && passwordForm.checkValidity()) {
      if (password1 !== password2) {
        setError(label(intl, "reset_password_error_dont_match"));
        return;
      }
      backend
        .finishReset(tokenId, { password: password1 })
        .then(() => {
          setSuccess(label(intl, "reset_password_finish_success"));
        })
        .catch((error) => {
          console.error("Error in reset", error);
          Notify.error(label(intl, "error_load"));
        });
    } else {
      passwordForm.reportValidity();
    }
  };

  return (
    <div className="forgot_password_page">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      <div className="container">
        <div className="header">
          <h5>
            <Message id="reset_password_title" />
          </h5>
          {success && <div className="success_message">{success}</div>}
          {error && <div className="error_message">{error}</div>}
        </div>
        {success && (
          <div className="field">
            <button type="button" className="btn" onClick={() => nav("/")}>
              <Message id="login_button" />
            </button>
          </div>
        )}
        {tokenError && (
          <>
            <div className="error_message">{tokenError}</div>
            <div className="field">
              <button
                type="button"
                className="btn"
                onClick={() => nav("/forgot-password")}
              >
                <Message id="forgot_password_title" />
              </button>
            </div>
          </>
        )}
        {!token && !tokenError && <Spinner />}

        {!loadingState && token && !success && (
          <div className="form">
            <form
              onSubmit={onPasswordSubmit}
              ref={(elem) => setPasswordForm(elem)}
            >
              <div className="field">
                <input
                  type="password"
                  autoComplete="current-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  placeholder={label(intl, "reset_password_password1")}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  autoComplete="current-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  placeholder={label(intl, "reset_password_password2")}
                />
              </div>

              <div style={{ position: "relative" }}>
                <input
                  type="submit"
                  name="submit"
                  style={{ position: "absolute", left: "-10000px" }}
                />
              </div>
            </form>

            <div className="field">
              <button type="button" className="btn" onClick={onButtonClicked}>
                <Message id="reset_password_button" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
