import Message from "../../../i18n/Message";
import "./ErrorState.scss";

const ErrorState = ({ icon, textKey }) => {
  return (
    <div className="error_state_container">
      <div className="error_state_wrapper">
        <div className="error_state_icon_wrapper">
          {/*<FlowsIcon color="#67748e" size="36" />*/}
          {icon}
        </div>
        <div className="error_state_text">
          <Message id={textKey} />
        </div>
      </div>
    </div>
  );
};

export default ErrorState;
