import { useCallback, useEffect, useState } from "react";
import backend from "../../../../backend/backend";
import label from "../../../../i18n/label";
import Notify from "../../../shared/Notify";
import "./PromptTemplates.scss";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import FileTextIcon from "../../../../assets/icons/FileTextIcon";
import debounce from "lodash/debounce";
import Message from "../../../../i18n/Message";
import Spinner from "../../../../assets/icons/Spinner";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import CreatePromptDialog from "../../../prompt/list/CreatePromptDialog";
import promptControlViewType from "../promptControlViewType";
import { useWorkspaceId } from "../../../shared/workspace/workspaceContext";

export const loadApprovedPrompts = (
  intl,
  workspaceId,
  keyword,
  onSuccess,
  onError
) => {
  backend
    .getApprovedPrompts(workspaceId, keyword)
    .then((data) => {
      console.log("loaded approved prompts", data);
      onSuccess && onSuccess(data);
    })
    .catch((err) => {
      onError && onError(err);
      console.error("Failed to load approved prompts", err);
      Notify.error(label(intl, "error_unexpected"));
    });
};

const PromptTemplates = ({ navigateToView }) => {
  const intl = useIntl();
  const workspaceId = useWorkspaceId();
  const [prompts, setPrompts] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [keywordElem, setKeywordElem] = useState(null);
  const [newPromptDialog, setNewPromptDialog] = useState(false);

  useEffect(() => {
    loadApprovedPrompts(intl, workspaceId, "", (data) => {
      setPrompts(data);
    });
  }, [intl, workspaceId]);

  useEffect(() => {
    if (keywordElem) {
      keywordElem.focus();
    }
  }, [keywordElem]);

  const searchHandler = useCallback(
    debounce((workspaceId, searchKeyword) => {
      loadApprovedPrompts(intl, workspaceId, searchKeyword, (data) => {
        setPrompts(data);
      });
    }, 220),
    [setPrompts, intl]
  );

  const onKeywordChanged = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    searchHandler(workspaceId, newKeyword);
  };

  const onPromptClicked = (item) => {
    window.gAppendPromptToMessage(item);
    navigateToView(promptControlViewType.TEXTAREA);
  };
  return (
    <div className="prompt_form_templates">
      <div className="template_search">
        <div className="search_field">
          <input
            type="text"
            ref={(item) => setKeywordElem(item)}
            value={keyword}
            onChange={onKeywordChanged}
            className="form-control"
            placeholder="Search prompts..."
          />
        </div>
        <div className="add_button">
          <button
            className="btn btn-outline-dark btn-sm"
            type="button"
            onClick={() => setNewPromptDialog(true)}
          >
            <PlusIcon color="rgb(103, 116, 142)" />
            <Message id="prompt_control_new_prompt" />
          </button>
        </div>
      </div>
      <div className="prompt_template_list">
        {keyword && !prompts?.length && (
          <div className="no_matches_state">
            <FileTextIcon size={24} color="rgb(103, 116, 142)" />
            <Message id="no_matches_state" values={{ keyword }} />
          </div>
        )}
        {!keyword && !prompts && (
          <div className="template_spinner_container">
            <Spinner />
          </div>
        )}
        {prompts?.map((item) => {
          return (
            <div
              className="template_item"
              onClick={() => onPromptClicked(item)}
            >
              <div className="template_icon">
                <FileTextIcon size={22} color="rgb(103, 116, 142)" />
              </div>
              <div className="name">{item.promptName}</div>
              <div className="content">{item.content}</div>
            </div>
          );
        })}
      </div>
      {newPromptDialog && (
        <CreatePromptDialog onClose={() => setNewPromptDialog(false)} />
      )}
    </div>
  );
};

export default PromptTemplates;
