import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import FlowActions from "./FlowActions";

const initialState = {
  data: {
    list: null,
    listCounter: 0,

    flow: null,
  },
};

export const flowSelectors = {
  getList: (state) => state.flows.data.list,
  getListCounter: (state) => state.flows.data.listCounter,
  getFlow: (state) => state.flows.data.flow,
};

const onLoadList = produce((draft, action) => {
  draft.data.list = action.list;
});

const onListChanged = produce((draft) => {
  draft.data.listCounter++;
});

const onFlowLoaded = produce((draft, action) => {
  const obj = action.flow;
  draft.data.flow = obj;
});

const onFlowUpdated = produce((draft, action) => {
  const obj = action.flow;
  draft.data.flow = obj;
  draft.data.listCounter++;
});

const handlers = {
  [FlowActions.LIST_LOADED]: onLoadList,
  [FlowActions.LIST_CHANGED]: onListChanged,
  [FlowActions.LOADED]: onFlowLoaded,
  [FlowActions.UPDATED]: onFlowUpdated,
};

const flowReducer = reducerUtils.createReducer(initialState, handlers);

export default flowReducer;
