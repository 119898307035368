import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CheckIcon from "../../assets/icons/CheckIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import Spinner from "../../assets/icons/Spinner";
import UserPlusIcon from "../../assets/icons/UserPlusIcon";
import label from "../../i18n/label";
import Message from "../../i18n/Message";
import ModalDialog from "../shared/ModalDialog";

const InviteMemberDialog = ({ onClose, onInvite }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [input, setInput] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formElem, setFormElem] = useState(null);

  useEffect(() => {
    if (input) input.focus();
  }, [input]);

  const onConfirm = () => {
    const valid = input.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;

    setLoading(true);
    onInvite({ email, isAdmin }, onClose, () => setLoading(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <ModalDialog onClose={onClose} className="invite_member_dialog">
      <h1>
        <Message id="invite_member_dialog_title" />
      </h1>
      <div className="dialog_text">
        <Message id="invite_member_dialog_text" />
      </div>
      <div className={"email_form " + (loading ? "loading" : "")}>
        {!loading && <EmailIcon color="rgb(117,117,117)" strokeWidth="2" />}
        {loading && <CheckIcon color="#155724" strokeWidth="2" />}
        <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
          <input
            type="email"
            ref={(val) => setInput(val)}
            required
            placeholder={label(intl, "invitation_email_placeholder")}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            readOnly={loading}
          />
        </form>
      </div>
      <div className="admin_role_checkbox">
        <input
          name="is_admin"
          type="checkbox"
          checked={isAdmin}
          onChange={() => setIsAdmin(!isAdmin)}
        />
        <span onClick={() => setIsAdmin(!isAdmin)}>
          <Message id="invite_is_admin" />
        </span>
      </div>
      <div className="buttons">
        <button className="btn bg-gradient-dark" onClick={onConfirm}>
          {loading ? <Spinner color="#fff" /> : <UserPlusIcon color="#fff" />}
          <Message id="send_invitation" />
        </button>
        <button className="btn no-btn" onClick={onClose}>
          <Message id="cancel" />
        </button>
      </div>
    </ModalDialog>
  );
};

export default InviteMemberDialog;
