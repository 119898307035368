import WorkspaceLayout from "../shared/layout/WorkspaceLayout";

import AreaLayout from "../shared/layout/AreaLayout";
import LinkBuilder from "../shared/link-builder/linkBuilder";
import LinkBuilderContext from "../shared/link-builder/linkBuilderContext";
import { useEffect, useState } from "react";
import backend from "../../backend/backend";
import Notify from "../shared/Notify";
import { useIntl } from "react-intl";
import label from "../../i18n/label";
import WorkspaceContext from "../shared/workspace/workspaceContext";
import { useParams } from "react-router-dom";

const WorkspacePageWrapper = ({ area, children, contentClassName = "" }) => {
  const intl = useIntl();

  const { workspaceId } = useParams();
  const [loadedWorkspaceId, setLoadedWorkspaceId] = useState(null);
  const [subodmain, setSubdomain] = useState(null);

  useEffect(() => {
    if (!loadedWorkspaceId && subodmain) {
      backend
        .lookupWorkspaceBySubdomain(subodmain)
        .then((resp) => setLoadedWorkspaceId(resp.uid))
        .catch((err) => {
          console.error("Failed to lookup workspace", err);
          Notify.error(label(intl, "error_unexpected"));
        });
    }
  }, [intl, loadedWorkspaceId, subodmain]);

  if (!area) {
    const linkBuilder = new LinkBuilder(false, workspaceId);
    return (
      <WorkspaceContext.Provider value={{ uid: workspaceId }}>
        <LinkBuilderContext.Provider value={linkBuilder}>
          <WorkspaceLayout contentPanelClassName={contentClassName}>
            {children}
          </WorkspaceLayout>
        </LinkBuilderContext.Provider>
      </WorkspaceContext.Provider>
    );
  } else {
    console.log("Wrapper 1");
    const matches = window.location.host.match(
      /([a-zA-Z0-9-_]+)\.(promptleo-en.com|promptleo.com)/
    );
    const linkBuilder = new LinkBuilder(true, null, matches[1]);
    console.log("Found match ", matches[1]);
    if (!loadedWorkspaceId) {
      if (!subodmain || subodmain !== matches[1]) {
        setSubdomain(matches[1]);
      }
      return null;
    }
    return (
      <WorkspaceContext.Provider value={{ uid: loadedWorkspaceId }}>
        <LinkBuilderContext.Provider value={linkBuilder}>
          <AreaLayout contentClassName={contentClassName}>
            {children}
          </AreaLayout>
        </LinkBuilderContext.Provider>
      </WorkspaceContext.Provider>
    );
  }
};

export default WorkspacePageWrapper;
