import { getDescriptorById } from "../model-providers/modelDescriptors";

const keyStorage = window.localStorage;
const PREFIX = "pmtleo-mk-";

const buildIndexKey = (modelId) => {
  const model = getDescriptorById(modelId);
  return PREFIX + model.vendor;
};

export const localKeysRegistry = {
  getKey: async (modelId) => {
    return keyStorage.getItem(buildIndexKey(modelId));
  },
  saveKey: async (modelId, key) => {
    keyStorage.setItem(buildIndexKey(modelId), key);
  },
};

const remoteKeysRegistry = {
  getKey: (workspaceId, modelId) => {},
  saveKey: (workspaceId, modelId, key) => {},
};
