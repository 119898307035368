import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FolderIcon from "../../../assets/icons/FolderIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import ModalDialog from "../ModalDialog";
import { createWorkspace } from "./store/workspaceActionCreators";

import "./CreateWorkspaceDialog.scss";

const CreateWorkspaceDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [input, setInput] = useState(null);
  const [formElem, setFormElem] = useState(null);

  useEffect(() => {
    if (input) input.focus();
  }, [input]);

  const onCreate = () => {
    const valid = input.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;
    setLoading(true);
    const onSuccess = (workspace) => {
      setLoading(false);
      onClose();
      //navigate(linkToDesigns(workspace.uid));
      // TODO: navigate
      navigate("/");
    };
    dispatch(createWorkspace(intl, name, onSuccess, () => setLoading(false)));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_workspace_dialog">
      <h1>
        <Message id="create_workspace_dialog_title" />
      </h1>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="workspace_form">
          <div className="dialog_body">
            <input
              type="text"
              ref={(val) => setInput(val)}
              required
              maxLength="120"
              placeholder={label(intl, "worksapce_name_placeholder")}
              onChange={(e) => setName(e.target.value)}
              value={name}
              readOnly={loading}
            />
            <FolderIcon color="rgba(77,64,132,0.6)" strokeWidth="2" />
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn bg-gradient-primary"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner /> : <PlusIcon color="#fff" strokeWidth="2" />}
            <Message id="create" />
          </button>
          <button className="btn no-btn btn-sm" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default CreateWorkspaceDialog;
