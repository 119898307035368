import { useEffect, useState } from "react";
import Message from "../../../../i18n/Message";
import { CheckSquare, CheckSquare2 } from "lucide-react";

import { useDispatch, useSelector } from "react-redux";
import { chatbotSelectors } from "../../store/chatbotReducer";
import backend from "../../../../backend/backend";
import Notify from "../../../shared/Notify";
import label from "../../../../i18n/label";
import { useIntl } from "react-intl";
import Spinner from "../../../../assets/icons/Spinner";
import {
  loadChatbot,
  loadChatbotList,
  loadKbpages,
} from "../../store/chatbotActionCreators";
import { parseUrls } from "./trainUtils";
import { useParams } from "react-router-dom";

import "./TrainTab.scss";
import IndexedPages from "./IndexedPages";

const TrainTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { chatbotId } = useParams();
  const [sources, setSources] = useState("");
  const [parseError, setParseError] = useState(false);
  const chatbot = useSelector(chatbotSelectors.getChatbot);
  const [indexInProgress, setIndexInProgress] = useState(false);
  const kbpages = useSelector(chatbotSelectors.getKbpages);
  const kbpagesChatbotUid = useSelector(chatbotSelectors.getKbpagesChatbotId);

  useEffect(() => {
    console.log("");
    if (chatbot?.chatbot?.kbSources && !sources) {
      setSources(JSON.parse(chatbot.chatbot.kbSources).join("\n"));
    }
  }, [chatbot, sources]);

  useEffect(() => {
    dispatch(loadKbpages(chatbotId));
  }, [dispatch, chatbotId]);

  const onSourcesSave = () => {
    const urls = parseUrls(sources);
    console.log("Extracted URLs: ", urls);
    if (!urls || urls.length === 0) {
      setParseError(true);
      return;
    }
    setParseError(false);
    setIndexInProgress(true);
    backend
      .updateChatbot({
        ...chatbot.chatbot,
        kbSources: JSON.stringify(urls),
      })
      .then((resp) => {
        console.log("Saved chatbot ", resp);
        Notify.success(label(intl, "kb_sources_save_success"));
        const proceed = () => {
          backend
            .indexChatbot(resp.chatbot)
            .then((indexResp) => {
              console.log("Indexing resp: ", indexResp);
              setIndexInProgress(true);
              const checkStatus = () => {
                backend
                  .getChatbot(resp.chatbot.uid)
                  .then((newResp) => {
                    console.log("Checking running status ", newResp);
                    if (newResp.chatbot.kbSyncStatus === "RUNNING") {
                      dispatch(loadKbpages(chatbotId));
                      setTimeout(checkStatus, 1500);
                    } else {
                      setIndexInProgress(false);
                      dispatch(loadChatbot(chatbotId, intl));
                      dispatch(loadChatbotList());
                      dispatch(loadKbpages(chatbotId));
                    }
                  })
                  .catch(() => {
                    Notify.error(label(intl, "error_unexpected"));
                  });
              };
              setTimeout(checkStatus, 1000);
            })
            .catch((indexErr) => {
              console.error("Failed to save chatbot ", indexErr);
              Notify.error(label(intl, "error_unexpected"));
              setIndexInProgress(false);
            });
        };
        setTimeout(proceed, 700);
      })
      .catch((err) => {
        console.error("Failed to save chatbot ", err);
        Notify.error(label(intl, "error_unexpected"));
        setIndexInProgress(false);
      });
  };

  const localKbpages =
    null !== kbpages && kbpagesChatbotUid === chatbotId ? kbpages : [];

  return (
    <div className="card content_panel_body train_tab">
      <div className="card_header">
        <div className="card_title">
          <Message id="train_tab_title" />
        </div>
        <div className="card_actions"></div>
      </div>
      <div className="widget_code_body">
        <p>
          <Message id="kb_init_help" />
        </p>
        <textarea
          rows="7"
          value={sources}
          onChange={(e) => setSources(e.target.value)}
          disabled={indexInProgress}
          className="form-control"
        ></textarea>
        <div className="widget_code_button_container">
          <button
            className="btn btn-outline-dark"
            disabled={indexInProgress}
            onClick={onSourcesSave}
          >
            <CheckSquare color="#344767" size={18} />
            <Message id="chatbot_train_save_button" />
          </button>
          {parseError && (
            <div className="error_notification " role="alert">
              <Message id="kb_sources_parse_error" />
            </div>
          )}
          {indexInProgress && (
            <div className="indexing_status">
              <Spinner />
              <Message id="chatbot_train_indexing_state" />
            </div>
          )}
        </div>
      </div>
      {localKbpages.length > 0 && (
        <div>
          <div className="card_header">
            <div className="card_title">
              <Message id="train_tab_kbpages_title" />
            </div>
            <div className="card_actions"></div>
          </div>
          <IndexedPages pages={localKbpages} />
        </div>
      )}
    </div>
  );
};

export default TrainTab;
