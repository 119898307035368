import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import Select from "react-select";

import ModalDialog from "../../shared/ModalDialog";

import "./EditEndpointDialog.scss";
import { promptSelectors } from "../../prompt/store/promptReducer";
import { apiKeySelectors } from "../../api-keys/store/apiKeyReducer";
import {
  loadPrompts,
  loadPromptVersions,
} from "../../prompt/store/promptActionCreators";
import { loadApiKeys } from "../../api-keys/store/apiKeyActionCreators";
import {
  createEndpoint,
  updateEndpoint,
} from "../store/endpointActionCreators";
import EndpointMetadata from "./EndpointMetadata";

const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#f7f7ff", // for option hover bg-color
    primary: "#644EF3", // for selected option bg-color
    neutral10: "#644EF3", // for tags bg-color
    neutral20: "#e1e1ed", // for input border-color
    neutral30: "#e1e1ed", // for input hover border-color
  },
});

const EditEndpointDialog = ({ endpoint, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();

  const prompts = useSelector(promptSelectors.getList);
  const versions = useSelector(promptSelectors.getVersions);
  const apiKeys = useSelector(apiKeySelectors.getList);

  const [apiKeyUid, setApiKeyUid] = useState(endpoint?.apiKeyUid ?? "");
  const [promptUid, setPromptUid] = useState(endpoint?.promptUid ?? "");
  const [versionUid, setVersionUid] = useState(endpoint?.versionUid ?? "");

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(endpoint?.name ?? "");
  const [description, setDescription] = useState(endpoint?.description ?? "");

  const [metadata, setMetadata] = useState(
    endpoint?.metadata ? endpoint.metadata : {}
  );

  const [formElem, setFormElem] = useState(null);
  const [nameInput, setNameInput] = useState(null);
  const [descriptionInput, setDescriptionInput] = useState(null);

  useEffect(() => {
    if (!prompts) {
      dispatch(
        loadPrompts(intl, workspaceId, (response) => {
          if (response && response.length > 0) {
            setPromptUid(response[0].uid);
          }
        })
      );
    } else {
      if (prompts && prompts.length > 0) {
        setPromptUid(prompts[0].uid);
      }
    }
  }, [prompts, workspaceId, setPromptUid]);

  useEffect(() => {
    if (prompts && prompts.length > 0) {
      dispatch(
        loadPromptVersions(intl, workspaceId, prompts[0].uid, (response) => {
          if (response && response.length > 0) {
            setVersionUid(response[0].uid);
          }
        })
      );
    }
  }, [prompts, workspaceId]);

  useEffect(() => {
    if (promptUid) {
      dispatch(
        loadPromptVersions(intl, workspaceId, promptUid, (response) => {
          if (response && response.length > 0) {
            setVersionUid(response[0].uid);
          }
        })
      );
    }
  }, [promptUid, workspaceId, setVersionUid]);

  useEffect(() => {
    if (!apiKeys) {
      dispatch(
        loadApiKeys(intl, workspaceId, (response) => {
          if (response && response.length > 0) {
            setApiKeyUid(response[0].uid);
          }
        })
      );
    } else {
      if (apiKeys && apiKeys.length > 0) {
        setApiKeyUid(apiKeys[0].uid);
      }
    }
  }, [apiKeys, workspaceId]);

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  const apiKeyOptions =
    apiKeys?.map((item) => ({ label: item.name, value: item.uid })) ?? [];

  const apiKeySelectedOption =
    apiKeyOptions.find((item) => item.value === apiKeyUid) ??
    (apiKeyOptions.length > 0 ? apiKeyOptions[0] : null);

  const promptOptions =
    prompts?.map((item) => ({ label: item.name, value: item.uid })) ?? [];

  const promptSelectedOption =
    promptOptions.find((item) => item.value === promptUid) ??
    (promptOptions.length > 0 ? promptOptions[0] : null);

  const versionOptions =
    versions?.map((item) => ({ label: item.name, value: item.uid })) ?? [];

  const versionSelectedOption =
    versionOptions.find((item) => item.value === versionUid) ??
    (versionOptions.length > 0 ? versionOptions[0] : null);

  const onPromptChanged = (option) => {
    setPromptUid(option.value);
    setVersionUid("");
    dispatch(loadPromptVersions(intl, workspaceId, option.value));
  };

  const onCreate = () => {
    const valid = nameInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      onClose();
    };
    const newObject = {
      name,
      description,
      metadata,
      promptUid,
      versionUid,
      apiKeyUid,
    };
    if (endpoint) {
      dispatch(
        updateEndpoint(
          intl,
          workspaceId,
          endpoint.uid,
          newObject,
          onSuccess,
          () => setLoading(false)
        )
      );
    } else {
      dispatch(
        createEndpoint(intl, newObject, workspaceId, onSuccess, () =>
          setLoading(false)
        )
      );
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_endpoint_dialog">
      <div className="modal_title">
        <Message
          id={
            endpoint
              ? "update_endpoint_dialog_title"
              : "create_endpoint_dialog_title"
          }
        />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="dialog_body">
          <input
            className="form-control"
            type="text"
            ref={(val) => setNameInput(val)}
            required
            maxLength="120"
            placeholder={label(intl, "endpoint_name_placeholder")}
            onChange={(e) => setName(e.target.value)}
            value={name}
            readOnly={loading}
          />
          <div class="url_container">
            <span class="http-method">POST</span>
            <span class="rest-endpoint">
              api.promptleo.com/{workspaceId}/endpoints/
            </span>
            <input
              className="form-control"
              type="text"
              maxLength="120"
              placeholder={label(intl, "endpoint_name_demo_placeholder")}
              value={name}
              readOnly={true}
            />
          </div>

          <textarea
            ref={(val) => setDescriptionInput(val)}
            autoFocus={true}
            rows="3"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder={label(intl, "form_description_placeholder")}
            className="form-control"
          ></textarea>
          <div className="select_row">
            <div className="label">
              <Message id="create_form_api_key_label" />
            </div>
            <Select
              theme={selectThemeColors}
              disabled={!apiKeys}
              className="react-select"
              classNamePrefix="select"
              value={apiKeySelectedOption}
              name="teamSize"
              options={apiKeyOptions}
              onChange={(option) => setApiKeyUid(option.value)}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="select_row">
            <div className="label">
              <Message id="create_form_prompt_label" />
            </div>
            <Select
              theme={selectThemeColors}
              disabled={!prompts}
              className="react-select"
              classNamePrefix="select"
              value={promptSelectedOption}
              name="teamSize"
              options={promptOptions}
              onChange={onPromptChanged}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="select_row">
            <div className="label">
              <Message id="create_form_prompt_version_label" />
            </div>
            <Select
              theme={selectThemeColors}
              disabled={!versions || !promptSelectedOption}
              className="react-select"
              classNamePrefix="select"
              placeholder={label(
                intl,
                "create_form_prompt_version_placeholder"
              )}
              value={versionSelectedOption}
              name="teamSize"
              options={versionOptions}
              onChange={(option) => setVersionUid(option.value)}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className="select_row">
            <div className="label">
              <Message id="metadata_title" />
            </div>
            <EndpointMetadata metadata={metadata} setMetadata={setMetadata} />
          </div>
        </div>

        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message
              id={
                endpoint ? "update_endpoint_button" : "create_endpoint_button"
              }
            />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default EditEndpointDialog;
