import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import PromptActions from "./promptActions";

export const createPrompt = (intl, name, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createPrompt(name, workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.LOADED,
          prompt: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadPrompts = (intl, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getPrompts(workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.LIST_LOADED,
          prompts: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadPrompt = (intl, workspaceId, promptId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getPrompt(workspaceId, promptId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.LOADED,
          prompt: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updatePrompt = (
  intl,
  workspaceId,
  uid,
  prompt,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updatePrompt(workspaceId, uid, prompt)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.UPDATED,
          prompt: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deletePrompt = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deletePrompt(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: PromptActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadPromptVersions = (
  intl,
  workspaceId,
  promptId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getPromptVersions(workspaceId, promptId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.VERSIONS_LOADED,
          versions: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadPromptVersion = (
  intl,
  workspaceId,
  promptId,
  versionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getPromptVersion(workspaceId, promptId, versionId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.VERSION_LOADED,
          version: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const createPromptVersion = (
  intl,
  version,
  promptId,
  workspaceId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .createPromptVersion(version, workspaceId, promptId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.VERSIONS_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create a version", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updatePromptVersion = (
  intl,
  version,
  promptId,
  workspaceId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updatePromptVersion(workspaceId, promptId, version)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.VERSIONS_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update a version", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deletePromptVersion = (
  intl,
  versionId,
  promptId,
  workspaceId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .deletePromptVersion(workspaceId, promptId, versionId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: PromptActions.VERSIONS_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a version", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

/*
export const loadSubmissions = (
  intl,
  workspaceId,
  campaignId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getSubmissions(workspaceId, campaignId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: CampaignActions.SUBMISSIONS_LOADED,
          submissions: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadSubmission = (
  intl,
  workspaceId,
  campaignId,
  submissionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getSubmission(workspaceId, campaignId, submissionId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: CampaignActions.SUBMISSION_LOADED,
          submission: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load submissions", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteSubmission = (
  intl,
  workspaceId,
  campaignId,
  submissionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .deleteSubmission(workspaceId, campaignId, submissionId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: CampaignActions.SUBMISSION_LOADED,
          submission: null,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateSubmissionStatus = (
  intl,
  workspaceId,
  campaignId,
  submissionId,
  status,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .saveSubmissionStatus(workspaceId, campaignId, submissionId, status)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: CampaignActions.SUBMISSION_LOADED,
          submission: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to change submission status", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
*/
