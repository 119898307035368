import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import CodeMirror from "@uiw/react-codemirror";
import { materialDark } from "@uiw/codemirror-theme-material";
import { EditorView } from "@codemirror/view";
import { json } from "@codemirror/lang-json";

import Message from "../../../i18n/Message";
import HubLayout from "../layout/HubLayout";

import "./HubJobDetailsPage.scss";

import modelRegistry from "../models/modelRegistry";
import { linkToHubModel } from "../../../routes";
import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { loadJob } from "../store/hubActionCreators";
import { useIntl } from "react-intl";
import { hubSelectors } from "../store/hubReducer";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ActivityIcon from "../../../assets/icons/ActivityIcon";
import LoaderIcon from "../../../assets/icons/LoaderIcon";
import SquareIcon from "../../../assets/icons/SquareIcon";
import CheckSquareIcon from "../../../assets/icons/CheckSquareIcon";
import ListIcon from "../../../assets/icons/ListIcon";
import ClockIcon from "../../../assets/icons/ClockIcon";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import { formatDurationMs } from "../utils/hubUtils";

const editorHeight = "260px";

const HubJobDetailsPage = () => {
  const nav = useNavigate();
  const intl = useIntl();
  const { handle, jobId } = useParams();
  const dispatch = useDispatch();
  const job = useSelector(hubSelectors.getJob);

  const model = modelRegistry.find(handle);
  useEffect(() => {
    if (!job || job.uid !== jobId) {
      dispatch(loadJob(jobId, intl));
    }
  }, [intl, dispatch, jobId, job]);

  const input = job?.input ?? "{}";
  let iconBgClass = "draft";
  let statusValueClass = "";
  if (job?.status === "FINISHED") {
    // iconBgClass = "finished";
    statusValueClass = " finished";
  }

  return (
    <HubLayout contentPanelClassName="hub_job_details">
      <div className="breadcrumbs">
        <div onClick={() => nav(linkToHubModel(handle))}>
          <ChevronLeftIcon
            size={16}
            strokeWidth="2"
            color="rgb(103, 116, 142)"
          />
          <Message id="hub_job_breacrumbs_back" />
        </div>
      </div>
      <h1 className="model_name">
        <ActivityIcon color="#344767" size="32" /> Job: {model.title}
      </h1>
      <div className="model_id" onClick={() => nav(linkToHubModel(handle))}>
        {model.vendor}/{model.handle}
      </div>
      {job && (
        <div className="summary_container">
          <div className="card">
            <div className={"icon_container " + iconBgClass}>
              {job.status === "QUEUED" && (
                <LoaderIcon color="rgb(123, 136, 162)" />
              )}
              {job.status === "RUNNING" && (
                <SquareIcon color="rgb(123, 136, 162)" />
              )}
              {job.status === "FINISHED" && (
                <CheckSquareIcon color="rgb(123, 136, 162)" />
              )}
            </div>
            <div className="meta_container">
              <div className="status_label">
                <Message id="job_details_status" />
              </div>
              <div className={"status_value" + statusValueClass}>
                {job.status}
              </div>
            </div>
          </div>
          <div className="card">
            <div className={"icon_container draft"}>
              <ListIcon color="rgb(123, 136, 162)" />
            </div>
            <div className="meta_container">
              <div className="status_label">
                <Message id="hub_recent_jobs_header_queued" />
              </div>
              <div className="status_value">
                {formatDurationMs(job.queuedMs)}
              </div>
            </div>
          </div>
          <div className="card">
            <div className={"icon_container draft"}>
              <ClockIcon color="rgb(123, 136, 162)" />
            </div>
            <div className="meta_container">
              <div className="status_label">
                <Message id="hub_recent_jobs_header_running" />
              </div>
              <div className="status_value">
                {formatDurationMs(job.runningMs)}
              </div>
            </div>
          </div>
          <div className="card">
            <div className={"icon_container draft"}>
              <CalendarIcon color="rgb(123, 136, 162)" />
            </div>
            <div className="meta_container">
              <div className="status_label">
                <Message id="hub_recent_jobs_header_created" />
              </div>
              <div className="status_value">
                {dayjs.utc(job.dateCreated).fromNow()}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="model_job_details_card card">
        {job && (
          <div className="rest_api_container">
            <div className="job_input">
              <div className="label">
                <Message id="job_details_label_request" />
              </div>
              <CodeMirror
                value={input ? JSON.stringify(JSON.parse(input), null, 2) : ""}
                theme={materialDark}
                height={editorHeight}
                extensions={[json(), EditorView.lineWrapping]}
              />
            </div>
            <div className="between">
              <ChevronRightIcon />
            </div>
            <div className="job_result">
              <div className="label">
                <Message id="job_details_label_response" />
              </div>
              <CodeMirror
                value={
                  job?.result
                    ? JSON.stringify(JSON.parse(job.result), null, 2)
                    : ""
                }
                theme={materialDark}
                height={editorHeight}
                extensions={[json(), EditorView.lineWrapping]}
              />
            </div>
          </div>
        )}
      </div>
    </HubLayout>
  );
};

export default HubJobDetailsPage;
