const ChatbotWidgetActions = {
  CHATBOT_LOADED: "chatbot/loaded",
  CHAT_LOADED: "chatbot/chat/loaded",

  POSTED_MESSAGE: "chat/message/posted",
  GOT_MESSAGE_PART: "chat/message/part",
  MESSAGE_PART_ERROR: "chat/message/part-error",
  FINISHED_MESSAGE_PARTS: "chat/message/parts-finished",
  GOT_MESSAGE_RELY: "chat/message/reply",
};

export default ChatbotWidgetActions;
