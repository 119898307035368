import Message from "../../../i18n/Message";
import TrashIcon from "../../../assets/icons/TrashIcon";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import { useNavigate, useParams } from "react-router-dom";
import { linkToPrompt } from "../../../routes";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { deleteApiKey } from "../store/apiKeyActionCreators";
import { useIntl } from "react-intl";

import "./ApiKeyTile.scss";
import dayjs from "dayjs";
import CreateApiKeyDialog from "./CreateApiKeyDialog";

const ApiKeyTile = ({ apiKey }) => {
  const { workspaceId } = useParams();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const onDeleteConfirmed = () => {
    const onFinished = () => {
      setShowDeleteConfirm(false);
    };
    dispatch(
      deleteApiKey(intl, workspaceId, apiKey.uid, onFinished, onFinished)
    );
  };

  return (
    <div className="campaign_tile border-radius-lg d-flex">
      <div className="d-flex flex-column tile_body">
        <div className="tile-name">{apiKey.name}</div>
        <div className="metadata_container">
          <div className="metadata_row">
            <div className="meta_name">
              <Message id="model" />
            </div>
            <div className="meta_value">
              <Message id={"vendor." + apiKey.vendor} />
            </div>
          </div>
          <div className="metadata_row">
            <div className="meta_name">
              <Message id="created_on" />
            </div>
            <div className="meta_value">
              {dayjs(apiKey.dateCreated).format("LLL")}
            </div>
          </div>
        </div>

        <div className="status_row">
          {/*
          {campaign.status === "ONLINE" && (
            <span className="badge badge-sm bg-gradient-success">
              <Message id="status_online" />
            </span>
          )}
          {(!campaign.status || campaign.status === "DRAFT") && (
            <span className="badge badge-sm bg-gradient-secondary">
              <Message id="status_offline" />
            </span>
          )}
          */}
        </div>
      </div>
      <div className="d-flex tile-actions">
        <div className="confirm_container">
          {showDeleteConfirm && (
            <>
              <div
                className="cover"
                onClick={() => setShowDeleteConfirm(false)}
              ></div>
              <div className="confirm_panel">
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              </div>
            </>
          )}
        </div>
        <button
          className="no-btn tile-action"
          onClick={() => setShowDeleteConfirm(true)}
        >
          <TrashIcon size="16" color="#344767" />
          <Message id="delete" />
        </button>
        {/*
        <button
          className="no-btn tile-action"
          onClick={() => setShowEdit(true)}
        >
          <Edit2Icon size="16" color="#344767" />
          <Message id="edit" />
        </button>
          */}
      </div>
      {showEdit && (
        <CreateApiKeyDialog
          apiKey={apiKey}
          onClose={() => setShowEdit(false)}
        />
      )}
    </div>
  );
};

export default ApiKeyTile;
