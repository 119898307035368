import copy from "copy-to-clipboard";
import { useIntl } from "react-intl";
import CopyIcon from "../../assets/icons/CopyIcon";
import Spinner from "../../assets/icons/Spinner";
import label from "../../i18n/label";
import Message from "../../i18n/Message";
import Notify from "../shared/Notify";

import "./WorkspaceUID.scss";

const WorkspaceUID = ({ workspace }) => {
  const intl = useIntl();
  return (
    <div className="workspace_uid">
      <h3>
        <Message id="workspace_id" />
      </h3>
      <div className="help_text">
        <Message id="workspace_id_help" />
      </div>
      {!workspace && <Spinner />}
      {workspace && (
        <>
          <div className="workspace_uid_value">{workspace.uid}</div>

          <button
            type="button"
            className="btn btn-outline-dark btn-sm"
            onClick={() => {
              copy(workspace.uid);
              Notify.success(label(intl, "copy_success"));
            }}
          >
            <CopyIcon color="rgb(52, 71, 103)" />
            <Message id="copy" />
          </button>
        </>
      )}
    </div>
  );
};

export default WorkspaceUID;
