import ListIcon from "../../../../assets/icons/ListIcon";
import Message from "../../../../i18n/Message";
import ZeroState from "../../shared/ZeroState";
import "./ChatsZeroState.scss";

const ChatsZeroState = () => {
  return (
    <div className="card content_panel_body chats_zero_state_card">
      <div className="card_header">
        <div className="card_title">
          <Message id="widget_code_chats_title" />
        </div>
        <div className="card_actions"></div>
      </div>
      <div className="chats_zero_state_body">
        <ZeroState
          icon={<ListIcon color="#67748e" size="36" />}
          textKey="chats_zero_state_help"
          onClick={null}
        />
      </div>
    </div>
  );
};

export default ChatsZeroState;
