import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AlertTriangleIcon from "../../../../assets/icons/AlertTriangleIcon";
import ArrowInIcon from "../../../../assets/icons/ArrowInIcon";
import ChevronRightIcon from "../../../../assets/icons/ChevronRightIcon";
import Spinner from "../../../../assets/icons/Spinner";
import { POST } from "../../../../backend/httpAPI";
import label from "../../../../i18n/label";

import Message from "../../../../i18n/Message";
import parseParams from "../../../prompt/parseParams";
import { loadPromptVersion } from "../../../prompt/store/promptActionCreators";
import { promptSelectors } from "../../../prompt/store/promptReducer";
import Notify from "../../../shared/Notify";
import FormActions from "../../store/FormActions";

import "./FormPreviewContainer.scss";

const FormPreviewContainer = ({ form }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();
  const version = useSelector(promptSelectors.getCurrentVersion);
  const [params, setParams] = useState([]);
  const [formData, setFormData] = useState({});
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("FormPreviewContainer ", version, form?.versionUid);
    if (!version || form?.versionUid !== version.uid) {
      dispatch(
        loadPromptVersion(intl, workspaceId, form.templateUid, form.versionUid)
      );
    }
  }, [version, form, workspaceId]);

  useEffect(() => {
    if (version && version.uid === form.versionUid) {
      const params = parseParams(version.content);
      setParams(params);
    }
  }, [version, form]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const req = {
      params: formData,
    };
    setLoading(true);
    setResponse(null);
    POST("/customer/" + workspaceId + "/forms/" + form.uid, req)
      .then((res) => {
        setResponse(res);
        dispatch({ type: FormActions.SUBMISSIONS_CHANGED });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to send API request", err);
        Notify.error(label(intl, "api_console_server_error"));
      });
  };

  return (
    <>
      <div className="preview_row card">
        <div className="card_header">
          <div className="card_title">
            <Message id="form_preview_title" />
          </div>
          <div className="card_actions"></div>
        </div>
        <div className="preview_body">
          <div className="preview_form_fields">
            {params && params.length > 0 && (
              <form onSubmit={onFormSubmit}>
                <div className="form_fields_container">
                  {params.map((param) => {
                    return (
                      <input
                        key={param}
                        className="form-control"
                        type="text"
                        required
                        placeholder={param}
                        value={formData[param] ?? ""}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            [param]: e.target.value,
                          }))
                        }
                      />
                    );
                  })}
                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-outline-primary btn-sm"
                    >
                      {loading ? <Spinner /> : <ArrowInIcon color="#cb0c9f" />}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="between">
            <ChevronRightIcon />
          </div>
          <div className="preview_form_output">
            {!response && <div className="response_placeholder">&nbsp;</div>}
            {response && response.status === "ERROR" && (
              <div className="submission_error">
                <AlertTriangleIcon color="#856404" />
                <Message id="submission_error_retry" />
              </div>
            )}
            {response && response.status !== "ERROR" && (
              <>
                <div className="response_label">
                  <label>
                    <Message id="form_submission_response" />
                  </label>
                </div>
                <div className="choice_container">
                  {response.choices?.map((item) => {
                    return (
                      <div key={item.text} className="choice">
                        {item.text}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormPreviewContainer;
