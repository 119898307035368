import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../assets/icons/Spinner";
import TrashIcon from "../../../assets/icons/TrashIcon";

import Message from "../../../i18n/Message";
import { linkToForms } from "../../../routes";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";

import "./FlowDetailsPage.scss";
import { deleteFlow, loadFlow } from "../store/flowActionCreators";
import FlowCanvas from "./canvas/FlowCanvas";
import { flowSelectors } from "../store/flowReducer";

const FlowDetailsPage = () => {
  const { flowId, workspaceId } = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const flow = useSelector(flowSelectors.getFlow);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!flow || flow?.uid !== flowId) {
      dispatch(loadFlow(intl, workspaceId, flowId));
    }
  }, [flow, flowId, workspaceId, dispatch, intl]);

  const onDeleteConfirmed = () => {
    const onSuccess = () => {
      setShowDeleteConfirm(false);
      nav(linkToForms(workspaceId));
    };
    dispatch(
      deleteFlow(intl, workspaceId, flowId, onSuccess, () =>
        setShowDeleteConfirm(false)
      )
    );
  };

  console.log("Flow", flow, flow?.uid === flowId);
  const item = !!flow && flow?.uid === flowId ? flow : null;

  return (
    <WorkspaceLayout contentPanelClassName="form_details_main">
      {!item && (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {item && (
        <>
          <div className="title_row card">
            <div className="title_top_panel">
              <div className="title_column">
                <div className="campaign_title">{item.name}</div>
                <div className="metadata_row">
                  <div className="meta_name">
                    <Message id="created_on" />
                  </div>
                  <div className="meta_value">
                    {dayjs(item.dateCreated).format("LLL")}
                  </div>
                </div>

                {/*
              <div>
                <ToggleSwitch
                  onChange={onStatusChanged}
                  value={status}
                  optionLeft={{ label: "campaign_offline", value: "DRAFT" }}
                  optionRight={{ label: "campaign_online", value: "ONLINE" }}
                />
              </div>
      */}
              </div>
              <div className="title_actions">
                <div className="confirm_container">
                  {showDeleteConfirm && (
                    <>
                      <div
                        className="cover"
                        onClick={() => setShowDeleteConfirm(false)}
                      ></div>
                      <div className="confirm_panel">
                        <button
                          className="btn bg-gradient-primary btn-sm"
                          onClick={onDeleteConfirmed}
                        >
                          <Message id="confirm_delete" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <button
                  className="btn no-btn tile-action"
                  onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                >
                  <TrashIcon size="16" color="#344767" />
                  <Message id="delete" />
                </button>
              </div>
            </div>
          </div>
          <FlowCanvas flow={flow} />
          {/*
  
          <CampaignSubmissions campaign={campaign} submissions={submissios} />
                */}
        </>
      )}
    </WorkspaceLayout>
  );
};

export default FlowDetailsPage;
