import { useEffect, useState } from "react";
import Message from "../../../../i18n/Message";
import { CheckSquare, CheckSquare2 } from "lucide-react";

import "./KBInitState.scss";
import { useDispatch, useSelector } from "react-redux";
import { chatbotSelectors } from "../../store/chatbotReducer";
import backend from "../../../../backend/backend";
import Notify from "../../../shared/Notify";
import label from "../../../../i18n/label";
import { useIntl } from "react-intl";
import Spinner from "../../../../assets/icons/Spinner";
import {
  loadChatbot,
  loadChatbotList,
} from "../../store/chatbotActionCreators";

function parseUrls(input) {
  if (!input || typeof input !== "string") {
    throw new Error("Input must be a non-empty string");
  }

  // Split on commas, spaces, or newline characters
  const urls = input
    .split(/[\s,]+/)
    .map((url) => url.trim())
    .filter((url) => url !== ""); // Remove any empty strings

  const urlPattern =
    /^(https?:\/\/)[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=]+[a-zA-Z0-9]+[\/]?$/;

  if (!urls.every((url) => urlPattern.test(url))) {
    throw new Error("Invalid URL format detected");
  }

  return urls;
}

const KBInitState = ({ chatbotId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sources, setSources] = useState("");
  const [parseError, setParseError] = useState(false);
  const chatbot = useSelector(chatbotSelectors.getChatbot);
  const [indexInProgress, setIndexInProgress] = useState(false);

  useEffect(() => {
    console.log("");
    if (chatbot?.chatbot?.kbSources && !sources) {
      setSources(JSON.parse(chatbot.chatbot.kbSources).join("\n"));
    }
  }, [chatbot, sources]);

  const onSourcesSave = () => {
    const urls = parseUrls(sources);
    console.log("Extracted URLs: ", urls);
    if (!urls || urls.length === 0) {
      setParseError(true);
      return;
    }
    setParseError(false);
    setIndexInProgress(true);
    backend
      .updateChatbot({
        ...chatbot.chatbot,
        kbSources: JSON.stringify(urls),
      })
      .then((resp) => {
        console.log("Saved chatbot ", resp);
        Notify.success(label(intl, "kb_sources_save_success"));
        const proceed = () => {
          backend
            .indexChatbot(resp.chatbot)
            .then((indexResp) => {
              console.log("Indexing resp: ", indexResp);
              setIndexInProgress(true);
              const checkStatus = () => {
                backend
                  .getChatbot(resp.chatbot.uid)
                  .then((newResp) => {
                    console.log("Checking running status ", newResp);
                    if (newResp.chatbot.kbSyncStatus === "RUNNING") {
                      setTimeout(checkStatus, 1500);
                    } else {
                      setIndexInProgress(false);
                      dispatch(loadChatbot(chatbotId, intl));
                      dispatch(loadChatbotList());
                    }
                  })
                  .catch(() => {
                    Notify.error(label(intl, "error_unexpected"));
                  });
              };
              setTimeout(checkStatus, 1000);
            })
            .catch((indexErr) => {
              console.error("Failed to save chatbot ", indexErr);
              Notify.error(label(intl, "error_unexpected"));
              setIndexInProgress(false);
            });
        };
        setTimeout(proceed, 700);
      })
      .catch((err) => {
        console.error("Failed to save chatbot ", err);
        Notify.error(label(intl, "error_unexpected"));
        setIndexInProgress(false);
      });
  };

  return (
    <div className="kb_init_state">
      <div className="card_header">
        <div className="card_title">
          <Message id="kb_init_title" />
        </div>
        <div className="card_actions"></div>
      </div>
      <div className="widget_code_body">
        <p>
          <Message id="kb_init_help" />
        </p>
        <textarea
          rows="7"
          value={sources}
          onChange={(e) => setSources(e.target.value)}
          disabled={indexInProgress}
          className="form-control"
        ></textarea>
        <div className="widget_code_button_container">
          <button
            className="btn btn-outline-dark"
            disabled={indexInProgress}
            onClick={onSourcesSave}
          >
            <CheckSquare color="#344767" size={18} />
            <Message id="save" />
          </button>
          {parseError && (
            <div className="error_notification " role="alert">
              <Message id="kb_sources_parse_error" />
            </div>
          )}
          {indexInProgress && (
            <div className="indexing_status">
              <Spinner />
              Indexing...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KBInitState;
