import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import ChatActions from "./chatActions";

const initialState = {
  data: {
    list: null,
    listCounter: 0,
    listFolderId: null,

    chat: null,
    messages: [],

    replyInProgress: false,
    replyPart: "",
    replyError: null,
  },
};

export const chatSelectors = {
  getList: (state) => state.chats.data.list,
  getListCounter: (state) => state.chats.data.listCounter,
  getListFolderId: (state) => state.chats.data.listFolderId,
  getFolder: (state) => state.chats.data.folder,
  getChat: (state) => state.chats.data.chat,
  getMessages: (state) => state.chats.data.messages,
  getReplyInProgress: (state) => state.chats.data.replyInProgress,
  getReplyPart: (state) => state.chats.data.replyPart,
  getReplyError: (state) => state.chats.data.replyError,
};

const onLoadList = produce((draft, action) => {
  draft.data.list = action.list;
  draft.data.listFolderId = action.folderId;
});

const onListChanged = produce((draft) => {
  draft.data.listCounter++;
});

const onChatLoaded = produce((draft, action) => {
  const obj = action.chat;
  draft.data.chat = obj.chat;
  draft.data.messages = obj.messages;
});

const onChatFolderLoaded = produce((draft, action) => {
  const obj = action.folder;
  draft.data.folder = obj.chat;
});

const onMessagePosted = produce((draft, action) => {
  const message = action.message;
  draft.data.messages.push({ role: "user", content: message });
  draft.data.replyInProgress = true;
  draft.data.replyError = null;
  draft.data.replyPart = "";
});

const onGotMessagePart = produce((draft, action) => {
  draft.data.replyPart = (draft.data.replyPart ?? "") + action.content;
  //console.log("onGotMessagePart ", draft.data.replyPart, action);
});

const onFinishedMessageParts = produce((draft, action) => {
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.messages = [
    ...(draft.data.messages ?? []).filter((item) => item.uid),
    ...action.response,
  ];
});

const onMessagePartError = produce((draft, action) => {
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.replyError = action.error;
});

const onGotMessageReply = produce((draft, action) => {
  const messages = action.reply.messages;
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.messages = [...draft.data.messages, ...messages];
});

const onMessageFileUploaded = produce((draft, action) => {
  const message = action.message;
  draft.data.messages = [...draft.data.messages, message];
});

const onMessageDeleted = produce((draft, action) => {
  const messageId = action.messageId;
  draft.data.messages = draft.data.messages.filter(
    (item) => item.uid !== messageId
  );
});

const onMessageUpdated = produce((draft, action) => {
  const { messageId, content } = action;
  draft.data.messages = draft.data.messages.map((item) => {
    if (item.uid === messageId) {
      return {
        ...item,
        content,
      };
    } else {
      return item;
    }
  });
});

const handlers = {
  [ChatActions.LIST_LOADED]: onLoadList,
  [ChatActions.LIST_CHANGED]: onListChanged,
  [ChatActions.LOADED]: onChatLoaded,
  [ChatActions.FOLDER_LOADED]: onChatFolderLoaded,
  [ChatActions.POSTED_MESSAGE]: onMessagePosted,
  [ChatActions.GOT_MESSAGE_PART]: onGotMessagePart,
  [ChatActions.FINISHED_MESSAGE_PARTS]: onFinishedMessageParts,
  [ChatActions.MESSAGE_PART_ERROR]: onMessagePartError,
  [ChatActions.GOT_MESSAGE_RELY]: onGotMessageReply,
  [ChatActions.MESSAGE_FILE_UPLOADED]: onMessageFileUploaded,
  [ChatActions.MESSAGE_DELETED]: onMessageDeleted,
  [ChatActions.MESSAGE_UPDATED]: onMessageUpdated,
};

const chatReducer = reducerUtils.createReducer(initialState, handlers);

export default chatReducer;
