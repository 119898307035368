import authStore from "../components/auth/AuthStore";
import Notify from "../components/shared/Notify";

export const backendURL = process.env.REACT_APP_REST_URL;

export const responseHandler = async (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    //console.log("Response body: ",resp.body);
    return await resp.json().catch(() => {
      return {};
    });
  } else {
    console.log("Got resp status = ", resp.status, JSON.stringify(resp));
    if (resp.status === 401) {
      //window.location = "/b/login";
      authStore.setToken("");
      window.location = "/app/";
      return await Promise.reject(resp);
    } else if (resp.status === 400) {
      if (resp.url.indexOf("/b/rest/public") !== -1) {
        //window.location = "/b/login";
        authStore.setToken("");
        window.location = "/app/";
      }
      return await Promise.reject(resp);
    } else if (resp.status === 429) {
      Notify.error("Subscription limit exceeded");
      return await Promise.reject(resp);
    }
    return await Promise.reject(resp);
  }
};

const errorHandler = (err) => {
  console.log("Got error", err, err.response, JSON.stringify(err));
  return Promise.reject(err);
};

export const assignApiKey = (options) => {
  const apiKey = authStore.getToken();
  if (apiKey) {
    if (!("headers" in options)) {
      options.headers = {};
    }
    options.headers["Authorization"] = "Bearer " + apiKey;
  }
};

export const GET = (url) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    cache: "no-store",
  };
  assignApiKey(options);
  return fetch(backendURL + url, options)
    .then(responseHandler)
    .catch(errorHandler);
};

export const POST = (url, data, raw = false) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: raw ? data : JSON.stringify(data),
  };
  if (!raw) {
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
  }
  assignApiKey(options);
  return fetch(backendURL + url, options)
    .then(responseHandler)
    .catch(errorHandler);
};

export const PUT = (url, data, raw = false) => {
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
    },
    body: raw ? data : JSON.stringify(data),
  };
  if (!raw) {
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
  }
  assignApiKey(options);
  return fetch(backendURL + url, options)
    .then(responseHandler)
    .catch(errorHandler);
};

export const DELETE = (url) => {
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
    cache: "no-store",
  };
  assignApiKey(options);
  return fetch(backendURL + url, options)
    .then(responseHandler)
    .catch(errorHandler);
};
