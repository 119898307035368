import React from "react";

const LogoAnthropic = ({ size = 36, strokeWidth = "1" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    strokeWidth={strokeWidth}
  >
    <path
      d="M25.9562 5H20.4485L30.4923 30.3772H36L25.9562 5ZM10.0438 5L0 30.3772H5.6163L7.67027 25.048H18.178L20.232 30.3772H25.8483L15.8045 5H10.0438ZM9.48712 20.335L12.9242 11.4165L16.3612 20.335H9.48712Z"
      fill="#181818"
    />
  </svg>
);
export default LogoAnthropic;
