import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import ClockIcon from "../../../../assets/icons/ClockIcon";
import Message from "../../../../i18n/Message";
import CodeMirror from "@uiw/react-codemirror";
import { githubLight } from "@uiw/codemirror-theme-github";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";

import "./EndpointRequest.scss";
import CalendarIcon from "../../../../assets/icons/CalendarIcon";

const editorHeight = "220px";

const EndpointRequest = ({ request, index }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const onDeleteConfirmed = () => {
    setShowDeleteConfirm(false);
  };

  return (
    <div className="request_item">
      <div className="date">
        <div>
          <CalendarIcon color="#555" size="16" />
          {dayjs(request.dateCreated).format("LLL")}
        </div>
        <div className="time_diff">{dayjs(request.dateCreated).fromNow()}</div>
        <div>
          <ClockIcon color="#555" size="16" /> {request.duration / 1000}s.
        </div>
      </div>
      <div className="item_content">
        <CodeMirror
          value={JSON.stringify(JSON.parse(request.requestBody), null, 2)}
          theme={githubLight}
          height={editorHeight}
          extensions={[json(), EditorView.lineWrapping]}
        />
      </div>
      <div className="item_content">
        <CodeMirror
          value={JSON.stringify(JSON.parse(request.responseBody), null, 2)}
          theme={githubLight}
          height={editorHeight}
          extensions={[json(), EditorView.lineWrapping]}
        />
      </div>
      <div className="item_actions">
        <div className="confirm_container">
          {showDeleteConfirm && (
            <>
              <div
                className="cover"
                onClick={() => setShowDeleteConfirm(false)}
              ></div>
              <div className="confirm_panel">
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              </div>
            </>
          )}
        </div>
        <button
          className="btn no-btn"
          onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
        >
          <TrashIcon size="18" color="#344767" />
        </button>
      </div>
    </div>
  );
};

export default EndpointRequest;
