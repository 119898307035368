import React from "react";
import { useRef } from "react";
import CrossIcon from "../../assets/icons/CrossIcon";

const ModalDialog = ({ onClose, closable = true, className = "", children }) => {
  const coverRef = useRef();
  const dialogRef = useRef();

  const onCoverClick = (e) => {
    if (e.target !== dialogRef.current && !dialogRef.current.contains(e.target)) {
      closable && onClose();
    }
  };

  return (
    <div ref={coverRef} className={"modal_dialog " + className} onMouseDown={onCoverClick}>
      <div ref={dialogRef} className="modal_body">
        {closable && (
          <button className="btn modal_close_btn" onClick={onClose}>
            <CrossIcon color="#000" />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default ModalDialog;
