import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../assets/icons/Spinner";
import TrashIcon from "../../../assets/icons/TrashIcon";

import Message from "../../../i18n/Message";

import {
  deletePrompt,
  loadPrompt,
  loadPromptVersions,
} from "../store/promptActionCreators";
import { promptSelectors } from "../store/promptReducer";

import PromptTitle from "./PromptTitle";

import "./PromptDetailsPage.scss";
import PromptContent from "./PromptContent";
import PromptSnapshots from "./PromptSnapshots";
import PromptVersionsStatusBadge from "./PromptVersionsStatusBadge";
import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const PromptDetailsPage = () => {
  const { versionId, promptId } = useParams();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  const intl = useIntl();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const prompt = useSelector(promptSelectors.getPrompt);
  const versionsCounter = useSelector(promptSelectors.getVersionsCounter);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const versions = useSelector(promptSelectors.getVersions);
  const version = useSelector(promptSelectors.getVersion(versionId));
  const [snapshotInProgress, setSnapshotInProgress] = useState(false);

  const promptUid = prompt?.uid;

  useEffect(() => {
    if (!prompt || prompt?.uid !== promptId) {
      dispatch(loadPrompt(intl, workspaceId, promptId));
    }
  }, [prompt, promptId, workspaceId, dispatch, intl]);

  useEffect(() => {
    dispatch(
      loadPromptVersions(intl, workspaceId, promptId, (data) => {
        console.log("DATA RESL ", versionId, data?.[0].uid);
        if (!versionId && data?.[0].uid) {
          nav(linkBuilder.linkToPromptVersion(promptId, data?.[0].uid));
        }
      })
    );
  }, [promptId, versionId, versionsCounter, workspaceId, dispatch, intl]);

  const onDeleteConfirmed = () => {
    const onSuccess = () => {
      setShowDeleteConfirm(false);
      nav(linkBuilder.linkToPrompts());
    };
    dispatch(
      deletePrompt(intl, workspaceId, promptId, onSuccess, () =>
        setShowDeleteConfirm(false)
      )
    );
  };
  console.log("Prompt", prompt, prompt?.uid === promptId);
  const item = !!prompt && prompt?.uid === promptId ? prompt : null;

  return (
    <>
      {!item ||
        (!versionId && (
          <div className="spinner_container">
            <Spinner />
          </div>
        ))}
      {item && version && (
        <>
          <div className="title_row">
            <div className="title_column">
              <div className="breadcrumbs">
                <div onClick={() => nav(linkBuilder.linkToPrompts())}>
                  <ChevronLeftIcon
                    size={16}
                    strokeWidth="2"
                    color="rgb(103, 116, 142)"
                  />
                  <Message id="back_to_prompts" />
                </div>
              </div>
              <div className="title_block">
                <div className="title_left">
                  <PromptTitle prompt={item} />
                  <div className="metadata_row">
                    <div className="meta_name">
                      <Message id="created_on" />
                    </div>
                    <div className="meta_value">
                      {dayjs(item.dateCreated).format("DD MMM")}
                    </div>
                  </div>
                  <div className="metadata_row">
                    <div className="meta_name">
                      <Message id="status" />
                    </div>
                    <div className="meta_value">
                      <PromptVersionsStatusBadge versions={versions} />
                    </div>
                  </div>
                </div>
                <div className="title_actions">
                  <div className="confirm_container">
                    {showDeleteConfirm && (
                      <>
                        <div
                          className="cover"
                          onClick={() => setShowDeleteConfirm(false)}
                        ></div>
                        <div className="confirm_panel">
                          <button
                            className="btn bg-gradient-primary btn-sm"
                            onClick={onDeleteConfirmed}
                          >
                            <Message id="confirm_delete" />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    className="btn no-btn tile-action"
                    onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                  >
                    <TrashIcon size="16" color="#344767" />
                    <Message id="delete" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PromptContent
            prompt={prompt}
            version={version}
            snapshotInProgress={snapshotInProgress}
            setSnapshotInProgress={setSnapshotInProgress}
          />
          <PromptSnapshots
            prompt={prompt}
            versions={versions}
            version={version}
            snapshotInProgress={snapshotInProgress}
          />
          {/*
  
          <CampaignSubmissions campaign={campaign} submissions={submissios} />
                */}
        </>
      )}
    </>
  );
};

export default PromptDetailsPage;
