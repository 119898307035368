import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import CheckIcon from "../../../assets/icons/CheckIcon";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";

import "./ChatbotTitle.scss";
import {
  loadChatbot,
  loadChatbotList,
  updateChatbot,
} from "../store/chatbotActionCreators";

const ChatbotTitle = ({ chatbot }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const onEdit = () => {
    setName(chatbot.name);
    setEditMode(true);
  };
  const onSave = (e) => {
    e.preventDefault();
    if (!name) {
      setEditMode(false);
      return;
    }
    setInProgress(true);
    const onSuccess = () => {
      dispatch(loadChatbot(chatbot.uid, intl));
      dispatch(loadChatbotList());
      setInProgress(false);
      setEditMode(false);
    };
    const onError = () => {
      setInProgress(false);
      setEditMode(false);
    };
    dispatch(
      updateChatbot(
        {
          ...chatbot,
          name,
        },
        intl,
        onSuccess,
        onError
      )
    );
  };

  return (
    <form onSubmit={onSave}>
      <div className="prompt_name">
        {!editMode && (
          <>
            {chatbot.name}
            <div className="edit" onClick={onEdit}>
              <Edit2Icon size="20" color="#cb0c9f" />
            </div>
          </>
        )}
        {editMode && (
          <>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <button
              type="submit"
              disabled={inProgress}
              className="btn bg-gradient-dark btn-sm btn_title"
              onClick={onSave}
            >
              {inProgress ? (
                <Spinner />
              ) : (
                <CheckIcon color="#fff" strokeWidth="2" size="18" />
              )}
              <Message id="save" />
            </button>
            <button
              type="button"
              className="btn no-btn"
              onClick={() => setEditMode(false)}
            >
              <Message id="cancel" />
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default ChatbotTitle;
