import Message from "../../../i18n/Message";
import TrashIcon from "../../../assets/icons/TrashIcon";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import ChatbotStatusBadge from "./ChatbotStatusBadge";

import "./ChatbotTile.scss";
import { deleteChatbot, loadChatbotList } from "../store/chatbotActionCreators";
import { linkToChatbot } from "../../../routes";
import ChatbotTab from "../chatbot-details/chatbotTab";

const ChatbotTile = ({ chatbot }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const onDeleteConfirmed = () => {
    const onFinished = () => {
      setShowDeleteConfirm(false);
      dispatch(loadChatbotList());
    };
    dispatch(
      deleteChatbot(chatbot.uid, intl, onFinished, () =>
        setShowDeleteConfirm(false)
      )
    );
  };

  return (
    <div
      className={
        "campaign_tile prompt_tile chatbot_tile border-radius-lg d-flex " +
        (prompt.approved ? "approved" : "draft")
      }
    >
      <div
        className="d-flex flex-column tile_body"
        onClick={() => nav(linkToChatbot(chatbot.uid, ChatbotTab.CHATS))}
      >
        <div className="tile-name">{chatbot.name}</div>
        <div className="metadata_row">
          <div className="meta_name">
            <Message id="created_on" />
          </div>
          <div className="meta_value">
            {dayjs(chatbot.dateCreated).format("LLL")}
          </div>
        </div>
        <div className="status_row"></div>
      </div>
      <div className="d-flex tile-actions">
        <div>
          <ChatbotStatusBadge chatbot={chatbot} />
        </div>
        <div className="confirm_container">
          {showDeleteConfirm && (
            <>
              <div
                className="cover"
                onClick={() => setShowDeleteConfirm(false)}
              ></div>
              <div className="confirm_panel">
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              </div>
            </>
          )}
        </div>
        <button
          className="no-btn tile-action"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteConfirm(true);
          }}
        >
          <TrashIcon size="16" color="#344767" />
          <Message id="delete" />
        </button>
        <button
          className="no-btn tile-action"
          onClick={() => nav(linkToChatbot(chatbot.uid, ChatbotTab.CHATS))}
        >
          <Edit2Icon size="16" color="#344767" />
          <Message id="edit" />
        </button>
      </div>
    </div>
  );
};

export default ChatbotTile;
