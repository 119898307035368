import backend from "../../../../backend/backend";
import label from "../../../../i18n/label";
import Notify from "../../../shared/Notify";
import SharedFormActions from "./SharedFormActions";

export const loadSharedForm = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getSharedForm(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: SharedFormActions.LOADED,
          form: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load a shared form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadSharedSubmissionForm = (
  intl,
  workspaceId,
  id,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getSharedSubmissionForm(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: SharedFormActions.LOADED,
          form: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load a shared form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadSharedPromptVersion = (
  intl,
  workspaceId,
  promptId,
  versionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getSharedPromptVersion(workspaceId, promptId, versionId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: SharedFormActions.PROMPT_VERSION_LOADED,
          version: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load campaigns", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadSharedFormSubmission = (
  intl,
  workspaceId,
  formId,
  submissionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getSharedFormSubmission(workspaceId, formId, submissionId)
      .then((data) => {
        console.log("loaded form submission", data);
        dispatch({
          type: SharedFormActions.SUBMISSION_LOADED,
          formSubmission: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load form submission", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
