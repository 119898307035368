import { FormattedMessage } from "react-intl";

const Message = ({ id, values = {} }) => {
  const newValues = {
    ...values,
    bold: (...chunks) => <strong>{chunks}</strong>,
    p: (...chunks) => <p>{chunks}</p>,
  };
  return <FormattedMessage id={id} values={newValues} />;
};

export default Message;
