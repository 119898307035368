import { useDispatch } from "react-redux";
import "./ChatSettingsDialog.scss";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import backend from "../../../../backend/backend";
import ChatActions from "../../store/chatActions";
import Notify from "../../../shared/Notify";
import label from "../../../../i18n/label";
import ModalDialog from "../../../shared/ModalDialog";
import Spinner from "../../../../assets/icons/Spinner";
import Message from "../../../../i18n/Message";
import LogoOpenAI from "../../../../assets/icons/LogoOpenAI";
import modelDescriptors, {
  getDescriptorById,
  modelVendor,
} from "../../../../model/model-providers/modelDescriptors";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import { localKeysRegistry } from "../../../../model/model-keys/modelKeysRegistry";
import { useWorkspaceId } from "../../../shared/workspace/workspaceContext";
import LogoAmazon from "../../../../assets/icons/LogoAmazon";
import LogoAnthropic from "../../../../assets/icons/LogoAnthropic";

const ChatSettingsDialog = ({ chat, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const workspaceId = useWorkspaceId();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(chat.name);
  const [modelId, setModelId] = useState(chat.modelId);
  const [formElem, setFormElem] = useState(null);
  const [nameInput, setNameInput] = useState(null);
  const [apiKeyInput, setApiKeyInput] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [systemPrompt, setSystemPrompt] = useState(chat.systemPrompt);

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  useEffect(() => {
    if (apiKeyInput) apiKeyInput.focus();
  }, [apiKeyInput]);

  const onSave = async () => {
    const valid = nameInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;

    setLoading(true);

    const onSuccess = () => {
      setLoading(false);
      onClose();
    };

    backend
      .updateChat(workspaceId, chat.uid, {
        name,
        modelId,
        systemPrompt,
      })
      .then((chat) => {
        console.log("Created chat: ", chat);
        dispatch({
          type: ChatActions.LOADED,
          chat,
        });
        onSuccess();
      })
      .catch((err) => {
        console.error("Chat creation failed ", err);
        Notify.error(label(intl, "error_create_chat"));
        setLoading(false);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onSave();
  };

  return (
    <ModalDialog onClose={onClose} className="create_chat_dialog">
      <div className="modal_title">
        <Message id={"settings"} />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="dialog_body">
          <div className="dialog_body_row">
            <label for="chat_name">
              <Message id="chat_name_label" />
            </label>
            <input
              id="chat_name"
              className="form-control"
              type="text"
              ref={(val) => setNameInput(val)}
              required
              maxLength="120"
              placeholder={label(intl, "chat_name_placeholder")}
              onChange={(e) => setName(e.target.value)}
              value={name}
              readOnly={loading}
            />
          </div>
          <div className="dialog_body_row">
            <div className="model_label">
              <Message id="model_selection_label" />
            </div>
            <div className="model_selector">
              {modelDescriptors.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={
                      "model_item " + (modelId === item.id ? " selected" : "")
                    }
                    onClick={() => setModelId(item.id)}
                  >
                    <div className="logo">
                      {item.vendor === modelVendor.OPENAI && <LogoOpenAI />}
                      {item.vendor === modelVendor.ANTHROPIC && (
                        <LogoAnthropic size={24} />
                      )}
                      {item.vendor === modelVendor.AMAZON && (
                        <LogoAmazon size={24} />
                      )}
                    </div>
                    <div className="name">{item.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="dialog_body_row">
            <label for="chat_system_prompt">
              <Message id="system_prompt_label" />
            </label>
            <textarea
              id="chat_system_prompt"
              rows="3"
              onChange={(e) => setSystemPrompt(e.target.value)}
              value={systemPrompt}
              placeholder={label(intl, "system_prompt_placeholder")}
              className="form-control"
            ></textarea>
          </div>
        </div>

        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id={"update_chat_settings_button"} />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default ChatSettingsDialog;
