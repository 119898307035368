import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage as Message } from "react-intl";

import SettingsPassword from "./SettingsPassword";
import Spinner from "../../assets/icons/Spinner";
import { login } from "../auth/store/actionCreators";
import UserIcon from "../../assets/icons/UserIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import { authSelectors } from "../auth/store/authReducer";
import SettingsAPIKey from "./SettingsAPIKey";
import GlobalLayout from "../shared/layout/GlobalLayout";

import "./SettingsPage.scss";

const SettingsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.getUser);

  useEffect(() => {
    if (null === user) {
      dispatch(login());
    }
  }, [dispatch, user]);

  return (
    <GlobalLayout contentPanelClassName="account_settings_content_panel">
      <h3>
        <Message id="account_settings" />
      </h3>
      {user ? (
        <div className="summary">
          <div>
            <UserIcon color="#344767" size={18} /> {user.name}
          </div>
          <div>
            <EmailIcon color="#344767" size={18} /> {user.email}
          </div>
        </div>
      ) : (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {(user?.source === 0 || user?.source === 5 || !user.source) && (
        <SettingsPassword />
      )}
      <SettingsAPIKey user={user} />
    </GlobalLayout>
  );
};
export default SettingsPage;
