import React, { useEffect, useRef } from "react";
import Message from "../../i18n/Message";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../auth/store/authReducer";
import Spinner from "../../assets/icons/Spinner";
import { login } from "../auth/store/actionCreators";

const SubscriptionUsage = () => {
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.getUser);
  const ref = useRef(false);
  useEffect(() => {
    if (ref.value === false) {
      dispatch(login());
      ref.value = true;
    }
  }, [ref]);

  if (!user) return <Spinner />;

  let progressNum = 0;
  if (user?.subscriptionUsage) {
    const usedTokens =
      user.subscriptionUsage.inputTokenCount +
      user.subscriptionUsage.outputTokenCount;
    if (usedTokens >= user.subscriptionUsage.tokenLimit) {
      progressNum = 100;
    } else {
      progressNum = Math.ceil(
        (usedTokens / user.subscriptionUsage.tokenLimit) * 100
      );
    }
    console.log("USAGE ", usedTokens, progressNum);
    //progressNum = 86;
  }
  const progressPct = progressNum + "%";
  const realPct = progressNum < 15 ? "15%" : progressNum + "%";
  let progressClass = "";
  if (progressNum >= 99) {
    progressClass = "red full";
  } else if (progressNum > 85) {
    progressClass = "red";
  } else if (progressNum > 60) {
    progressClass = "orange";
  }

  return (
    <div className="limits_panel">
      <h3>
        <Message id="subscription_usage" />
      </h3>
      <div>
        <Message
          id="subscription_usage_tokens"
          values={{
            current: Number(
              user.subscriptionUsage.inputTokenCount +
                user.subscriptionUsage.outputTokenCount
            ).toLocaleString(),
            total: Number(user.subscriptionUsage.tokenLimit).toLocaleString(),
            daysTillReset: user.subscriptionUsage.daysTillReset,
          }}
        />
      </div>
      <div className="progress_bar_container">
        <div className="meter animate">
          <span style={{ width: realPct }} className={progressClass}>
            {progressPct}
            <span></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionUsage;
