import React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import CheckSquareIcon from "../../../assets/icons/CheckSquareIcon";

import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import FolderIcon from "../../../assets/icons/FolderIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { isTeamAdmin } from "../../../backend/memberRole";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import { linkToApiKeys, linkToChats } from "../../../routes";
import { subscriptionWorkspaceLimits } from "../pricingLimits";
import CreateWorkspaceDialog from "./CreateWorkspaceDialog";

import "./WorkspaceDropdown.scss";

const WorkspaceDropdown = ({ workspace, workspaces, user }) => {
  const nav = useNavigate();
  const [shown, setShown] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const intl = useIntl();

  if (!workspace) return null;

  const shownWorkspaces = workspaces.filter(
    (item) => item.uid !== workspace.uid
  );

  const maxWorkspaces = subscriptionWorkspaceLimits[user.subscription.plan];
  let isLimitReached = workspaces.length >= maxWorkspaces;

  return (
    <>
      <div className="space_selector">
        <div
          className="current"
          onClick={() => setShown(!shown)}
          title={workspace.name}
        >
          <CheckSquareIcon color="#383838" strokeWidth="1.5" />
          <div className="widen">{workspace.name}</div>
          <ChevronDownIcon color="#383838" size="24" />
        </div>

        {shown && (
          <div className="options">
            {shownWorkspaces.map((item) => {
              return (
                <div
                  key={item.uid}
                  className="project_item"
                  onClick={() => nav(linkToChats(item.uid))}
                >
                  <FolderIcon color="#383838" strokeWidth="1.5" />
                  {item.name}
                </div>
              );
            })}
            {isTeamAdmin(user) && (
              <>
                <div className="add" key="add">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => setShowCreateDialog(true)}
                    disabled={isLimitReached}
                  >
                    <PlusIcon color="#383838" strokeWidth="2" />
                    <Message id="create_workspace" />
                  </button>
                  {isLimitReached && (
                    <div
                      className="subscription_limit_reached"
                      dangerouslySetInnerHTML={{
                        __html: label(intl, "subscription_limit_reached"),
                      }}
                    ></div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {showCreateDialog && (
        <CreateWorkspaceDialog onClose={() => setShowCreateDialog(false)} />
      )}
    </>
  );
};

export default WorkspaceDropdown;
