import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import FormActions from "./FormActions";

export const createForm = (intl, form, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createForm(form, workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FormActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create a form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadForms = (intl, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getForms(workspaceId)
      .then((data) => {
        console.log("loaded forms", data);
        dispatch({
          type: FormActions.LIST_LOADED,
          list: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadForm = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getForm(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FormActions.LOADED,
          form: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load a form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateForm = (
  intl,
  workspaceId,
  uid,
  form,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updateForm(workspaceId, uid, form)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FormActions.UPDATED,
          form: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update a form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteForm = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deleteForm(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: FormActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadFormSubmissions = (
  intl,
  workspaceId,
  formId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getFormSubmissions(workspaceId, formId)
      .then((data) => {
        console.log("loaded form submissions", data);
        dispatch({
          type: FormActions.SUBMISSIONS_LOADED,
          submissions: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadFormSubmission = (
  intl,
  workspaceId,
  formId,
  submissionId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getFormSubmission(workspaceId, formId, submissionId)
      .then((data) => {
        console.log("loaded form submission", data);
        dispatch({
          type: FormActions.SUBMISSION_LOADED,
          formSubmission: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load form submission", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
