import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import ChatbotLayout from "../shared/ChatbotLayout";

import "./ChatbotDetailsPage.scss";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { chatbotSelectors } from "../store/chatbotReducer";
import { useEffect, useState } from "react";
import {
  deleteChatbot,
  loadChatbot,
  loadChatbotList,
} from "../store/chatbotActionCreators";
import Spinner from "../../../assets/icons/Spinner";
import TrashIcon from "../../../assets/icons/TrashIcon";
import Message from "../../../i18n/Message";
import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";
import { linkToChatbotList } from "../../../routes";
import ChatbotStatusBadge from "../chatbots/ChatbotStatusBadge";
import ChatbotTitle from "./ChatbotTitle";
import ChatbotZeroState from "./ChatbotZeroState";
import { BookOpen, MessagesSquare, Share } from "lucide-react";
import ChatsTab from "./chats/ChatsTab";
import TrainTab from "./train/TrainTab";
import EmbedTab from "./embed/EmbedTab";
import ChatbotTab from "./chatbotTab";

const ChatbotDetailsPage = () => {
  const { chatbotId, tabId } = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const chatbot = useSelector(chatbotSelectors.getChatbot);
  const [tab, setTab] = useState(tabId);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!chatbot || (chatbot && chatbot.chatbot.uid !== chatbotId)) {
      console.log("Loding chatbot ", chatbotId, ", - ", chatbot?.uid);
      dispatch(loadChatbot(chatbotId, intl));
    }
  }, [dispatch, intl, chatbotId, chatbot]);

  const onDeleteConfirmed = () => {
    const onSuccess = () => {
      setShowDeleteConfirm(false);
      dispatch(loadChatbotList());
      nav(linkToChatbotList());
    };
    dispatch(
      deleteChatbot(chatbot.chatbot.uid, intl, onSuccess, () =>
        setShowDeleteConfirm(false)
      )
    );
  };

  console.log("ChatbotDetailsPage: ", chatbotId, " - ", chatbot?.chatbot?.uid);
  return (
    <ChatbotLayout contentPanelClassName="chatbot_details_page">
      {(!chatbot || (chatbot && chatbot.chatbot.uid !== chatbotId)) && (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {chatbot && chatbot.chatbot.uid === chatbotId && (
        <>
          <div className="title_row">
            <div className="title_column">
              <div className="breadcrumbs">
                <div onClick={() => nav(linkToChatbotList())}>
                  <ChevronLeftIcon
                    size={16}
                    strokeWidth="2"
                    color="rgb(103, 116, 142)"
                  />
                  <Message id="back_to_chatbot_list" />
                </div>
              </div>
              <div className="title_section">
                <ChatbotTitle chatbot={chatbot.chatbot} />
                <div className="title_actions">
                  <div className="confirm_container">
                    {showDeleteConfirm && (
                      <>
                        <div
                          className="cover"
                          onClick={() => setShowDeleteConfirm(false)}
                        ></div>
                        <div className="confirm_panel">
                          <button
                            className="btn bg-gradient-primary btn-sm"
                            onClick={onDeleteConfirmed}
                          >
                            <Message id="confirm_delete" />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    className="btn no-btn tile-action"
                    onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                  >
                    <TrashIcon size="16" color="#344767" />
                    <Message id="delete" />
                  </button>
                </div>
              </div>
              <div className="undertitle_row">
                <div className="metadata_column">
                  <div className="metadata_row">
                    <div className="meta_name">
                      <Message id="created_on" />
                    </div>
                    <div className="meta_value">
                      {dayjs(chatbot.chatbot.dateCreated).format("DD MMM")}
                    </div>
                  </div>
                  <div className="metadata_row">
                    <div className="meta_name">
                      <Message id="status" />
                    </div>
                    <div className="meta_value">
                      <ChatbotStatusBadge chatbot={chatbot.chatbot} />
                    </div>
                  </div>
                </div>
                <div className="tabs_column">
                  <div className="tabs_container">
                    <div className="tab_group">
                      <div
                        className={
                          "tab_group_item " +
                          (tab === ChatbotTab.CHATS ? " selected " : "")
                        }
                        onClick={() => setTab(ChatbotTab.CHATS)}
                      >
                        <MessagesSquare size={18} color="#344767" />
                        <Message id="chatbot_tab_chats" />
                      </div>
                      <div
                        className={
                          "tab_group_item " +
                          (tab === ChatbotTab.TRAIN ? " selected " : "")
                        }
                        onClick={() => setTab(ChatbotTab.TRAIN)}
                      >
                        <BookOpen size={18} color="#344767" />
                        <Message id="chatbot_tab_train" />
                      </div>
                      <div
                        className={
                          "tab_group_item " +
                          (tab === ChatbotTab.EMBED ? " selected " : "")
                        }
                        onClick={() => setTab(ChatbotTab.EMBED)}
                      >
                        <Share size={18} color="#344767" />
                        <Message id="chatbot_tab_embed" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="placeholder"></div>
              </div>
            </div>
          </div>
          {tab === ChatbotTab.CHATS && <ChatsTab />}
          {tab === ChatbotTab.TRAIN && <TrainTab />}
          {tab === ChatbotTab.EMBED && <EmbedTab />}
        </>
      )}
    </ChatbotLayout>
  );
};

export default ChatbotDetailsPage;
