import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import ApiKeyActions from "./ApiKeyActions";

const initialState = {
  data: {
    list: null,
    listCounter: 0,

    key: null,
  },
};

export const apiKeySelectors = {
  getList: (state) => state.apiKeys.data.list,
  getListCounter: (state) => state.apiKeys.data.listCounter,
  getKey: (state) => state.apiKeys.data.key,
};

const onLoadList = produce((draft, action) => {
  draft.data.list = action.list;
});

const onListChanged = produce((draft) => {
  draft.data.listCounter++;
});

const onKeyLoaded = produce((draft, action) => {
  const obj = action.key;
  draft.data.key = obj;
});

const onKeyUpdated = produce((draft, action) => {
  const obj = action.key;
  draft.data.key = obj;
  draft.data.listCounter++;
});

const handlers = {
  [ApiKeyActions.LIST_LOADED]: onLoadList,
  [ApiKeyActions.LIST_CHANGED]: onListChanged,
  [ApiKeyActions.LOADED]: onKeyLoaded,
  [ApiKeyActions.UPDATED]: onKeyUpdated,
};

const apiKeyReducer = reducerUtils.createReducer(initialState, handlers);

export default apiKeyReducer;
