import WorkspacePageWrapper from "../../workspace/WorkspacePageWrapper";
import PromptDetailsPage from "./PromptDetailsPage";

const PromptDetailsPageWrapper = ({ area }) => {
  return (
    <WorkspacePageWrapper contentClassName="prompt_details_main" area={area}>
      <PromptDetailsPage />
    </WorkspacePageWrapper>
  );
};

export default PromptDetailsPageWrapper;
