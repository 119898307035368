import React from "react";
import { useIntl } from "react-intl";
import label from "../../i18n/label";
import Message from "../../i18n/Message";
import ModalDialog from "../shared/ModalDialog";

const ConfirmUpgradeDialog = ({ onConfirm, onCancel, planKey }) => {
  const intl = useIntl();
  return (
    <ModalDialog onClose={onCancel}>
      <h1>
        <Message id="change_plan" />
      </h1>
      <div className="upgrade_confirm_text">
        <Message
          id="upgrade_confirm_text"
          values={{ plan: label(intl, "plan_" + planKey) }}
        />
      </div>
      <div className="buttons">
        <button className="btn bg-gradient-dark" onClick={onConfirm}>
          <Message id="confirm_upgrade" />
        </button>
        <button className="btn no-btn" onClick={onCancel}>
          <Message id="cancel" />
        </button>
      </div>
    </ModalDialog>
  );
};

export default ConfirmUpgradeDialog;
