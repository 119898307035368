import { useEffect, useState } from "react";
import Cookie from "js-cookie";
import authStore from "../../auth/AuthStore";

const useWebsocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    Cookie.set("pmtl_tk", authStore.getToken());
    var ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss://" : "ws://") +
        window.location.host +
        "/backend/ws/chat"
    );
    ws.onopen = function (event) {
      console.log("[WS] Connected to: " + event.currentTarget.url);
      Cookie.remove("pmtl_tk", { path: "/" });
    };
    ws.onclose = (error) => {
      // reconnect?
      console.log("[WS] closed: " + error);
    };

    setSocket(ws);
    return () => {
      ws.close();
    };
  }, [setSocket]);

  return socket;
};

export default useWebsocket;
