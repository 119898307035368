import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import label from "../../../../i18n/label";
import SendIcon from "../../../../assets/icons/SendIcon";
import Spinner from "../../../../assets/icons/Spinner";
import Message from "../../../../i18n/Message";

import "./PromptTextarea.scss";
import promptControlViewType from "../promptControlViewType";

const PromptTextarea = ({ view, onSubmit }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [formElem, setFormElem] = useState(null);
  const [input, setInput] = useState(null);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitOnEnter, setSubmitOnEnter] = useState(true);
  const [textareaStyles, setTextAreaStyles] = useState({ height: "56px" });

  const hidden = view !== promptControlViewType.TEXTAREA;
  useEffect(() => {
    if (input && !loading && !hidden) {
      input.focus();
    }
  }, [input, loading, hidden]);

  window.gAppendPromptToMessage = (item) => {
    setContent((prev) => {
      const newValue = (prev ? prev + " " : "") + item.content;
      if (newValue.length >= 105) {
        setTimeout(() => {
          const elem = document.querySelector(".prompt_textarea");
          setTextAreaStyles((prev) => {
            return {
              ...prev,
              overflow: "hidden",
              height: elem.scrollHeight + 2 + "px",
            };
          });
        }, 150);
      }
      return newValue;
    });
  };

  const onSendClicked = () => {
    if (loading) return;
    if (content) {
      setLoading(true);

      const onFinish = () => {
        setLoading(false);
      };

      onSubmit(content, onFinish, onFinish);
      setContent("");
    } else {
      input.checkValidity();
      input.reportValidity();
    }
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSendClicked();
  };
  const onKeyUp = (e) => {
    const elem = e.target;
    const currentHeight = parseInt(elem.style.height);
    console.log(
      "Element height now = ",
      currentHeight,
      " with scroll: ",
      e.target.scrollHeight,
      e.target.clientHeight
    );

    if (e.target.scrollHeight <= 220) {
      setTextAreaStyles({
        ...textareaStyles,
        overflow: "hidden",
        height: e.target.scrollHeight + 2 + "px",
      });
      /*
      if (
        currentHeight !== elem.scrollHeight + 1 &&
        currentHeight + 2 !== elem.scrollHeight
      ) {
        console.log("Appluing height ", e.target.scrollHeight, new Date());
        elem.style.height = "auto";
        elem.style.height = elem.scrollHeight + 2 + "px";
        setTextAreaStyles({
          ...textareaStyles,
          overflow: "hidden",
          height: e.target.scrollHeight + 2 + "px",
        });
      }
      */
    } else {
      setTextAreaStyles({
        ...textareaStyles,
        overflow: "auto",
      });
    }
  };
  const onKeyDown = (e) => {
    if (submitOnEnter && e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSendClicked();
    }
  };
  return (
    <div className={"prompt_textarea " + (hidden ? "hidden_view" : "")}>
      <form onSubmit={onFormSubmit} ref={(val) => setFormElem(val)}>
        <textarea
          ref={(val) => setInput(val)}
          autoFocus={true}
          disabled={loading}
          onChange={(e) => setContent(e.target.value)}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          style={textareaStyles}
          value={content}
          required
          placeholder={label(intl, "prompt_text_placeholder")}
          className="form-control prompt_textarea"
        ></textarea>
      </form>
      <div className="bottom_row">
        <div className="options_panel">
          <div className="submit_on_enter">
            <input
              type="checkbox"
              checked={submitOnEnter}
              onChange={() => setSubmitOnEnter(!submitOnEnter)}
            />
            <div onClick={() => setSubmitOnEnter(!submitOnEnter)}>
              <Message id="submit_on_enter" />
            </div>
          </div>
        </div>
        <div
          className={"send_button " + (loading ? "loading" : "")}
          onClick={onSendClicked}
        >
          {loading ? <Spinner /> : <SendIcon size={18} color="#fff" />}
          <Message id="prompt_control_send" />
        </div>
      </div>
    </div>
  );
};

export default PromptTextarea;
