import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import FormActions from "./FormActions";

const initialState = {
  data: {
    list: null,
    listCounter: 0,

    form: null,
    submissions: null,
    submissionsCounter: 0,
    formResponse: null,
    formSubmission: null,
  },
};

export const formSelectors = {
  getList: (state) => state.forms.data.list,
  getListCounter: (state) => state.forms.data.listCounter,
  getForm: (state) => state.forms.data.form,
  getSubmissions: (state) => state.forms.data.submissions,
  getSubmissionsCounter: (state) => state.forms.data.submissionsCounter,
  getFormResponse: (state) => state.forms.data.formResponse,
  getFormSubmission: (state) => state.forms.data.formSubmission,
};

const onLoadList = produce((draft, action) => {
  draft.data.list = action.list;
});

const onListChanged = produce((draft) => {
  draft.data.listCounter++;
});

const onFormLoaded = produce((draft, action) => {
  const obj = action.form;
  draft.data.form = obj;
});

const onFormUpdated = produce((draft, action) => {
  const obj = action.form;
  draft.data.form = obj;
  draft.data.listCounter++;
});

const onSubmissionsLoaded = produce((draft, action) => {
  draft.data.submissions = action.submissions;
});

const onSubmissionsChanged = produce((draft) => {
  draft.data.submissionsCounter++;
});

const onFormResponseLoaded = produce((draft, action) => {
  const obj = action.formResponse;
  draft.data.formResponse = obj;
});

const onFormSubmissionLoaded = produce((draft, action) => {
  const obj = action.formSubmission;
  draft.data.formSubmission = obj;
});

const handlers = {
  [FormActions.LIST_LOADED]: onLoadList,
  [FormActions.LIST_CHANGED]: onListChanged,
  [FormActions.LOADED]: onFormLoaded,
  [FormActions.UPDATED]: onFormUpdated,
  [FormActions.SUBMISSIONS_LOADED]: onSubmissionsLoaded,
  [FormActions.SUBMISSIONS_CHANGED]: onSubmissionsChanged,
  [FormActions.RESPONSE_LOADED]: onFormResponseLoaded,
  [FormActions.SUBMISSION_LOADED]: onFormSubmissionLoaded,
};

const formReducer = reducerUtils.createReducer(initialState, handlers);

export default formReducer;
