import english from "./resources/en.json";
import german from "./resources/de.json";

import "dayjs/locale/de";
import "dayjs/locale/en";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const allResources = {
  en: english,
  de: german,
};

export const selectLanguage = () => {
  const currentLang = navigator.language.split(/[-_]/)[0];
  console.log("currentLang=", currentLang);
  let lang = currentLang;
  let resources = allResources[currentLang];
  if (resources === undefined || Object.keys(resources).length === 0) {
    resources = allResources["en"];
    lang = "en";
  }
  return {
    lang,
    resources,
  };
};
