import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import LoginPage from "./LoginPage";
import { checkAuth } from "./store/actionCreators";
import { authSelectors } from "./store/authReducer";


const ProtectedPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(authSelectors.getUser);
    const [isLoading, setLoading] = useState(true);
    //console.log("ProtectedPage user", user);
    if(!user) {
       //console.log("Checking auth...")
        dispatch(
            checkAuth(() => {
              console.log("onError callback", isLoading);
              setLoading(false);
            }),
          );
        return <LoginPage loadingState={isLoading} />;
    } else {
        return <Outlet />;
    }
}

export default ProtectedPage;