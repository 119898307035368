import KeyIcon from "../../assets/icons/KeyIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import Edit3Icon from "../../assets/icons/Edit3Icon";
import memberRole, { teamRole } from "../../backend/memberRole";
import Message from "../../i18n/Message";

const MemberRoleDisplay = ({ role }) => {
  return (
    <>
      {(role === memberRole.ADMIN || role === teamRole.TEAM_ADMIN) && <KeyIcon color="#664d03" strokeWidth="2" size={18} />}
      {role === memberRole.CONTRIBUTOR && <Edit3Icon color="#664d03" strokeWidth="2" size={18} />}
      {(role === memberRole.READER || role === teamRole.READER) && <EyeIcon color="#664d03" strokeWidth="2" size={18} />}
      <Message id={"role_" + role} />
    </>
  );
};

export default MemberRoleDisplay;
