import { useIntl } from "react-intl";
import { useState } from "react";

import "./ForgotPasswordPage.scss";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import backend from "../../../backend/backend";
import Spinner from "../../../assets/icons/Spinner";

const ForgotPasswordPage = ({ loadingState = false }) => {
  const intl = useIntl();
  const [passwordForm, setPasswordForm] = useState(null);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //alex+appsumo1234@anwendo.com
  const onPasswordSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  /*
  const onApiKeySubmit = () => {
    onSubmit();
  };
*/
  const onButtonClicked = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (email && passwordForm.checkValidity()) {
      setLoading(true);
      backend
        .resetPassword({ email })
        .then(() => {
          setMessage(label(intl, "reset_password_success"));
          setEmail("");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in reset", error);
          Notify.error(label(intl, "error_load"));
          setLoading(false);
        });
    } else {
      passwordForm.reportValidity();
    }
  };

  return (
    <div className="forgot_password_page">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      <div className="container">
        <div className="header">
          <h5>
            <Message id="forgot_password_title" />
          </h5>
          {message && <div className="success_message">{message}</div>}
        </div>
        {!loadingState && (
          <div className="form">
            <form
              onSubmit={onPasswordSubmit}
              ref={(elem) => setPasswordForm(elem)}
            >
              <div className="field">
                <input
                  type="email"
                  autoComplete="email"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={label(intl, "login_email")}
                />
              </div>

              <div style={{ position: "relative" }}>
                <input
                  type="submit"
                  name="submit"
                  style={{ position: "absolute", left: "-10000px" }}
                />
              </div>
            </form>

            <div className="field">
              <button
                type="button"
                disabled={loading}
                className="btn"
                onClick={onButtonClicked}
              >
                {loading && <Spinner color="#fff" />}
                <Message id="forgot_password_button" />
              </button>
            </div>
            <div className="forgot_password">
              <a href="/app/register">
                <Message id="register_title" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
