import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import Spinner from "../../../assets/icons/Spinner";
import UserCircleIcon from "../../../assets/icons/UserCircleIcon";
import Message from "../../../i18n/Message";
import {
  cancelWorkspaceInvitation,
  deleteWorkspaceMember,
  loadWorkspaceUsers,
  sendWorkspaceInvite,
  updateWorkspaceMember,
} from "./store/workspaceUsersActionCreators";
import { workspaceUserSelectors } from "./store/workspaceUsersReducer";
import MailIcon from "../../../assets/icons/MailIcon";
import CheckIcon from "../../../assets/icons/CheckIcon";
import CrossIcon from "../../../assets/icons/CrossIcon";
import UserPlusIcon from "../../../assets/icons/UserPlusIcon";
import { authSelectors } from "../../auth/store/authReducer";
import { teamSelectors } from "../../team/store/teamReducer";
import { loadTeam } from "../../team/store/teamActionCreators";
import { login } from "../../auth/store/actionCreators";
import { subscriptionUserLimits } from "../../shared/pricingLimits";
import label from "../../../i18n/label";
import InviteMemberDialog from "../../team/InviteMemberDialog";
import DeleteMemberDialog from "../../team/DeleteMemberDialog";
import UserXIcon from "../../../assets/icons/UserXIcon";
import MemberRoleDisplay from "../../team/MemberRoleDisplay";
import Notify from "../../shared/Notify";
import MemberRoleDropdown from "../../team/MemberRoleDropdown";

import "./WorkspaceUsers.scss";

const WorkspaceUsers = ({ workspace }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const users = useSelector(workspaceUserSelectors.getUsers(workspace?.uid));
  const user = useSelector(authSelectors.getUser);
  const team = useSelector(teamSelectors.getTeam);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(false);
  const [roleChangeInProgress, setRoleChangeInProgress] = useState(false);

  useEffect(() => {
    if (workspace) {
      dispatch(loadWorkspaceUsers(intl, workspace.uid));
    }
  }, [workspace, intl, dispatch]);

  useEffect(() => {
    if (null === user) {
      dispatch(login());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (null === team) {
      dispatch(loadTeam(intl));
    }
  }, [dispatch, team, intl]);

  const maxUsers = subscriptionUserLimits[user.subscription.plan];
  let isLimitReached = maxUsers === 1;
  /*
    if (team) {
        const totalUsers = team.invitations.length + team.members.length;
        isLimitReached = totalUsers >= maxUsers;
    }
    */

  const onInvite = (params, onSuccess, onError) => {
    dispatch(
      sendWorkspaceInvite(intl, workspace.uid, params, onSuccess, onError)
    );
  };

  const onCancelInvitation = (uid) => {
    dispatch(cancelWorkspaceInvitation(intl, workspace.uid, uid));
  };

  const onMemberDelete = (member, onSuccess, onError) => {
    dispatch(
      deleteWorkspaceMember(intl, workspace.uid, member.uid, onSuccess, onError)
    );
  };

  const onMemberRoleChanged = (memberUid, value) => {
    console.log("onMemberRoleChanged ", memberUid, value);
    setRoleChangeInProgress(memberUid);
    const onSuccess = () => {
      setRoleChangeInProgress(null);
    };
    const onError = () => {
      setRoleChangeInProgress(null);
      Notify.error(label(intl, "error_unexpected"));
    };
    dispatch(
      updateWorkspaceMember(
        intl,
        workspace.uid,
        memberUid,
        value,
        onSuccess,
        onError
      )
    );
  };

  return (
    <div className="settings_panel workspace_users_panel">
      <h3>
        <Message id="workspace_users" />
      </h3>
      <div>
        {!users && <Spinner />}
        {users && (
          <div>
            {/* ===== workspace invitations ====== */}
            <h4>
              {" "}
              <Message id="workspace_users_local" />
            </h4>
            <div className="actions_panel">
              <button
                className="btn btn-outline-dark "
                onClick={() => setShowInviteDialog(true)}
                disabled={isLimitReached}
              >
                <UserPlusIcon color="#67748e" />
                <Message id="invite" />
              </button>
              {isLimitReached && (
                <div
                  className="upgrade_notice"
                  dangerouslySetInnerHTML={{
                    __html: label(intl, "user_limit_reached"),
                  }}
                ></div>
              )}
            </div>
            <div className="invitations_panel">
              {users.workspaceInvitations.map((inv) => {
                return (
                  <div className="invitation" key={inv.uid}>
                    <div>
                      <MailIcon color="#664d03" />{" "}
                      <span className="inv_email">{inv.email}</span>
                    </div>
                    <div>
                      <MemberRoleDisplay role={inv.role} />
                    </div>
                    <div>
                      <CalendarIcon color="#664d03" />
                      {dayjs(inv.dateCreated).format("DD MMM")}
                    </div>
                    <div>
                      <CheckIcon color="#664d03" strokeWidth="2" />
                      <Message id="invitation_sent" />
                    </div>
                    <div className="action">
                      <div
                        className="app-btn"
                        onClick={() => onCancelInvitation(inv.uid)}
                      >
                        <CrossIcon color="#664d03" />
                        <Message id="cancel" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* ===== workspace users ====== */}
            <div className="members_panel">
              {users.workspaceMembers.map((member) => {
                console.log("Rendering member ", member);
                return (
                  <div className="member" key={member.uid}>
                    <div>
                      <div>
                        <UserCircleIcon color="#67748e" />{" "}
                      </div>
                      <div className="member_name">
                        <div>{member.user.name}</div>
                        <div className="member_unteremail">
                          {member.user.email}
                        </div>
                      </div>
                    </div>
                    <div>
                      {roleChangeInProgress === member.uid ? (
                        <Spinner />
                      ) : member.user.uid === user.uid ? (
                        <MemberRoleDisplay role={member.role} />
                      ) : (
                        <MemberRoleDropdown
                          value={member.role}
                          onChange={(value) =>
                            onMemberRoleChanged(member.uid, value)
                          }
                        />
                      )}
                    </div>
                    <div>
                      <CalendarIcon color="#664d03" />
                      {dayjs(member.dateCreated).format("DD MMM")}
                    </div>
                    <div className="action">
                      {user.email !== member.user.email && (
                        <div
                          className="btn"
                          onClick={() => setMemberToDelete(member)}
                        >
                          <UserXIcon color="#344767" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* ===== account users ====== */}
            <h4>
              {" "}
              <Message id="workspace_users_from_account" />
            </h4>
            <div className="members_panel">
              {users.teamMembers.map((member) => {
                return (
                  <div className="member" key={member.uid}>
                    <div>
                      <UserCircleIcon color="#67748e" /> {member.user.name}
                    </div>
                    <div>{member.user.email}</div>
                    <div>
                      <CalendarIcon color="#67748e" size={18} />
                      {dayjs(member.dateCreated).format("DD MMM")}
                    </div>
                  </div>
                );
              })}
            </div>

            {showInviteDialog && (
              <InviteMemberDialog
                onClose={() => setShowInviteDialog(false)}
                onInvite={onInvite}
              />
            )}
            {memberToDelete && (
              <DeleteMemberDialog
                member={memberToDelete}
                onDelete={onMemberDelete}
                onClose={() => setMemberToDelete(null)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkspaceUsers;
