const parseParams = (text) => {
  const matches = text.matchAll(/{([^}]+)}/g);
  const params = [];
  for (const match of matches) {
    params.push(match[1]);
  }
  return params;
};

export default parseParams;
