const apiKeyStorage = window.localStorage;
const TOKEN_KEY = "pmtleo-ak";

class AuthStore {
  getToken() {
    return apiKeyStorage.getItem(TOKEN_KEY);
  }
  setToken(value) {
    apiKeyStorage.setItem(TOKEN_KEY, value);
  }
}

const authStore = new AuthStore();

export default authStore;
