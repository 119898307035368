import Message from "../../../i18n/Message";
import "./SharedPageLayout.scss";

const SharedPageLayout = ({ bodyClassName = "", children }) => {
  return (
    <div className="shared_page_layout">
      <div className={"shared_page_layout_body " + bodyClassName}>
        {children}
        <div className="powered_by">
          Powered by{" "}
          <a href="https://promptleo.com" target="_blank" rel="noopener">
            PromptLeo
          </a>
        </div>
      </div>
    </div>
  );
};

export default SharedPageLayout;
