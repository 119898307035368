import backend from "../../../../backend/backend";
import label from "../../../../i18n/label";
import Notify from "../../../shared/Notify";
import WorkspaceUserActions from "./workspaceUsersActionTypes";

export const loadWorkspaceUsers = (intl, workspaceId) => (dispatch) => {
  backend
    .getWorkspaceMembers(workspaceId)
    .then((data) => {
      //console.log("users response", data);
      dispatch({
        type: WorkspaceUserActions.USERS_LOADED,
        users: data,
        workspaceId,
      });
    })
    .catch((err) => {
      console.error("Failed to load users", err);
      Notify.error(label(intl, "error_unexpected"));
    });
};

export const sendWorkspaceInvite =
  (intl, workspaceUid, params, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .sendWorkspaceInvite(workspaceUid, params)
      .then((data) => {
        //console.log("send invite response", data);
        dispatch(loadWorkspaceUsers(intl, workspaceUid));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to send an invite", err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };

export const cancelWorkspaceInvitation =
  (intl, workspaceUid, uid, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .cancelWorkspaceInvite(workspaceUid, uid)
      .then((data) => {
        //console.log("cancel invite response", data);
        dispatch(loadWorkspaceUsers(intl, workspaceUid));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to cancel invite", err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };

export const updateWorkspaceMember =
  (intl, workspaceUid, uid, role, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .updateWorkspaceMember(workspaceUid, uid, role)
      .then(() => {
        dispatch(loadWorkspaceUsers(intl, workspaceUid));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to delete a member", uid, err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };

export const deleteWorkspaceMember =
  (intl, workspaceUid, uid, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .deleteWorkspaceMember(workspaceUid, uid)
      .then((data) => {
        //console.log("delete member response", data);
        dispatch(loadWorkspaceUsers(intl, workspaceUid));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to delete a member", uid, err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };
