const ChatActions = {
  LOADED: "chat/loaded",
  UPDATED: "chat/updated",
  POSTED_MESSAGE: "chat/message/posted",
  GOT_MESSAGE_PART: "chat/message/part",
  MESSAGE_PART_ERROR: "chat/message/part-error",
  FINISHED_MESSAGE_PARTS: "chat/message/parts-finished",
  GOT_MESSAGE_RELY: "chat/message/reply",
  MESSAGE_DELETED: "chat/message/deleted",
  MESSAGE_UPDATED: "chat/message/updated",
  MESSAGE_FILE_UPLOADED: "chat/file/uploaded",
  LIST_LOADED: "chat-list/loaded",
  LIST_CHANGED: "chat-list/changed",
  FOLDER_LOADED: "chat-folder/loaded",
};

export default ChatActions;
