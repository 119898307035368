export const modelVendor = {
  OPENAI: "OPENAI",
  ANTHROPIC: "ANTHROPIC",
  AMAZON: "AMAZON",
  META: "META",
};

export const openAIModel = {
  GPT3: "system:openai-gpt3.5",
};

const modelDescriptors = [
  /*
  {
    id: "system:openai-gpt3.5",
    name: "GPT 3.5 (Deprecated)",
    vendor: modelVendor.OPENAI,
    icon: "",
  },
  */
  {
    id: "system:openai-gpt4",
    name: "GPT-4",
    vendor: modelVendor.OPENAI,
    contextWindow: "8k",
    icon: "",
  },
  {
    id: "system:openai-gpt4-turbo",
    name: "GPT-4 Turbo",
    vendor: modelVendor.OPENAI,
    contextWindow: "128k",
    icon: "",
  },
  {
    id: "system:claude-v2",
    name: "Claude 2",
    vendor: modelVendor.ANTHROPIC,
    contextWindow: "100k",
    icon: "",
  },
  {
    id: "system:claude-v2.1",
    name: "Claude 2.1",
    vendor: modelVendor.ANTHROPIC,
    contextWindow: "200k",
    icon: "",
  },
  {
    id: "system:claude-instant-v1",
    name: "Claude Instant 1.2",
    vendor: modelVendor.ANTHROPIC,
    contextWindow: "100k",
    icon: "",
  },
  /*{
    id: "system:openai-gpt4-32k",
    name: "GPT-4 32k",
    vendor: modelVendor.OPENAI,
    contextWindow: "32k",
    icon: "",
  },*/
  {
    id: "system:openai-chatgpt",
    name: "GPT 3.5",
    vendor: modelVendor.OPENAI,
    contextWindow: "4k",
    icon: "",
  },
  {
    id: "system:amazon.titan-text-express-v1",
    name: "Titan Text G1 - Express",
    vendor: modelVendor.AMAZON,
    contextWindow: "8k",
    icon: "",
  },
  {
    id: "system:amazon.titan-text-lite-v1",
    name: "Titan Text G1 - Lite",
    vendor: modelVendor.AMAZON,
    contextWindow: "4k",
    icon: "",
  },
  /*
  {
    id: "system:meta.llama2-70b-chat-v1",
    name: "Llama 2 Chat 70B",
    vendor: modelVendor.META,
    contextWindow: "4k",
    icon: "",
  },
  {
    id: "system:meta.llama2-70b-v1",
    name: "Llama 2 70B",
    vendor: modelVendor.META,
    contextWindow: "4k",
    icon: "",
  },
  */
];

export const getDescriptorById = (id) =>
  modelDescriptors.find((item) => item.id === id);

export default modelDescriptors;
