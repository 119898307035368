import { useEffect, useState } from "react";
import SendIcon from "../../../assets/icons/SendIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import "./WidgetPromptTextarea.scss";
import { useIntl } from "react-intl";

const WidgetPromptTextarea = ({ onSubmit }) => {
  const intl = useIntl();
  const [formElem, setFormElem] = useState(null);
  const [input, setInput] = useState(null);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [textareaStyles, setTextAreaStyles] = useState({ height: "56px" });

  const onSendClicked = () => {
    if (loading) return;
    if (content) {
      setLoading(true);

      const onFinish = () => {
        setLoading(false);
      };

      onSubmit(content, onFinish, onFinish);
      setContent("");
    } else {
      input.checkValidity();
      input.reportValidity();
    }
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSendClicked();
  };

  const onKeyUp = (e) => {
    const elem = e.target;
    const currentHeight = parseInt(elem.style.height);
    console.log(
      "Element height now = ",
      currentHeight,
      " with scroll: ",
      e.target.scrollHeight,
      e.target.clientHeight
    );

    if (e.target.scrollHeight <= 220) {
      setTextAreaStyles({
        ...textareaStyles,
        overflow: "hidden",
        height: e.target.scrollHeight + 2 + "px",
      });
      /*
          if (
            currentHeight !== elem.scrollHeight + 1 &&
            currentHeight + 2 !== elem.scrollHeight
          ) {
            console.log("Appluing height ", e.target.scrollHeight, new Date());
            elem.style.height = "auto";
            elem.style.height = elem.scrollHeight + 2 + "px";
            setTextAreaStyles({
              ...textareaStyles,
              overflow: "hidden",
              height: e.target.scrollHeight + 2 + "px",
            });
          }
          */
    } else {
      setTextAreaStyles({
        ...textareaStyles,
        overflow: "auto",
      });
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSendClicked();
    }
  };

  return (
    <div className={"widget_prompt_textarea "}>
      <form onSubmit={onFormSubmit} ref={(val) => setFormElem(val)}>
        <textarea
          ref={(val) => setInput(val)}
          disabled={loading}
          onChange={(e) => setContent(e.target.value)}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          style={textareaStyles}
          value={content}
          required
          placeholder={label(intl, "prompt_text_placeholder")}
          className="form-control prompt_textarea"
        ></textarea>
      </form>
      <div className="bottom_row">
        <div className="options_panel">
          {/*
          <div className="submit_on_enter">
            <input
              type="checkbox"
              checked={submitOnEnter}
              onChange={() => setSubmitOnEnter(!submitOnEnter)}
            />
            <div onClick={() => setSubmitOnEnter(!submitOnEnter)}>
              <Message id="submit_on_enter" />
            </div>
          </div>
          */}
        </div>
        <div
          className={"send_button " + (loading ? "loading" : "")}
          onClick={onSendClicked}
        >
          {loading ? <Spinner /> : <SendIcon size={18} color="#fff" />}
          <Message id="prompt_control_send" />
        </div>
      </div>
    </div>
  );
};

export default WidgetPromptTextarea;
