import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

import "./PromptContent.scss";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import Branch1Icon from "../../../assets/icons/Branch1Icon";
import CheckIcon from "../../../assets/icons/CheckIcon";
import {
  createPromptVersion,
  updatePromptVersion,
} from "../store/promptActionCreators";
import dayjs from "dayjs";
import Notify from "../../shared/Notify";
import Spinner from "../../../assets/icons/Spinner";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const PromptContent = ({
  prompt,
  version,
  snapshotInProgress,
  setSnapshotInProgress,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const nav = useNavigate();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  const [text, setText] = useState(version.content);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);

  useEffect(() => {
    if (version) {
      setText(version.content);
    }
  }, [version?.uid, setText]);

  const autoSaveHandler = useCallback(
    debounce((savingVersion, newContent) => {
      const onFinished = () => {
        setSaveInProgress(false);
      };
      dispatch(
        updatePromptVersion(
          intl,
          {
            uid: savingVersion.uid,
            name: savingVersion.name,
            approved: savingVersion.approved,
            content: newContent,
          },
          version.templateUid,
          workspaceId,
          onFinished,
          onFinished
        )
      );
    }, 500),
    [dispatch, version?.uid, intl]
  );

  const onTextChange = (e) => {
    const value = e.target.value;
    setText(value);
    setSaveInProgress(true);
    autoSaveHandler(version, value);
  };

  const onNewSnapshot = () => {
    setSnapshotInProgress(true);
    const onSuccess = (resp) => {
      setSnapshotInProgress(false);
      nav(linkBuilder.linkToPromptVersion(prompt.uid, resp.uid));
      Notify.success(label(intl, "notification_new_snapshot_success"));
    };
    dispatch(
      createPromptVersion(
        intl,
        { name: dayjs().format("LLL"), content: text },
        prompt.uid,
        workspaceId,
        onSuccess,
        () => setSnapshotInProgress(false)
      )
    );
  };

  const onApprove = () => {
    if (version.approved) return;

    setApproveInProgress(true);

    const onFinished = () => setApproveInProgress(false);
    dispatch(
      updatePromptVersion(
        intl,
        {
          uid: version.uid,
          name: version.name,
          approved: true,
          content: text,
        },
        version.templateUid,
        workspaceId,
        onFinished,
        onFinished
      )
    );
  };

  return (
    <div className="card">
      <div className="card_header">
        <div className="card_title"></div>
        <div className="card_actions">
          <button
            className="btn no-btn btn-sm"
            disabled={snapshotInProgress}
            onClick={onNewSnapshot}
          >
            {!snapshotInProgress && <Branch1Icon size="18" color="#67748e" />}
            {snapshotInProgress && <Spinner />}
            <Message id="prompt_new_snapshot" />
          </button>
          {!version.approved && (
            <button
              className="btn no-btn btn-sm"
              disabled={approveInProgress}
              onClick={onApprove}
            >
              {!approveInProgress && (
                <CheckIcon size="18" color="#67748e" strokeWidth="2" />
              )}
              {approveInProgress && <Spinner />}
              <Message id="prompt_approve" />
            </button>
          )}
        </div>
      </div>
      <div className="prompt_content_body">
        <textarea
          autoFocus={true}
          readOnly={version.approved}
          rows="9"
          onChange={onTextChange}
          value={text}
          placeholder={label(intl, "prompt_content_placeholder")}
          className="form-control"
        ></textarea>
      </div>
    </div>
  );
};

export default PromptContent;
