import { assignApiKey, responseHandler } from "../../../backend/httpAPI";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";

//const API_ENDPOINT = "https://api.promptleo.com/v1/jobs";
const API_ENDPOINT = "/v1/jobs";

export const sendModelApiRequest = (
  req,
  intl,
  onSuccess = () => {},
  onError = () => {}
) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  };

  assignApiKey(options);
  fetch(API_ENDPOINT, options)
    .then(responseHandler)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      console.error("Failed to send API request", err);
      Notify.error(label(intl, "api_console_server_error"));
      onError();
    });
};
