export const ModelType = {
  TEXT_BASE: "text_base",
  TEXT_INSTRUCT: "text_instruct",
  IMAGE: "image",
  MULTIMODAL: "multimodal",
};

const imageModels = [
  {
    vendor: "black-forest-labs",
    handle: "flux.1-schnell",
    title: "FLUX.1 [schnell]",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/flux.1-schnell.webp",
    previewtext:
      "FLUX.1 [schnell] brings cutting-edge output quality and competitive prompt following, matching the performance of closed source alternatives.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-v3.5-large-turbo",
    title: "Stable Diffusion v3.5 Large Turbo",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-3.5-large-turbo.jpg",
    previewtext:
      "A text-to-image model that generates high-resolution images with fine details. It supports various artistic styles and produces diverse outputs from the same prompt, with a focus on fewer inference steps ",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-v3.5-large",
    title: "Stable Diffusion v3.5 Large",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-3.5-large.webp",
    previewtext:
      "A text-to-image model that generates high-resolution images with fine details. It supports various artistic styles and produces diverse outputs from the same prompt, thanks to Query-Key Normalization.",
    input: {
      prompt: "text",
    },
  },
  /*
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-3-medium",
    title: "Stable Diffusion v3 Medium",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-3-medium.webp",
    previewtext:
      "A text-to-image model that generates high-resolution images with fine details. It supports various artistic styles and produces diverse outputs from the same prompt, thanks to Query-Key Normalization.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-2",
    title: "Stable Diffusion 2",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-2.png",
    previewtext:
      "This is a model that can be used to generate and modify images based on text prompts. It is a Latent Diffusion Model that uses a fixed, pretrained text encoder (OpenCLIP-ViT/H).",
    input: {
      prompt: "text",
    },
  },
  */
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-xl-base-1.0",
    title: "Stable Diffusion XL Base 1.0",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-xl-base-1.0.png",
    previewtext:
      "A text-to-image generative AI model that creates beautiful images ",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-v1.5",
    title: "Stable Diffusion 1.5",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-v1.5.jpg",
    previewtext:
      "A text-to-image generative AI model that creates beautiful images ",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "stability-ai",
    handle: "stable-diffusion-v1.4",
    title: "Stable Diffusion 1.4",
    type: ModelType.IMAGE,
    previewImage: "/app/images/models/stable-diffusion-1.4.png",
    previewtext:
      "A latent text-to-image diffusion model capable of generating photo-realistic images given any text input.",
    input: {
      prompt: "text",
    },
  },
];

//
// meta-llama-3.1-8b-instruct.png
//

const textModels = [
  {
    vendor: "qwen",
    handle: "qwen2.5-14b-instruct",
    title: "Qwen2.5 14B Instruct",
    type: ModelType.TEXT_INSTRUCT,
    previewImage: "/app/images/models/qwen.webp",
    previewtext:
      "improved instruction following, generating long texts (over 8K tokens), understanding structured data (e.g, tables), etc.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "meta",
    handle: "llama-3.1-8b",
    title: "Meta Llama 3.1 8B",
    type: ModelType.TEXT_BASE,
    previewImage: "/app/images/models/meta-llama-3.1-8b.png",
    previewtext:
      "Llama 3.1 is an auto-regressive language model that uses an optimized transformer architecture.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "meta",
    handle: "llama-3.1-8b-instruct",
    title: "Meta Llama 3.1 8B Instruct",
    type: ModelType.TEXT_INSTRUCT,
    previewImage: "/app/images/models/meta-llama-3.1-8b-instruct.png",
    previewtext:
      "Llama 3.1 is an auto-regressive language model that uses an optimized transformer architecture.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "meta",
    handle: "llama-3.2-3b",
    title: "Meta Llama 3.2 3B",
    type: ModelType.TEXT_BASE,
    previewImage: "/app/images/models/meta-llama-3.2-3b.png",
    previewtext:
      "Llama 3.1 is an auto-regressive language model that uses an optimized transformer architecture.",
    input: {
      prompt: "text",
    },
  },
  {
    vendor: "meta",
    handle: "llama-3.2-3b-instruct",
    title: "Meta Llama 3.2 3B Instruct",
    type: ModelType.TEXT_INSTRUCT,
    previewImage: "/app/images/models/meta-llama-3.2-3b-instruct.png",
    previewtext:
      "Llama 3.1 is an auto-regressive language model that uses an optimized transformer architecture.",
    input: {
      prompt: "text",
    },
  },
];

const modelRegistry = {
  getImageModels() {
    return imageModels;
  },
  getTextModels() {
    return textModels;
  },
  find(handle) {
    const imageModel = imageModels.find((item) => item.handle === handle);
    if (imageModel) {
      return imageModel;
    }
    const textModel = textModels.find((item) => item.handle === handle);
    return textModel;
  },
};

export default modelRegistry;
