import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { authSelectors } from "../../auth/store/authReducer";
import UserCircleIcon from "../../../assets/icons/UserCircleIcon";
import SettingsIcon from "../../../assets/icons/SettignsIcon";
import Message from "../../../i18n/Message";
import {
  linkToAccountSettings,
  linkToHubModels,
  linkToHubStart,
  linkToOther,
  linkToTeam,
} from "../../../routes";
import { isTeamAdmin } from "../../../backend/memberRole";
import UsersIcon from "../../../assets/icons/UsersIcon";
import { logout } from "../../auth/store/actionCreators";
import ArrowOutIcon from "../../../assets/icons/ArrowOutIcon";
import GridIcon from "../../../assets/icons/GridIcon";
import Home2Icon from "../../../assets/icons/Home2Icon";

const HubLayout = ({ children, contentPanelClassName = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const user = useSelector(authSelectors.getUser);

  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const headerMenuRef = useRef(null);
  const submenuRef = useRef(null);
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(
    window.innerWidth < 1505
  );
  const [wasCollapsed, setWasCollapsed] = useState(false);

  useEffect(() => {
    if (showHeaderMenu) {
      const onClickAway = (e) => {
        if (
          submenuRef.current &&
          e.target !== submenuRef.current &&
          !submenuRef.current.contains(e.target)
        ) {
          setShowHeaderMenu(false);
        }
      };
      document.addEventListener("click", onClickAway);
      return () => document.removeEventListener("click", onClickAway);
    }
  }, [showHeaderMenu, submenuRef]);

  const onLeftPanelMouseOver = () => {
    if (leftPanelCollapsed) {
      setLeftPanelCollapsed(false);
      setWasCollapsed(true);
    }
  };

  const onLeftPanelMouseLeave = () => {
    if (wasCollapsed) {
      setWasCollapsed(false);
      setLeftPanelCollapsed(true);
    }
  };

  const LogoWrapper = ({ children }) => <a href={"/app"}>{children}</a>;

  return (
    <div className="workspace_layout">
      <div
        className={
          "main_layout " + (leftPanelCollapsed ? "left_menu_collapsed" : "")
        }
      >
        <header>
          <div className="logo">
            <LogoWrapper>
              <img src="/images/promptleo-logo-grey.svg" width="190" />
            </LogoWrapper>
          </div>
          {
            <>
              <div className="header_menu" ref={headerMenuRef}>
                <div className="item">
                  <div
                    className="entry"
                    onClick={() => navigate(linkToHubStart())}
                  >
                    <Message id="hub_menu_api" />
                  </div>
                </div>
                <div className="item">
                  <div
                    className="entry"
                    onClick={() => navigate(linkToOther())}
                  >
                    <Message id="hub_menu_workspace" />
                  </div>
                </div>
                <div className="item">
                  <div
                    className="entry"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowHeaderMenu(!showHeaderMenu);
                    }}
                  >
                    <UserCircleIcon color="#344767" strokeWidth="1.5" />
                    <span className="hidden-xs">{user ? user.name : ""}</span>
                  </div>
                  <div className="submenu_container">
                    {showHeaderMenu && (
                      <div className="submenu" ref={submenuRef}>
                        <div onClick={() => navigate(linkToAccountSettings())}>
                          <SettingsIcon color="#333" size="20" />{" "}
                          <Message id="account" />
                        </div>

                        {isTeamAdmin(user) && (
                          <>
                            <div onClick={() => navigate(linkToTeam())}>
                              <UsersIcon color="#333" size="20" />{" "}
                              <Message id="users" />
                            </div>
                          </>
                        )}

                        <div onClick={() => dispatch(logout())}>
                          <ArrowOutIcon color="#333" size="20" />{" "}
                          <Message id="menu_logout" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="break"></div>
            </>
          }
        </header>
        <div className="left_panel_mobile visible-xs"></div>
        <div
          className={
            "left_panel hidden-xs " + (leftPanelCollapsed ? "collapsed" : "")
          }
          onMouseOver={onLeftPanelMouseOver}
          onMouseLeave={onLeftPanelMouseLeave}
        >
          <hr className="horizontal dark"></hr>
          <div className="sidebar-items">
            <NavLink
              to={linkToHubStart()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <Home2Icon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="hub_menu_dashboard" />
              </span>
            </NavLink>
            <NavLink
              to={linkToHubModels()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <GridIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="hub_menu_models" />
              </span>
            </NavLink>
            {/* 
            <NavLink
              to={linkToAccountSettings()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <SettingsIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="settings" />
              </span>
            </NavLink>
            */}
          </div>
        </div>
        <div className={`content_panel ${contentPanelClassName}`}>
          {children}
        </div>
      </div>
      <ToastContainer
        closeButton={true}
        className="notification-box"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
    </div>
  );
};

export default HubLayout;
