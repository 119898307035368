import { useNavigate } from "react-router-dom";
import Message from "../../../i18n/Message";
import HubLayout from "../layout/HubLayout";

import "./HubModelsPage.scss";
import modelRegistry from "./modelRegistry";
import { linkToHubModelDetails } from "../../../routes";

const HubModelsPage = () => {
  const nav = useNavigate();

  return (
    <HubLayout contentPanelClassName="hub_models_content">
      <h1>
        <Message id="hub_models_title" />
      </h1>
      <div className="section_caption">
        <Message id="hub_image_models" />
      </div>
      <div className="model_list">
        {modelRegistry.getImageModels().map((model) => {
          return (
            <div
              className="model_item card"
              onClick={() => nav(linkToHubModelDetails(model.handle))}
            >
              <div className="model_image">
                <img src={model.previewImage} />
              </div>
              <div className="model_title">{model.title}</div>
              <div className="model_description">{model.previewtext}</div>
            </div>
          );
        })}
      </div>
      <div className="section_caption text_models_caption">
        <Message id="hub_text_models" />
      </div>
      <div className="model_list">
        {modelRegistry.getTextModels().map((model) => {
          return (
            <div
              className="model_item card"
              onClick={() => nav(linkToHubModelDetails(model.handle))}
            >
              <div className="model_image">
                <img src={model.previewImage} />
              </div>
              <div className="model_title">{model.title}</div>
              <div className="model_description">{model.previewtext}</div>
            </div>
          );
        })}
      </div>
    </HubLayout>
  );
};

export default HubModelsPage;
