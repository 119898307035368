import { Bot, UserIcon } from "lucide-react";
import "./ChatMessageFeed.scss";
import LogoOpenAI from "../../../../assets/icons/LogoOpenAI";
import MessageTextContainer from "../../../chats/details/MessageTextContainer";
import FileTextIcon from "../../../../assets/icons/FileTextIcon";
import ArrowUpIcon from "../../../../assets/icons/ArrowUpIcon";
import Message from "../../../../i18n/Message";
import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon";
import Spinner from "../../../../assets/icons/Spinner";

const ChatMessageFeed = ({ messages }) => {
  return (
    <div className="chat_messages_feed">
      <div className="chat_messages_feed_wrapper">
        {!messages && <Spinner />}
        {messages &&
          messages.map((message) => {
            return (
              <div
                key={message.uid}
                className={
                  "chat_message_container " +
                  (message.role === "user" ? "user" : "") +
                  (message.role === "assistant" ? "assistant" : "")
                }
              >
                <div className="avatar">
                  {message.role === "user" && (
                    <div className="user_avatar">
                      <UserIcon color="#777" />
                    </div>
                  )}
                  {message.role === "assistant" && (
                    <div className="openai_avatar">
                      <Bot color="#fff" />
                    </div>
                  )}
                </div>
                <div className="message_item_content">
                  <MessageTextContainer>{message.content}</MessageTextContainer>
                </div>
                <div className="message_metadata">
                  {!!message.filename && (
                    <>
                      <div className="message_metadata_item">
                        <FileTextIcon color="rgb(109, 123, 147)" size="16" />
                        {message.filename}
                      </div>
                    </>
                  )}
                  {!!message.inputTokenCount && (
                    <div className="message_metadata_item">
                      <ArrowUpIcon color="rgb(109, 123, 147)" size="16" />{" "}
                      {message.inputTokenCount} <Message id="message_tokens" />
                    </div>
                  )}
                  {!!message.outputTokenCount && (
                    <div className="message_metadata_item">
                      <ArrowDownIcon color="rgb(109, 123, 147)" size="16" />{" "}
                      {message.outputTokenCount} <Message id="message_tokens" />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ChatMessageFeed;
