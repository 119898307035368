import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useIntl } from "react-intl";
import dayjs from "dayjs";

import Spinner from "../../assets/icons/Spinner";
import { login } from "../auth/store/actionCreators";
import { authSelectors } from "../auth/store/authReducer";
import LineChartIcon from "../../assets/icons/LineChartIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import capitalize from "lodash/capitalize";
import CreditCardIcon from "../../assets/icons/CreditCardIcon";
import { accountSelectors } from "./store/accountReducer";
import { loadAccountOrders, upgradeSubscription } from "./store/actionCreators";

import Message from "../../i18n/Message";
import SubscriptionUsage from "./SubscriptionUsage";
import { subscriptionPlan } from "../shared/pricingLimits";
import label from "../../i18n/label";
import CheckIcon from "../../assets/icons/CheckIcon";
import ConfirmUpgradeDialog from "./ConfirmUpgradeDialog";
import GlobalLayout from "../shared/layout/GlobalLayout";

import "./SubscriptionPage.scss";

const upgradeOptions = [
  subscriptionPlan.FREE,
  subscriptionPlan.LITE,
  subscriptionPlan.PRO,
];

const SubscriptionPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.getUser);
  const userMins = useSelector(authSelectors.getMinutesSinceLoaded);
  const orders = useSelector(accountSelectors.getOrders);
  const [pendingUpgrade, setPendingUpgrade] = useState(null);
  const [inProgressPlan, setInProgressPlan] = useState(null);

  useEffect(() => {
    if (null === user || (userMins && userMins > 1)) {
      dispatch(login());
    }
  }, [dispatch, user, userMins]);

  useEffect(() => {
    if (!orders) {
      dispatch(loadAccountOrders(intl));
    }
  }, [dispatch, orders, intl]);

  const onUpgradeConfirmed = () => {
    setInProgressPlan(pendingUpgrade);
    setPendingUpgrade(null);
    if (sub.plan === "free") {
      window.showPaymentWindow(
        user,
        "promptleo-" + pendingUpgrade + "-monthly"
      );
    } else {
      dispatch(
        upgradeSubscription(
          intl,
          pendingUpgrade,
          () => {
            dispatch(login());
            dispatch(loadAccountOrders(intl));
            setInProgressPlan(null);
          },
          () => {
            setInProgressPlan(null);
          }
        )
      );
    }
  };

  const sub = user?.subscription;

  return (
    <GlobalLayout contentPanelClassName="subscription_content_panel">
      <h3>
        <Message id="subscription" />
      </h3>
      <div className="actions_panel">
        {sub.plan !== "free" && (sub.trialInProgress || sub.daysLeft === 0) ? (
          <button
            className="btn btn-outline-dark "
            onClick={() =>
              window.showPaymentWindow(user, user.subscription.planCode)
            }
          >
            <Message id="add_card" />
          </button>
        ) : (
          <></>
        )}
      </div>
      {sub ? (
        <>
          <div className="summary">
            <div>
              <LineChartIcon /> <Message id="plan" />:{" "}
              <div className="value">{capitalize(sub.plan)}</div>
            </div>

            {sub.plan !== "free" && (
              <>
                <div>
                  <CreditCardIcon /> <Message id="billing_cycle" />:{" "}
                  <div className="value">
                    <Message id={sub.period} />
                  </div>
                </div>
                {sub.plan !== subscriptionPlan.DEALIFY &&
                  sub.period !== "lifetime" && (
                    <div>
                      <CalendarIcon />{" "}
                      {sub.trialInProgress || sub.daysLeft === 0 ? (
                        <Message
                          id="status_trial"
                          values={{
                            date: dayjs(sub.trialDateEnded).format("DD MMM"),
                          }}
                        />
                      ) : (
                        <Message
                          id="status_paid"
                          values={{
                            date: dayjs(sub.paidDateEnded).format("DD MMM"),
                          }}
                        />
                      )}
                    </div>
                  )}
              </>
            )}
            {!sub.trialInProgress && (
              <div>
                <div className="upgrade_section">
                  <div className="upgrade_section__title">
                    <Message id="change_plan" />
                  </div>
                  <div className="upgrade_options_container">
                    {upgradeOptions.map((option) => {
                      return (
                        <div
                          key={option}
                          className={
                            "upgrade_option " +
                            (option === sub.plan ? "current" : "")
                          }
                          onClick={() =>
                            option !== sub.plan && setPendingUpgrade(option)
                          }
                        >
                          <div className="option_title">
                            {option === sub.plan && (
                              <CheckIcon color="#383838" />
                            )}
                            {option === inProgressPlan && <Spinner />}
                            {label(intl, "plan_" + option)}
                          </div>
                          <div className="option_action"></div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="compare_plans">
                    <a href="/p/pricing" target="_blank">
                      <Message id="change_plan_compare" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          <SubscriptionUsage />
        </>
      ) : (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {orders?.length > 0 && (
        <div className="subscriptio_section orders_panel ">
          <h3>
            <Message id="orders" />
          </h3>
          {orders.map((order) => {
            return (
              <div className="order" key={order.uid}>
                <div>
                  <CalendarIcon /> {dayjs(order.fsDate).format("DD MMM")}
                </div>
                <div>{order.totalDisplay}</div>
                <div>
                  {order.plan} / <Message id={order.period} />
                </div>
                <div>
                  <a
                    href={order.fsInvoiceUrl + "/pdf"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Message id="invoice" />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {pendingUpgrade && (
        <ConfirmUpgradeDialog
          planKey={pendingUpgrade}
          onConfirm={onUpgradeConfirmed}
          onCancel={() => setPendingUpgrade(null)}
        />
      )}
    </GlobalLayout>
  );
};
export default SubscriptionPage;
