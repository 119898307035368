import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Select from "react-select";

import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";

import ModalDialog from "../../shared/ModalDialog";

import "./CreateChatbotDialog.scss";
import { linkToChatbot } from "../../../routes";
import { createChatbot, loadChatbotList } from "../store/chatbotActionCreators";
import modelDescriptors, {
  modelVendor,
} from "../../../model/model-providers/modelDescriptors";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import LogoAnthropic from "../../../assets/icons/LogoAnthropic";
import LogoAmazon from "../../../assets/icons/LogoAmazon";

import "./CreateFirstStep.scss";

const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#f7f7ff", // for option hover bg-color
    primary: "#644EF3", // for selected option bg-color
    neutral10: "#644EF3", // for tags bg-color
    neutral20: "#e1e1ed", // for input border-color
    neutral30: "#e1e1ed", // for input hover border-color
  },
});

const modelIdOptions = modelDescriptors.map((item) => {
  return {
    value: item.id,
    label: item.name,
  };
});

const CreateFirstStep = ({ onClose, onNext }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  //const [systemPrompt, setSystemPrompt] = useState("");
  const [companyName, setCompanyName] = useState("");
  //const [modelId, setModelId] = useState(modelIdOptions[0].value);
  const [nameInput, setNameInput] = useState(null);
  const [companyNameInput, setCompanyNameInput] = useState(null);
  const [formElem, setFormElem] = useState(null);

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  const onCreate = () => {
    const validName = nameInput.checkValidity();
    const validCompanyName = companyNameInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!validName || !validCompanyName) return;
    setLoading(true);
    const onSuccess = (newChatbot) => {
      setLoading(false);
      dispatch(loadChatbotList());
      onNext(newChatbot);
    };
    dispatch(
      createChatbot(
        { name, modelId: "system:openai-gpt4o", companyName },
        intl,
        onSuccess,
        () => setLoading(false)
      )
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <>
      <div className="modal_title">
        <Message id="create_chatbot_dialog_title" />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="campaign_form">
          <div className="dialog_body">
            <div className="select_row">
              <div className="label">
                <Message id="create_chatbot_dialog_name" />
              </div>
              <input
                className="form-control"
                type="text"
                ref={(val) => setNameInput(val)}
                required
                maxLength="120"
                placeholder={label(
                  intl,
                  "create_chatbot_dialog_name_placeholder"
                )}
                onChange={(e) => setName(e.target.value)}
                value={name}
                readOnly={loading}
              />
            </div>
            <div className="select_row">
              <div className="label">
                <Message id="create_chatbot_dialog_company_name" />
              </div>
              <div className="help">
                <Message id="create_chatbot_dialog_company_name_help" />
              </div>
              <input
                className="form-control"
                type="text"
                ref={(val) => setCompanyNameInput(val)}
                required
                maxLength="200"
                placeholder={label(
                  intl,
                  "create_chatbot_dialog_company_name_placeholder"
                )}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
                readOnly={loading}
              />
            </div>
            {/* 
              <div className="select_row">
                <div className="label">
                  <Message id="create_chatbot_dialog_system_prompt" />
                </div>
                <textarea
                  rows="3"
                  onChange={(e) => setSystemPrompt(e.target.value)}
                  value={systemPrompt}
                  placeholder={label(
                    intl,
                    "create_chatbot_dialog_system_prompt_placeholder"
                  )}
                  className="form-control"
                ></textarea>
              </div>
            */}
            {/*
            <div className="model_block">
              <div className="label">
                <Message id="create_chatbot_dialog_model" />
              </div>
              <div className="model_selector_container">
                <div className="model_selector">
                  {modelDescriptors.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          "model_item " +
                          (modelId === item.id ? " selected" : "")
                        }
                        onClick={() => setModelId(item.id)}
                      >
                        <div className="logo">
                          {item.vendor === modelVendor.OPENAI && (
                            <LogoOpenAI size={32} />
                          )}
                          {item.vendor === modelVendor.ANTHROPIC && (
                            <LogoAnthropic size={32} />
                          )}
                          {item.vendor === modelVendor.AMAZON && (
                            <LogoAmazon size={32} />
                          )}
                        </div>
                        <div className="name_column">
                          <div className="name">{item.name}</div>
                          <div className="context">{item.contextWindow}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>*/}
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id="create_chatbot_button" />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateFirstStep;
