import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import TeamActions from "./teamActionTypes";

export const loadTeam = (intl) => (dispatch) => {
  backend
    .getTeam()
    .then((data) => {
      //console.log("team response", data);
      dispatch({
        type: TeamActions.TEAM_LOADED,
        team: data,
      });
    })
    .catch((err) => {
      console.error("Failed to change a password", err);
      Notify.error(label(intl, "error_unexpected"));
    });
};

export const sendInvite =
  (intl, params, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .sendInvite(params)
      .then((data) => {
        console.log("send invite response", data);
        dispatch(loadTeam(intl));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to send an invite", err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };

export const cancelInvitation =
  (intl, uid, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .cancelInvite(uid)
      .then((data) => {
        console.log("cancel invite response", data);
        dispatch(loadTeam(intl));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to cancel invite", err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };

export const deleteMember =
  (intl, uid, onSuccess = () => {}, onError = () => {}) =>
  (dispatch) => {
    backend
      .deleteMember(uid)
      .then((data) => {
        console.log("delete member response", data);
        dispatch(loadTeam(intl));
        onSuccess();
      })
      .catch((err) => {
        console.error("Failed to delete a member", uid, err);
        Notify.error(label(intl, "error_unexpected"));
        onError();
      });
  };
