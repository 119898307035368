export const formatDurationMs = (value) => {
  if (!value) return "";

  if (value < 1000) {
    return parseInt(value) + "ms";
  } else {
    return parseInt(value / 1000) + "s";
  }
};

export const cropText = (text, maxLength = 120) => {
  if (!text) return "";
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + "...";
  } else {
    return text;
  }
};
