import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import openAIService from "../../../model/model-providers/openai/openAIService";
import Notify from "../../shared/Notify";
import {
  getDescriptorById,
  modelVendor,
} from "../../../model/model-providers/modelDescriptors";
import ChatActions from "./chatActions";
import { scrollToChatBottom } from "../details/chatScrollUtils";

export const loadChatList = (
  intl,
  workspaceId,
  folderId,
  keyword,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getChats(workspaceId, folderId, keyword)
      .then((data) => {
        console.log("loaded chat list", data);
        dispatch({
          type: ChatActions.LIST_LOADED,
          list: data,
          folderId,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chat list", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadChatFolder = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getChat(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: ChatActions.FOLDER_LOADED,
          folder: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chat", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadChat = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getChat(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: ChatActions.LOADED,
          chat: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chat", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const postChatMessage = (
  intl,
  workspaceId,
  chat,
  message,
  messages,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    dispatch({
      type: ChatActions.POSTED_MESSAGE,
      message,
    });
    const model = getDescriptorById(chat.modelId);
    console.log("Found model by id ", model);
    if (model.vendor === modelVendor.OPENAI) {
      console.log("Handling openai vendor", messages);
      let existingMessages = [];
      if (chat.systemPrompt) {
        existingMessages.push({
          role: "system",
          content: chat.systemPrompt,
        });
      }
      if (messages) {
        existingMessages = [...existingMessages, ...messages];
      }

      const allMessages = existingMessages.map((item) => ({
        role: item.role,
        content: item.content,
      }));
      allMessages.push({ role: "user", content: message });
      console.log("Prepared messages ", allMessages);
      let tempBuffer = "";
      const onPartAvailable = (content) => {
        tempBuffer += content;
        dispatch({
          type: ChatActions.GOT_MESSAGE_PART,
          content,
        });
        setTimeout(scrollToChatBottom, 250);
      };

      const onFinish = () => {
        //console.log("WHOLE MESSAGE: ", tempBuffer);
        backend
          .saveReply(workspaceId, chat.uid, {
            userMessage: message,
            assistantReply: tempBuffer,
          })
          .then((response) => {
            console.log("Got backend response", response);
            dispatch({
              type: ChatActions.FINISHED_MESSAGE_PARTS,
              response,
            });
            onSuccess && onSuccess(response);
          })
          .catch((err) => {
            onError && onError(err);
            console.error("Failed to post chat message", err);
            Notify.error(label(intl, "error_unexpected"));
          });
      };
      const onApiError = (err) => {
        dispatch({
          type: ChatActions.MESSAGE_PART_ERROR,
          error: err,
        });
        onError && onError(err);
      };
      openAIService.postMessage(
        chat.modelId,
        allMessages,
        onPartAvailable,
        onFinish,
        onApiError
      );
    } else {
      console.log("Handling other vendor");
      backend
        .postChatMessage(workspaceId, chat.uid, message)
        .then((data) => {
          console.log("post chat message response", data);
          dispatch({
            type: ChatActions.GOT_MESSAGE_RELY,
            reply: data,
          });
          onSuccess && onSuccess(data);
        })
        .catch((err) => {
          onError && onError(err);
          console.error("Failed to post chat message", err);
          Notify.error(label(intl, "error_unexpected"));
        });
    }
  };
};

export const deleteChat = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deleteChat(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: ChatActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteChatMessage = (
  intl,
  workspaceId,
  chatId,
  messageId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .deleteChatMessage(workspaceId, chatId, messageId)
      .then((data) => {
        dispatch({
          type: ChatActions.MESSAGE_DELETED,
          chatId,
          messageId,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateChatMessage = (
  intl,
  workspaceId,
  chatId,
  messageId,
  content,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updateChatMessage(workspaceId, chatId, messageId, content)
      .then((data) => {
        dispatch({
          type: ChatActions.MESSAGE_UPDATED,
          chatId,
          messageId,
          content,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
