import Message from "../../../i18n/Message";
import label from "../../../i18n/label";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Notify from "../../shared/Notify";
import backend from "../../../backend/backend";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../auth/store/actionCreators";
import authStore from "../../auth/AuthStore";

import "./InvitationPage.scss";
import { useParams } from "react-router";
import { accountSelectors } from "../store/accountReducer";
import { loadInvitation } from "../store/actionCreators";
import Spinner from "../../../assets/icons/Spinner";

const InvitationPage = ({}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { invitationId } = useParams();
  const [passwordForm, setPasswordForm] = useState(null);

  const [name, setName] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [password, setPassword] = useState("");

  const invitation = useSelector(accountSelectors.getInvitation);

  useEffect(() => {
    console.log("InvitationPage useEffect", invitationId);

    dispatch(loadInvitation(intl, invitationId));
  }, [dispatch, invitationId]);

  console.log("Loaded invitation ", invitation);

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  /*
  const onApiKeySubmit = () => {
    onSubmit();
  };
*/
  const onButtonClicked = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (name && password && passwordForm.checkValidity()) {
      backend
        .registerByInvitation(invitationId, { password, name })
        .then((resp) => {
          authStore.setToken(resp.token);
          dispatch(login());
          window.location = "/app/";
        })
        .catch((error) => {
          console.error("Error in login", error);
          Notify.error(label(intl, "login_error"));
        });
    } else {
      passwordForm.reportValidity();
    }
  };

  return (
    <div className="register_page">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      <div className="container">
        <div className="header">
          <h5>
            <Message id="invitation_page_title" />
          </h5>
        </div>
        {!invitation && (
          <div className="spinner_container">
            <Spinner />
          </div>
        )}
        {invitation && invitation.activated && (
          <div className="invitation_error">
            <Message id="invitation_page_error" />
          </div>
        )}
        {invitation && !invitation.activated && (
          <div className="form">
            <form
              onSubmit={onPasswordSubmit}
              ref={(elem) => setPasswordForm(elem)}
            >
              <div className="field">
                <input
                  type="text"
                  autoComplete="name"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={label(intl, "register_name")}
                />
              </div>

              <div className="field">
                <input
                  type="password"
                  autoComplete="current-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={label(intl, "login_password")}
                />
              </div>
              <div className="field">
                <input
                  type="email"
                  spellCheck="false"
                  readOnly={true}
                  disabled={true}
                  value={invitation?.email}
                  placeholder={label(intl, "login_email")}
                  style={{ backgroundColor: "#f8f8f8" }}
                />
              </div>
              <div class="terms">
                <input
                  type="checkbox"
                  value={agreed}
                  id="flexCheckDefault"
                  checked={agreed}
                  required
                  onChange={() => setAgreed(!agreed)}
                />
                <label for="flexCheckDefault">
                  <Message id="register_agree" />{" "}
                  <a href="/p/terms" target="_blank" rel="noopener">
                    <Message id="register_terms" />
                  </a>{" "}
                  <Message id="register_and" />{" "}
                  <a href="/p/privacy-policy" target="_blank" rel="noopener">
                    <Message id="register_privacy" />
                  </a>
                </label>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="submit"
                  name="submit"
                  style={{ position: "absolute", left: "-10000px" }}
                />
              </div>
            </form>

            <div className="field">
              <button type="button" className="btn" onClick={onButtonClicked}>
                <Message id="register_button" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvitationPage;
