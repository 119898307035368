import Notify from "../../../shared/Notify";
import "./ApiConsole.scss";
import CodeMirror from "@uiw/react-codemirror";
import { materialDark } from "@uiw/codemirror-theme-material";

import { EditorView } from "@codemirror/view";
import { json } from "@codemirror/lang-json";
import { POST } from "../../../../backend/httpAPI";
import Spinner from "../../../../assets/icons/Spinner";
import Message from "../../../../i18n/Message";
import { useIntl } from "react-intl";
import label from "../../../../i18n/label";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FileTextIcon from "../../../../assets/icons/FileTextIcon";
import ChevronRightIcon from "../../../../assets/icons/ChevronRightIcon";
import ArrowInIcon from "../../../../assets/icons/ArrowInIcon";
import { loadPromptVersion } from "../../../prompt/store/promptActionCreators";
import { promptSelectors } from "../../../prompt/store/promptReducer";
import EndpointActions from "../../store/EndpointActions";

const sendRequest = (
  req,
  workspaceId,
  endpoint,
  intl,
  onSuccess = () => {},
  onError = () => {}
) => {
  POST("/customer/" + workspaceId + "/endpoints/" + endpoint.name, req)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      console.error("Failed to send API request", err);
      Notify.error(label(intl, "api_console_server_error"));
      onError();
    });
};

export const buildShortAPIRequest = (promptText) => {
  const matches = promptText.matchAll(/{([^}]+)}/g);
  const params = {};
  for (const match of matches) {
    params[match[1]] = "";
  }
  const req = {};
  req.params = params;
  //req.metadata = { field: "value" };
  return req;
};

const editorHeight = "260px";

const ApiConsole = ({ endpoint }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const [requestJson, setRequestJson] = useState("");
  const [responseJson, setResponseJson] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [response, setResponse] = useState(null);
  const version = useSelector(promptSelectors.getCurrentVersion);

  useEffect(() => {
    if (!version || endpoint?.versionUid !== version.uid) {
      dispatch(
        loadPromptVersion(
          intl,
          workspaceId,
          endpoint.templateUid,
          endpoint.versionUid
        )
      );

      /*
      setInProgress(true);
      sendRequest(req, design, intl, (res) => {
        setResponseJson(JSON.stringify(res, null, 2));
        setResponse(res);
        setInProgress(false);
      }, () => setInProgress(false));      
      */
    }
  }, [version, endpoint, workspaceId]);

  useEffect(() => {
    if (version && version.uid === endpoint.versionUid) {
      const req = buildShortAPIRequest(version.content);
      setRequestJson(JSON.stringify(req, null, 2));
    }
  }, [version, endpoint]);

  if (!endpoint || !version) {
    return <Spinner />;
  }

  const onSendClicked = () => {
    let req = {};
    try {
      req = JSON.parse(requestJson);
    } catch (e) {
      Notify.error(label(intl, "api_console_json_error"));
      return;
    }
    setInProgress(true);
    setResponseJson("");
    sendRequest(
      req,
      workspaceId,
      endpoint,
      intl,
      (res) => {
        setResponseJson(JSON.stringify(res, null, 2));
        setResponse(res);
        setInProgress(false);
        dispatch({ type: EndpointActions.REQUESTS_CHANGED });
      },
      () => setInProgress(false)
    );
  };

  return (
    <div className="api_console_body">
      <div className="url_container">
        <span className="http-method">POST</span>
        <span className="rest-endpoint">
          https://api.promptleo.com/{workspaceId}/endpoints/{endpoint.name}
        </span>
      </div>
      <div className="request_container">
        <div className="request">
          <div className="label">
            <Message id="api_console_label_request" />
          </div>
          <CodeMirror
            value={requestJson}
            theme={materialDark}
            height={editorHeight}
            extensions={[json(), EditorView.lineWrapping]}
            onChange={(value) => setRequestJson(value)}
          />
          {/* 
          <CodeMirror
            value={requestJson}
            options={requestEditorOptions}
            onBeforeChange={(editor, data, value) => {
              setRequestJson(value);
            }}
            onChange={(editor, data, value) => {}}
          />
          */}
        </div>
        <div className="between">
          <ChevronRightIcon />
        </div>
        <div className="response">
          <div className="label">
            <Message id="api_console_label_response" />
          </div>
          <CodeMirror
            value={responseJson}
            theme={materialDark}
            height={editorHeight}
            extensions={[json(), EditorView.lineWrapping]}
          />
          {/* 
          <CodeMirror
            value={responseJson}
            options={requestEditorOptions}
            onBeforeChange={(editor, data, value) => {}}
            onChange={(editor, data, value) => {}}
          />
          */}
          {inProgress && (
            <div className="progress">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      <div className="request_actions">
        <div className="buttons">
          <div>
            <button
              className="btn bg-gradient-dark"
              disabled={inProgress}
              onClick={onSendClicked}
            >
              {inProgress ? <Spinner /> : <ArrowInIcon color="#fff" />}
              <Message id="api_console_send_request" />
            </button>
          </div>

          <div>
            <a
              href="https://promptleo.com/api-docs/"
              target="_blank"
              rel="noreferrer"
            >
              <FileTextIcon color="#333" />{" "}
              <Message id="api_console_doc_link" />
            </a>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ApiConsole;
