import { combineReducers } from "redux";
import authReducer from "../components/auth/store/authReducer";
import accountReducer from "../components/account/store/accountReducer";

import workspaceReducer from "../components/shared/layout/store/workspaceReducer";
import teamReducer from "../components/team/store/teamReducer";
import workspaceUsersReducer from "../components/workspace/users/store/workspaceUsersReducer";
import promptReducer from "../components/prompt/store/promptReducer";
import apiKeyReducer from "../components/api-keys/store/apiKeyReducer";
import formReducer from "../components/form/store/formReducer";
import endpointReducer from "../components/endpoint/store/endpointReducer";
import flowReducer from "../components/flows/store/flowReducer";
import sharedFormReducer from "../components/form/sharing/store/sharedFormReducer";
import chatReducer from "../components/chats/store/chatReducer";
import hubReducer from "../components/hub/store/hubReducer";
import chatbotReducer from "../components/chatbot/store/chatbotReducer";
import chatbotWidgetReducer from "../widgets/chatbot/content/store/chatbotWidgetReducer";
//import interviewReducer from "../components/interview/store/interviewReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  team: teamReducer,
  workspace: workspaceReducer,
  workspaceUsers: workspaceUsersReducer,

  prompts: promptReducer,
  apiKeys: apiKeyReducer,
  forms: formReducer,
  sharedForm: sharedFormReducer,
  endpoints: endpointReducer,
  flows: flowReducer,
  chats: chatReducer,

  hub: hubReducer,
  chatbot: chatbotReducer,
  chatbotWidget: chatbotWidgetReducer,
});

export default rootReducer;
