import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./assets/styles/app.scss";
import ProtectedPage from "./components/auth/ProtectedPage";
import { selectLanguage } from "./i18n/selectLanguage";
import { ToastContainer } from "react-toastify";
import store from "./store/store";
import { Provider } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

import RegisterPage from "./components/account/register/RegisterPage";
import ApiKeyListPage from "./components/api-keys/list/ApiKeyListPage";
import FormListPage from "./components/form/list/FormListPage";
import EndpointListPage from "./components/endpoint/list/EndpointListPage";
import EndpointDetailsPage from "./components/endpoint/details/EndpointDetailsPage";
import FormDetailsPage from "./components/form/details/FormDetailsPage";
import FlowDetailsPage from "./components/flows/details/FlowDetailsPage";
import SharedFormPage from "./components/form/sharing/SharedFormPage";
import WorkspaceSettingsPage from "./components/workspace/WorkspaceSettingsPage";
import SettingsPage from "./components/account/SettingsPage";
import AppsumoRegisterPage from "./components/account/register/appsumo/AppsumoRegisterPage";
import ForgotPasswordPage from "./components/account/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "./components/account/forgot-password/ResetPasswordPage";
import EmailConfirmedPage from "./components/account/register/email-confirmed/EmailConfirmedPage";
import FlowWaitlistPage from "./components/flows/list/FlowWaitlistPage";
import SharedFormResultPage from "./components/form/sharing/SharedFormResultPage";
import PitchGroundRegisterPage from "./components/account/register/pitchground/PitchGroundRegisterPage";
import DealifyRegisterPage from "./components/account/register/dealify/DealifyRegisterPage";
import TeamPage from "./components/team/TeamPage";
import InvitationPage from "./components/account/invitation/InvitationPage";
import ChatListPageWrapper from "./components/chats/list/ChatListPageWrapper";
import ChatPageWrapper from "./components/chats/details/ChatPageWrapper";
import PromptListPageWrapper from "./components/prompt/list/PromptListPageWrapper";
import PromptDetailsPageWrapper from "./components/prompt/details/PromptDetailsPageWrapper";
import SubscriptionPage from "./components/account/SubscriptionPage";
import HubWelcomePage from "./components/hub/welcome/HubWelcomePage";
import HubModelsPage from "./components/hub/models/HubModelsPage";
import HubModelDetails from "./components/hub/model_details/HubModelDetails";
import HubJobDetailsPage from "./components/hub/job_details/HubJobDetailsPage";
import ChatbotListPage from "./components/chatbot/chatbots/ChatbotListPage";
import ChatbotDetailsPage from "./components/chatbot/chatbot-details/ChatbotDetailsPage";
import ChatbotWidgetPage from "./widgets/chatbot/content/ChatbotWidgetPage";

const App = () => {
  const { lang, resources } = selectLanguage();

  return (
    <IntlProvider locale={lang} messages={resources} defaultLocale="en">
      <Provider store={store}>
        <Router basename="/app">
          <Routes>
            <Route path="/" element={<ProtectedPage />}>
              <Route path="/" element={<ChatbotListPage />} />
              <Route path="/ai/chatbots" element={<ChatbotListPage />} />
              <Route
                path="/ai/chatbots/:chatbotId/:tabId"
                element={<ChatbotDetailsPage />}
              />
              <Route path="/hub/start" element={<HubWelcomePage />} />
              <Route path="/hub/models" element={<HubModelsPage />} />
              <Route path="/hub/models/:handle" element={<HubModelDetails />} />
              <Route path="/hub/models/:handle" element={<HubModelDetails />} />
              <Route
                path="/hub/models/:handle/jobs/:jobId"
                element={<HubJobDetailsPage />}
              />
              <Route path="/account" element={<SettingsPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/subscription" element={<SubscriptionPage />} />
              <Route path="/w/:workspaceId/keys" element={<ApiKeyListPage />} />
              <Route
                path="/w/:workspaceId/settings"
                element={<WorkspaceSettingsPage />}
              />

              <Route path="/other" element={<ChatListPageWrapper />} />
              <Route
                path="/w/:workspaceId/chats"
                element={<ChatListPageWrapper />}
              />
              <Route
                path="/chats"
                element={<ChatListPageWrapper area={true} />}
              />

              <Route
                path="/w/:workspaceId/chats/:folderId"
                element={<ChatListPageWrapper />}
              />
              <Route
                path="/chats/:folderId"
                element={<ChatListPageWrapper area={true} />}
              />
              <Route
                path="/w/:workspaceId/chats/:chatId/edit"
                element={<ChatPageWrapper />}
              />
              <Route
                path="/chats/:chatId/edit"
                element={<ChatPageWrapper area={true} />}
              />

              <Route
                path="/w/:workspaceId/prompts/:promptId"
                element={<PromptDetailsPageWrapper />}
              />
              <Route
                path="/prompts/:promptId"
                element={<PromptDetailsPageWrapper area={true} />}
              />
              <Route
                path="/w/:workspaceId/prompts/:promptId/:versionId"
                element={<PromptDetailsPageWrapper />}
              />
              <Route
                path="/prompts/:promptId/:versionId"
                element={<PromptDetailsPageWrapper area={true} />}
              />

              <Route
                path="/w/:workspaceId/prompts"
                element={<PromptListPageWrapper />}
              />
              <Route
                path="/prompts"
                element={<PromptListPageWrapper area={true} />}
              />

              <Route path="/w/:workspaceId/forms" element={<FormListPage />} />
              <Route
                path="/w/:workspaceId/forms/:formId"
                element={<FormDetailsPage />}
              />

              <Route
                path="/w/:workspaceId/endpoints/:endpointId"
                element={<EndpointDetailsPage />}
              />
              <Route
                path="/w/:workspaceId/endpoints"
                element={<EndpointListPage />}
              />
              <Route
                path="/w/:workspaceId/flows"
                element={<FlowWaitlistPage />}
              />
              <Route
                path="/w/:workspaceId/flows/:flowId"
                element={<FlowDetailsPage />}
              />
            </Route>

            {/* shared forms */}
            <Route
              path="/f/:workspaceId/:formId"
              element={<SharedFormPage />}
            />
            <Route
              path="/f/:workspaceId/:formId/:submissionId"
              element={<SharedFormResultPage />}
            />
            {/* account */}
            <Route
              path="/forgot-password/:tokenId"
              element={<ResetPasswordPage />}
            />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />

            <Route
              path="/register/email-confirmed"
              element={<EmailConfirmedPage />}
            />

            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/register/invitation/:invitationId"
              element={<InvitationPage />}
            />

            {/* Widgets */}
            <Route
              path="/widgets/chatbot/:chatbotId"
              element={<ChatbotWidgetPage />}
            />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem 2rem" }}>
                  <h1>Page not found</h1>
                </main>
              }
            />
          </Routes>
        </Router>
      </Provider>
      <ToastContainer position="top-right" />
    </IntlProvider>
  );
};

export default App;
