import { GET, POST, PUT, DELETE } from "./httpAPI";

const backend = {
  register: (request) => {
    return POST("/public/register", request);
  },
  registerAppsumo: (request) => {
    return POST("/public/register/appsumo", request);
  },
  registerPitchGround: (request) => {
    return POST("/public/register/pitchground", request);
  },
  registerDealify: (request) => {
    return POST("/public/register/dealify", request);
  },
  getInvitation: (uid) => {
    return GET("/public/invitations/" + uid);
  },
  registerByInvitation: (uid, request) => {
    return POST("/public/invitations/" + uid, request);
  },

  loginWithPassword: (username, password) => {
    return POST("/auth/login", { username, password });
  },
  resetPassword: (request) => {
    return POST("/public/forgot-password/reset", request);
  },
  getResetToken: (token) => {
    return GET("/public/forgot-password/reset/" + token);
  },
  finishReset: (token, request) => {
    return POST("/public/forgot-password/reset/" + token, request);
  },
  uploadAsset: (workspaceId, file, filename) => {
    const request = new FormData();
    request.append("file", file);
    request.append("filename", filename);
    return POST("/public/media/" + workspaceId + "/assets", request, true);
  },
  getMediaAsset: (workspaceId, uid) => {
    return GET("/public/media/" + workspaceId + "/assets/" + uid);
  },
  /*
  getMediaAssets: ( workspaceId, page) => {
    return GET("/public/media/" + workspaceId + "/assets?p=" + page);
  },
  getMediaAsset: ( workspaceId, uid) => {
    return GET("/public/media/" + workspaceId + "/assets/" + uid);
  },
  deleteMediaAsset: ( workspaceId, asset) => {
    return POST("/public/media/" + workspaceId + "/assets/" + asset.uid + "/delete");
  },
  updateMediaAssetImage: ( workspaceId, asset, image) => {
    const request = new FormData();
    request.append("file", image);
    return POST("/public/media/" + workspaceId + "/assets/" + asset.uid, request, true);
  },
 
  getUnsplashPhotos: ( workspaceId, page) => {
    return GET("/public/media/" + workspaceId + "/unsplash" + (page ? "?p=" + page : ""));
  },
  searchUnsplashPhotos: ( workspaceId, query, page) => {
    return GET("/public/media/" + workspaceId + "/unsplash/search?q=" + query + "&p=" + page);
  },
  addUnsplashPhoto: ( workspaceId, photo) => {
    return POST("/public/media/" + workspaceId + "/unsplash/add", photo);
  },
   */
  changePassword: (current, next) => {
    return POST("/users/creds", { current, next });
  },
  processOrder: (orderId, orderReference) => {
    return POST("/subscription/order", { orderId, orderReference });
  },
  getOrders: () => {
    return GET("/subscription/orders");
  },
  upgradeSubscription: (plan) => {
    return POST("/subscription/change", { plan });
  },

  processCreditsOrder: (orderId, orderReference) => {
    return POST("/credits/order", { orderId, orderReference });
  },
  getAccountCredits: () => {
    return GET("/credits");
  },

  // workspace
  getWorkspaces: () => {
    return GET("/workspaces");
  },
  lookupWorkspace: (templateUid) => {
    return GET("/sdk/workspace-lookup?templateId=" + templateUid);
  },
  createWorkspace: (name) => {
    return POST("/workspaces", { name });
  },
  deleteWorkspace: (uid) => {
    return POST("/workspaces/" + uid + "/delete");
  },
  updateWorkspace: (uid, request) => {
    return POST("/workspaces/" + uid, request);
  },
  // workspace members
  getWorkspaceMembers: (uid) => {
    return GET("/workspaces/" + uid + "/users");
  },
  sendWorkspaceInvite: (workspaceId, { email, isAdmin }) => {
    return POST("/workspaces/" + workspaceId + "/users", {
      email,
      role: isAdmin ? "ADMIN" : "CONTRIBUTOR",
    });
  },
  cancelWorkspaceInvite: (workspaceId, uid) => {
    return POST(
      "/workspaces/" + workspaceId + "/invitations/" + uid + "/delete"
    );
  },
  updateWorkspaceMember: (workspaceId, uid, role) => {
    console.log("updateWorkspaceMember ", uid, role);
    return POST("/workspaces/" + workspaceId + "/users/" + uid, { role });
  },
  deleteWorkspaceMember: (workspaceId, uid) => {
    return POST("/workspaces/" + workspaceId + "/users/" + uid + "/delete");
  },
  // fonts
  getWorkspaceFonts: (uid) => {
    return GET("/workspaces/" + uid + "/fonts");
  },
  createWorkspaceFont: (workspaceId, file) => {
    const request = new FormData();
    request.append("file", file);
    return POST("/workspaces/" + workspaceId + "/fonts", request, true);
  },
  deleteWorkspaceFont: (workspaceId, uid) => {
    return POST("/workspaces/" + workspaceId + "/fonts/" + uid + "/delete");
  },
  // team
  getTeam: () => {
    return GET("/team");
  },
  sendInvite: ({ email, isAdmin }) => {
    return POST("/team", { email, role: isAdmin ? "ADMIN" : "CONTRIBUTOR" });
  },
  cancelInvite: (uid) => {
    return POST("/team/invitations/" + uid + "/delete");
  },
  deleteMember: (uid) => {
    return POST("/team/members/" + uid + "/delete");
  },
  // new
  createCampaign: (name, workspaceId) => {
    return POST("/w/" + workspaceId + "/campaigns", { name });
  },
  getCampaigns: (workspaceId) => {
    return GET("/w/" + workspaceId + "/campaigns");
  },
  getCampaign: (workspaceId, campaignId) => {
    return GET("/w/" + workspaceId + "/campaigns/" + campaignId);
  },
  deleteCampaign: (workspaceId, campaignId) => {
    return DELETE("/w/" + workspaceId + "/campaigns/" + campaignId);
  },
  updateCampaign: (workspaceId, uid, campaign) => {
    return PUT("/w/" + workspaceId + "/campaigns/" + uid, campaign);
  },
  getInterviewCampaign: (campaignId) => {
    return GET("/public/interview-campaigns/" + campaignId);
  },
  createSubmission: (campaignId, name, email) => {
    return POST("/public/interviews", { campaignId, name, email });
  },
  getUserSubmission: (submissionId) => {
    return GET("/public/interviews/" + submissionId);
  },
  updateUserSubmission: (submissionId, submission) => {
    return PUT("/public/interviews/" + submissionId, submission);
  },
  getSubmissions: (workspaceId, campaignId) => {
    return GET(
      "/w/" + workspaceId + "/campaigns/" + campaignId + "/submissions"
    );
  },
  getSubmission: (workspaceId, campaignId, submissionId) => {
    return GET(
      "/w/" +
        workspaceId +
        "/campaigns/" +
        campaignId +
        "/submissions/" +
        submissionId
    );
  },
  deleteSubmission: (workspaceId, campaignId, submissionId) => {
    return DELETE(
      "/w/" +
        workspaceId +
        "/campaigns/" +
        campaignId +
        "/submissions/" +
        submissionId
    );
  },
  saveSubmissionStatus: (workspaceId, campaignId, submissionId, status) => {
    return POST(
      "/w/" +
        workspaceId +
        "/campaigns/" +
        campaignId +
        "/submissions/" +
        submissionId +
        "/status",
      { status }
    );
  },
  /** promptleo new */
  createPrompt: (name, workspaceId) => {
    return POST("/w/" + workspaceId + "/prompts", { name });
  },
  getPrompts: (workspaceId) => {
    return GET("/w/" + workspaceId + "/prompts");
  },
  getPrompt: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/prompts/" + id);
  },
  deletePrompt: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/prompts/" + id);
  },
  updatePrompt: (workspaceId, uid, prompt) => {
    return PUT("/w/" + workspaceId + "/prompts/" + uid, prompt);
  },
  getPromptVersions: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/prompts/" + id + "/versions");
  },
  getPromptVersion: (workspaceId, promptId, versionId) => {
    return GET(
      "/w/" + workspaceId + "/prompts/" + promptId + "/versions/" + versionId
    );
  },
  createPromptVersion: (version, workspaceId, promptId) => {
    return POST(
      "/w/" + workspaceId + "/prompts/" + promptId + "/versions",
      version
    );
  },
  updatePromptVersion: (workspaceId, promptUid, version) => {
    return PUT(
      "/w/" +
        workspaceId +
        "/prompts/" +
        promptUid +
        "/versions/" +
        version.uid,
      version
    );
  },
  deletePromptVersion: (workspaceId, promptUid, versionUid) => {
    return DELETE(
      "/w/" + workspaceId + "/prompts/" + promptUid + "/versions/" + versionUid
    );
  },
  getApprovedPrompts: (workspaceId, keyword) => {
    return GET(
      "/w/" +
        workspaceId +
        "/approved-prompts" +
        (keyword ? "?keyword=" + keyword : "")
    );
  },
  /* api keys */
  createApiKey: (apiKey, workspaceId) => {
    return POST("/w/" + workspaceId + "/keys", apiKey);
  },
  getApiKeys: (workspaceId) => {
    return GET("/w/" + workspaceId + "/keys");
  },
  getApiKey: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/keys/" + id);
  },
  deleteApiKey: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/keys/" + id);
  },
  updateApiKey: (workspaceId, uid, apiKey) => {
    return PUT("/w/" + workspaceId + "/keys/" + uid, apiKey);
  },
  /* forms */
  createForm: (form, workspaceId) => {
    return POST("/w/" + workspaceId + "/forms", form);
  },
  getForms: (workspaceId) => {
    return GET("/w/" + workspaceId + "/forms");
  },
  getForm: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/forms/" + id);
  },
  deleteForm: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/forms/" + id);
  },
  updateForm: (workspaceId, uid, form) => {
    return PUT("/w/" + workspaceId + "/forms/" + uid, form);
  },
  getFormSubmissions: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/forms/" + id + "/submissions");
  },
  /** shared forms */
  getSharedForm: (workspaceId, id) => {
    return GET("/public/" + workspaceId + "/shared-forms/" + id);
  },
  getSharedSubmissionForm: (workspaceId, id) => {
    return GET("/public/" + workspaceId + "/shared-form-result/forms/" + id);
  },
  getSharedPromptVersion: (workspaceId, promptId, versionId) => {
    return GET(
      "/public/" +
        workspaceId +
        "/shared-prompts/" +
        promptId +
        "/versions/" +
        versionId
    );
  },
  getSharedFormSubmission: (workspaceId, id, submissionId) => {
    return GET(
      "/public/" +
        workspaceId +
        "/shared-form-result/submissions/" +
        submissionId
    );
  },
  /* endpoints */
  createEndpoint: (endpoint, workspaceId) => {
    return POST("/w/" + workspaceId + "/endpoints", endpoint);
  },
  getEndpoints: (workspaceId) => {
    return GET("/w/" + workspaceId + "/endpoints");
  },
  getEndpoint: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/endpoints/" + id);
  },
  deleteEndpoint: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/endpoints/" + id);
  },
  updateEndpoint: (workspaceId, uid, endpoint) => {
    return PUT("/w/" + workspaceId + "/endpoints/" + uid, endpoint);
  },
  getEndpointRequests: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/endpoints/" + id + "/requests");
  },
  execEndpoint: (workspaceId, uid, name, request) => {
    return PUT("/customer/" + workspaceId + "/endpoints/" + name, request);
  },
  /* flows */
  createFlow: (flow, workspaceId) => {
    return POST("/w/" + workspaceId + "/flows", flow);
  },
  getFlows: (workspaceId) => {
    return GET("/w/" + workspaceId + "/flows");
  },
  getFlow: (workspaceId, id) => {
    return GET("/w/" + workspaceId + "/flows/" + id);
  },
  deleteFlow: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/flows/" + id);
  },
  updateFlow: (workspaceId, uid, flow) => {
    return PUT("/w/" + workspaceId + "/flows/" + uid, flow);
  },
  /* models */
  getModel: (workspaceId, modelId) => {
    return GET("/w/" + workspaceId + "/models/" + modelId);
  },
  saveModel: (workspaceId, modelId, request) => {
    return POST("/w/" + workspaceId + "/models/" + modelId, request);
  },
  /* chats */
  getChats: (workspaceId, folderId, keyword) => {
    return GET(
      "/w/" +
        workspaceId +
        "/chats" +
        (folderId ? "?folder_id=" + folderId : "") +
        (keyword ? (folderId ? "&" : "?") + "keyword=" + keyword : "")
    );
  },
  getChat: (workspaceId, chatId) => {
    return GET("/w/" + workspaceId + "/chats/" + chatId);
  },
  createChat: (workspaceId, request) => {
    return POST("/w/" + workspaceId + "/chats", request);
  },
  cloneChat: (chatUid) => {
    return POST("/chats-clone", { chatUid });
  },
  updateChat: (workspaceId, chatId, request) => {
    return PUT("/w/" + workspaceId + "/chats/" + chatId, request);
  },
  postChatMessage: (workspaceId, chatId, message) => {
    return POST("/w/" + workspaceId + "/chats/" + chatId, { content: message });
  },
  saveReply: (workspaceId, chatId, reply) => {
    return POST("/w/" + workspaceId + "/chats/" + chatId + "/reply", reply);
  },
  deleteChat: (workspaceId, id) => {
    return DELETE("/w/" + workspaceId + "/chats/" + id);
  },
  deleteChatMessage: (workspaceId, chatId, messageId) => {
    return DELETE(
      "/w/" + workspaceId + "/chats/" + chatId + "/messages/" + messageId
    );
  },
  updateChatMessage: (workspaceId, chatId, messageId, content) => {
    return PUT(
      "/w/" + workspaceId + "/chats/" + chatId + "/messages/" + messageId,
      { content }
    );
  },
  uploadChatFile: (workspaceId, chatId, file) => {
    const request = new FormData();
    request.append("file", file);
    return POST(
      "/w/" + workspaceId + "/chats/" + chatId + "/upload",
      request,
      true
    );
  },
  lookupWorkspaceBySubdomain: (subdomainOrId) => {
    return GET("/public/workspace-lookup?subdomain=" + subdomainOrId);
  },
  /**** HUB  *****/
  getHubJobs: () => {
    return GET("/jobs");
  },
  getHubJob: (id) => {
    return GET("/jobs/" + id);
  },
  getHubJobsByModel: (model) => {
    return GET("/jobs-by-model?model=" + encodeURIComponent(model));
  },
  /***** CHATBOTS *****/
  getChatbotList: () => {
    return GET("/chatbots");
  },
  getChatbot: (uid) => {
    return GET("/chatbots/" + uid);
  },
  createChatbot: (request) => {
    return POST("/chatbots", request);
  },
  updateChatbot: (data) => {
    return PUT("/chatbots/" + data.uid, data);
  },
  deleteChatbot: (uid) => {
    return DELETE("/chatbots/" + uid);
  },
  indexChatbot: (data) => {
    return POST("/chatbots/" + data.uid + "/index", {});
  },
  getChatMessages: (chatbotUid, chatUid) => {
    return GET("/chatbots/" + chatbotUid + "/chats/" + chatUid + "/messages");
  },
  getKbpages: (chatbotUid) => {
    return GET("/chatbots/" + chatbotUid + "/kbpages");
  },
  /**** Chatbot Widbet *****/
  getWidgetChatbot: (uid, clientId) => {
    return GET("/public/chatbots/" + uid + "?cid=" + clientId);
  },
  createWidgetChat: (chatbotId, request) => {
    return POST("/public/chatbots/" + chatbotId + "/chats", request);
  },
};

export default backend;
