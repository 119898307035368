import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage as Message, useIntl } from "react-intl";
import dayjs from "dayjs";

import Spinner from "../../assets/icons/Spinner";
import { login } from "../auth/store/actionCreators";
import { authSelectors } from "../auth/store/authReducer";

import UserPlusIcon from "../../assets/icons/UserPlusIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
import { teamSelectors } from "./store/teamReducer";
import {
  cancelInvitation,
  deleteMember,
  loadTeam,
  sendInvite,
} from "./store/teamActionCreators";
import InviteMemberDialog from "./InviteMemberDialog";
import MailIcon from "../../assets/icons/MailIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import CrossIcon from "../../assets/icons/CrossIcon";
import UserXIcon from "../../assets/icons/UserXIcon";
import DeleteMemberDialog from "./DeleteMemberDialog";
import { subscriptionUserLimits } from "../shared/pricingLimits";
import label from "../../i18n/label";
import KeyIcon from "../../assets/icons/KeyIcon";
import GlobalLayout from "../shared/layout/GlobalLayout";

import "./TeamPage.scss";

const TeamPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.getUser);
  const team = useSelector(teamSelectors.getTeam);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(false);

  useEffect(() => {
    if (null === user) {
      dispatch(login());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (null === team) {
      dispatch(loadTeam(intl));
    }
  }, [dispatch, team, intl]);

  const onInvite = (params, onSuccess, onError) => {
    dispatch(sendInvite(intl, params, onSuccess, onError));
  };

  const onCancelInvitation = (uid) => {
    dispatch(cancelInvitation(intl, uid));
  };

  const onMemberDelete = (member, onSuccess, onError) => {
    dispatch(deleteMember(intl, member.uid, onSuccess, onError));
  };

  const maxUsers = subscriptionUserLimits[user.subscription.plan];
  let isLimitReached = maxUsers === 1;
  /*
  if (team) {
    isLimitReached = team.invitations.length + team.members.length >= maxUsers;
  }
  */

  return (
    <GlobalLayout contentPanelClassName="team_content_panel">
      <h2>
        <Message id="users" />
      </h2>
      <div className="actions_panel">
        <button
          className="btn btn-outline-dark "
          onClick={() => setShowInviteDialog(true)}
          disabled={isLimitReached}
        >
          <UserPlusIcon />
          <Message id="invite" />
        </button>
        {isLimitReached && (
          <div
            className="upgrade_notice"
            dangerouslySetInnerHTML={{
              __html: label(intl, "user_limit_reached"),
            }}
          ></div>
        )}
      </div>
      {team ? (
        <div className="card">
          {team.invitations && team.invitations.length > 0 && (
            <>
              <div className="panel_title">
                <Message id="invitations_panel_title" />
              </div>
              <div className="invitations_panel">
                {team.invitations.map((inv) => {
                  return (
                    <div className="invitation" key={inv.uid}>
                      <div>
                        <MailIcon color="#664d03" /> {inv.email}
                      </div>
                      <div>
                        <KeyIcon color="#664d03" strokeWidth="2" />
                        <Message id={"role_" + inv.role} />
                      </div>
                      <div>
                        <CalendarIcon color="#664d03" />
                        {dayjs(inv.dateCreated).format("DD MMM")}
                      </div>
                      <div>
                        <CheckIcon color="#664d03" strokeWidth="2" />
                        <Message id="invitation_sent" />
                      </div>
                      <div className="action">
                        <div
                          className="app-btn"
                          onClick={() => onCancelInvitation(inv.uid)}
                        >
                          <CrossIcon color="#664d03" />
                          <Message id="cancel" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="panel_title">
            <Message id="users_panel_title" />
          </div>
          <div className="members_panel">
            {team.members.map((member) => {
              return (
                <div className="member" key={member.uid}>
                  <div>
                    <div>
                      <UserCircleIcon />{" "}
                    </div>
                    <div className="member_name">
                      <div>{member.user.name}</div>
                      <div className="member_unteremail">
                        {member.user.email}
                      </div>
                    </div>
                  </div>
                  <div>
                    <KeyIcon color="#664d03" strokeWidth="2" />
                    <Message id={"role_" + member.role} />
                  </div>
                  <div>
                    <CalendarIcon color="#664d03" />
                    {dayjs(member.dateCreated).format("DD MMM")}
                  </div>
                  <div className="action">
                    {user.email !== member.user.email &&
                      !member.user.roleList.includes("ROLE_ADMIN") &&
                      member.user.uid !== team.owner.uid && (
                        <div
                          className="app-btn"
                          onClick={() => setMemberToDelete(member)}
                        >
                          <UserXIcon />
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {showInviteDialog && (
        <InviteMemberDialog
          onClose={() => setShowInviteDialog(false)}
          onInvite={onInvite}
        />
      )}
      {memberToDelete && (
        <DeleteMemberDialog
          member={memberToDelete}
          onDelete={onMemberDelete}
          onClose={() => setMemberToDelete(null)}
        />
      )}
    </GlobalLayout>
  );
};
export default TeamPage;
