import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import ApiKeyActions from "./ApiKeyActions";
import PromptActions from "./ApiKeyActions";

export const createApiKey = (intl, apiKey, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createApiKey(apiKey, workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: ApiKeyActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create an api key", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadApiKeys = (intl, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getApiKeys(workspaceId)
      .then((data) => {
        console.log("loaded api keys", data);
        dispatch({
          type: ApiKeyActions.LIST_LOADED,
          list: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load api keys", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadApiKey = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getApiKey(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: ApiKeyActions.LOADED,
          key: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load an api key", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateApiKey = (
  intl,
  workspaceId,
  uid,
  apiKey,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updateApiKey(workspaceId, uid, apiKey)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: ApiKeyActions.UPDATED,
          key: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update an api key", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteApiKey = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deleteApiKey(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: ApiKeyActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a campaign", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
