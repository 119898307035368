const memberRole = {
  ADMIN: "ADMIN",
  CONTRIBUTOR: "CONTRIBUTOR",
  READER: "READER",
  USER: "USER",
};

export const teamRole = {
  TEAM_ADMIN: "TEAM_ADMIN",
};

export const isTeamAdmin = (user) => {
  return user.roles && user.roles.find((item) => item === teamRole.TEAM_ADMIN);
};
export const isWorkspaceAdmin = (workspace) => {
  return workspace?.memberRole === memberRole.ADMIN;
};

export default memberRole;
