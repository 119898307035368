import "./IndexedPages.scss";

const IndexedPages = ({ pages }) => {
  return (
    <div className="chatbot_indexed_pages">
      {pages.map((item) => {
        return (
          <div className="indexed_page" key={item.url}>
            <div>
              <a href={item.url} target="_blank" rel="noopener noreferer">
                {item.url}
              </a>
            </div>
            <div>{item.textTokenCount}</div>
          </div>
        );
      })}
    </div>
  );
};

export default IndexedPages;
