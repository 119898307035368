import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import FlowActions from "./FlowActions";

export const createFlow = (intl, flow, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createFlow(flow, workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FlowActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create a flow", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadFlows = (intl, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getFlows(workspaceId)
      .then((data) => {
        console.log("loaded forms", data);
        dispatch({
          type: FlowActions.LIST_LOADED,
          list: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load flows", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadFlow = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getFlow(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FlowActions.LOADED,
          flow: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load a flow", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateFlow = (
  intl,
  workspaceId,
  uid,
  flow,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updateFlow(workspaceId, uid, flow)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: FlowActions.UPDATED,
          flow: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update a flow", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteFlow = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deleteFlow(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: FlowActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete a form", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
