import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import ClockIcon from "../../../../assets/icons/ClockIcon";
import Message from "../../../../i18n/Message";
import CodeMirror from "@uiw/react-codemirror";
import { githubLight } from "@uiw/codemirror-theme-github";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";

import "./FormSubmission.scss";
import CalendarIcon from "../../../../assets/icons/CalendarIcon";
import AlertTriangleIcon from "../../../../assets/icons/AlertTriangleIcon";

const editorHeight = "220px";

const FormSubmission = ({ submission, index }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const onDeleteConfirmed = () => {
    setShowDeleteConfirm(false);
  };

  const input = JSON.parse(submission.input);
  const output = JSON.parse(submission.output);
  return (
    <div className="request_item">
      <div className="date">
        <div>
          <CalendarIcon color="#555" size="16" />
          {dayjs(submission.dateCreated).format("LLL")}
        </div>
        <div className="time_diff">
          {dayjs(submission.dateCreated).fromNow()}
        </div>
        <div>
          <ClockIcon color="#555" size="16" /> {submission.duration / 1000}s.
        </div>
      </div>
      <div className="params_content">
        {Object.keys(input.params).map((item, idx) => {
          return (
            <div className="param_item" key={item + idx}>
              <label>{item}</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={input.params[item]}
              />
            </div>
          );
        })}
      </div>
      <div className="response_content">
        <div className="result_label">
          <Message id="form_submission_result" />
        </div>
        {!output && submission && submission.status === "ERROR" && (
          <div className="submission_error">
            <AlertTriangleIcon color="#856404" />
            <Message id="submission_error_result" />
          </div>
        )}
        {output?.choices?.map((item, idx) => {
          return (
            <div className="output_item" key={item + idx}>
              {item.text}
            </div>
          );
        })}
      </div>
      <div className="item_actions">
        <div className="confirm_container">
          {showDeleteConfirm && (
            <>
              <div
                className="cover"
                onClick={() => setShowDeleteConfirm(false)}
              ></div>
              <div className="confirm_panel">
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              </div>
            </>
          )}
        </div>
        <button
          className="btn no-btn"
          onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
        >
          <TrashIcon size="18" color="#344767" />
        </button>
      </div>
    </div>
  );
};

export default FormSubmission;
