import { BotOff } from "lucide-react";
import "./NotFoundState.scss";
import Message from "../../../../i18n/Message";

const NotFoundState = () => {
  return (
    <div className="chatbot_not_found_state">
      <div className="chatbot_not_found_state_icon">
        {/*<FlowsIcon color="#67748e" size="36" />*/}
        <BotOff size={48} color="#67748e" />
      </div>
      <div className="chatbot_not_found_state_text">
        <Message id="widget_chatbot_not_found" />
      </div>
    </div>
  );
};

export default NotFoundState;
