export const subscriptionPlan = {
  FREE: "free",
  LITE: "lite",
  PRO: "pro",
  DEALIFY: "dealify",
  DEALIFY_2X: "dealify_2x",
  DEALIFY_3X: "dealify_3x",
};

export const subscriptionUserLimits = {
  [subscriptionPlan.FREE]: 1,
  [subscriptionPlan.LITE]: 2,
  [subscriptionPlan.DEALIFY]: 2,
  [subscriptionPlan.DEALIFY_2X]: 4,
  [subscriptionPlan.DEALIFY_3X]: 6,
  [subscriptionPlan.PRO]: 5,
};

export const subscriptionTemplateLimits = {
  [subscriptionPlan.FREE]: 1,
  [subscriptionPlan.LITE]: 10000,
  [subscriptionPlan.DEALIFY]: 10000,
  [subscriptionPlan.DEALIFY_2X]: 10000,
  [subscriptionPlan.DEALIFY_3X]: 10000,
  [subscriptionPlan.PRO]: 1000,
};

export const subscriptionWorkspaceLimits = {
  [subscriptionPlan.FREE]: 1,
  [subscriptionPlan.LITE]: 2,
  [subscriptionPlan.DEALIFY]: 2,
  [subscriptionPlan.DEALIFY_2X]: 4,
  [subscriptionPlan.DEALIFY_3X]: 6,
  [subscriptionPlan.PRO]: 5,
};
