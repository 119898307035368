import { useIntl } from "react-intl";
import "./EmbedTab.scss";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { chatbotSelectors } from "../../store/chatbotReducer";
import Message from "../../../../i18n/Message";
import Notify from "../../../shared/Notify";
import label from "../../../../i18n/label";
import CopyIcon from "../../../../assets/icons/CopyIcon";
import ErrorState from "../../shared/ErrorState";
import { CircleAlertIcon } from "lucide-react";

const EmbedTab = () => {
  const intl = useIntl();
  const textareaRef = useRef(null);
  const { chatbotId } = useParams();
  const chatbot = useSelector(chatbotSelectors.getChatbot);

  const onWidgetCodeClick = () => {
    if (textareaRef.current) {
      // Force selection of all text
      const textarea = textareaRef.current;
      const length = textarea.value.length;

      // First focus the element
      textarea.focus();

      // Then use setSelectionRange for better cross-browser compatibility
      textarea.setSelectionRange(0, length);

      // Also call select() as a fallback
      textarea.select();
    }
  };

  const onCopyCode = () => {
    navigator.clipboard.writeText(textareaRef.current.value);
    Notify.success(label(intl, "copy_success"));
  };

  const host = "https://promptleo.com";
  //const host = "http://localhost:3333";
  const widgetCode = `<div
  class="promptleo-chatbot-widget"
  style="width: 100%; height: 700px; margin: auto; padding: 3rem" 
  chatbot-id="${chatbotId}"
  ></div>
  <script id="promptleo-chatbot-loader" src="${host}/app/widgets/chatbot-widget-v1.js"></script>`;

  if (!chatbot?.chatbot?.kbSources) {
    return (
      <div className="card content_panel_body">
        <ErrorState
          icon={<CircleAlertIcon color="#856404" size={32} />}
          textKey="chatbot_tab_embed_not_ready_error"
        />
      </div>
    );
  }

  return (
    chatbot?.chatbot?.kbSources && (
      <div className="card content_panel_body">
        <div className="card_header">
          <div className="card_title">
            <Message id="embed_tab_title" />
          </div>
          <div className="card_actions"></div>
        </div>
        <div className="widget_code_body">
          <p>
            <Message id="widget_code_help" />
          </p>
          <textarea
            readOnly={true}
            rows="7"
            ref={textareaRef}
            value={widgetCode}
            onClick={onWidgetCodeClick}
            className="form-control"
          ></textarea>
          <div className="widget_code_button_container">
            <button className="btn btn-outline-dark" onClick={onCopyCode}>
              <CopyIcon color="#344767" size={18} />
              <Message id="widget_code_copy_button" />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default EmbedTab;
