import { useParams } from "react-router-dom";
import WorkspaceLayout from "../shared/layout/WorkspaceLayout";
import "./WorkspaceSettingsPage.scss";

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../assets/icons/Spinner";
import TrashIcon from "../../assets/icons/TrashIcon";
import { isTeamAdmin, isWorkspaceAdmin } from "../../backend/memberRole";
import Message from "../../i18n/Message";
import SettingsAPIKey from "../account/SettingsAPIKey";
import { login } from "../auth/store/actionCreators";
import { authSelectors } from "../auth/store/authReducer";
import { deleteWorkspace } from "../shared/layout/store/workspaceActionCreators";
import { workspaceSelectors } from "../shared/layout/store/workspaceReducer";
import WorkspaceUID from "./WorkspaceUID";
import WorkspaceUsers from "./users/WorkspaceUsers";

const WorkspaceSettingsPage = () => {
  const { workspaceId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const workspace = useSelector(workspaceSelectors.getCurrent(workspaceId));
  const workspaces = useSelector(workspaceSelectors.getWorkspaces);
  //const [origin, setOrigin] = useState(workspace.allowedOrigins);
  const user = useSelector(authSelectors.getUser);

  useEffect(() => {
    if (null === user) {
      dispatch(login());
    }
  }, [dispatch, user]);

  const onDeleteConfirmed = () => {
    dispatch(
      deleteWorkspace(intl, workspace.uid, () => (window.location = "/app"))
    );
  };
  /*
  const onOriginSubmit = (e) => {
    e && e.preventDefault();

    const req = {
      allowedOrigins: origin ? origin : "",
    };
    dispatch(updateWorkspace(intl, workspace.uid, req, () => Notify.success(label(intl, "url_params_settings_saved"))));

    return false;
  };
*/
  return (
    <WorkspaceLayout contentPanelClassName="workspace_settings">
      {!workspace && <Spinner />}
      {workspace && (
        <>
          <h2>{workspace.name}</h2>

          {(isTeamAdmin(user) || isWorkspaceAdmin(workspace)) && (
            <WorkspaceUsers workspace={workspace} />
          )}
          <WorkspaceUID workspace={workspace} />

          <SettingsAPIKey user={user} />

          {(isTeamAdmin(user) || isWorkspaceAdmin(workspace)) && (
            <>
              <div className="settings_panel project_delete_panel">
                <h3>
                  <Message id="delete_workspace" />
                </h3>
                <p>
                  <Message id="delete_workspace_text" />
                </p>
                <div>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    disabled={workspaces?.length < 2}
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    <TrashIcon color="#cb0c9f" /> <Message id="delete" />
                  </button>
                  {showDeleteConfirm && (
                    <div className="confirm_container">
                      <div
                        className="cover"
                        onClick={() => setShowDeleteConfirm(false)}
                      ></div>
                      <div className="confirm_panel">
                        <button
                          className="btn bg-gradient-primary btn-sm"
                          onClick={onDeleteConfirmed}
                        >
                          <Message id="confirm_delete" />
                        </button>
                      </div>
                    </div>
                  )}
                  {workspaces?.length < 2 && (
                    <div className="delete_disabled_hint">
                      <Message id="delete_disabled_hint" />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </WorkspaceLayout>
  );
};

export default WorkspaceSettingsPage;
