import React from "react";

import label from "../../i18n/label";
import Select from "react-select";
import { useIntl } from "react-intl";
import memberRole from "../../backend/memberRole";

const MemberRoleDropdown = ({ value, onChange }) => {
  const intl = useIntl();

  const options = [
    {
      value: memberRole.ADMIN,
      label: label(intl, "role_ADMIN"),
    },
    {
      value: memberRole.CONTRIBUTOR,
      label: label(intl, "role_CONTRIBUTOR"),
    },
    {
      value: memberRole.READER,
      label: label(intl, "role_READER"),
    },
  ];
  const selectedOption = value
    ? options.find((item) => item.value === value)
    : options[1];

  return (
    <Select
      options={options}
      placeholder={label(intl, "member_role_placeholder")}
      isClearable={false}
      value={selectedOption}
      onChange={(option) => {
        onChange(option.value);
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "180px",
        }),
      }}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

export default MemberRoleDropdown;
