import produce from "immer";
import reducerUtils from "../../../../store/reducerUtils";
import WorkspaceUserActions from "./workspaceUsersActionTypes";


const initialState = {
  users: null,
  usersWorkspaceId: null,
};

export const workspaceUserSelectors = {
  getUsers: workspaceId => (state) => workspaceId && state.workspaceUsers.usersWorkspaceId === workspaceId ? state.workspaceUsers.users: null,
};

const onUsersLoaded = produce((draft, action) => {
  draft.users = action.users;
  draft.usersWorkspaceId = action.workspaceId;
});

const handlers = {
  [WorkspaceUserActions.USERS_LOADED]: onUsersLoaded,
};

const workspaceUsersReducer = reducerUtils.createReducer(initialState, handlers);

export default workspaceUsersReducer;
