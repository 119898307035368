import React from "react";
import Message from "../../../i18n/Message";
import ModalDialog from "../ModalDialog";

import "./TrialExpiredDialog.scss";

const TrialExpiredDialog = ({ user }) => {
  return (
    <ModalDialog className="trial_exired_dialog" closable={false}>
      <h1>
        <Message id="trial_expired" />
      </h1>
      <div className="trial_exired_dialog_body">
        <div className="expired_message">
          <Message id="trial_expired" />. <Message id="trial_summary_text" />.
        </div>
      </div>
      <div className="buttons">
        <button
          className="btn btn-dark"
          onClick={() =>
            window.showPaymentWindow(user, user.subscription.planCode)
          }
        >
          <Message id="add_card" />
        </button>
      </div>
    </ModalDialog>
  );
};

export default TrialExpiredDialog;
