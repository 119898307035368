import ChatListPage from "./ChatListPage";

import WorkspacePageWrapper from "../../workspace/WorkspacePageWrapper";

const ChatListPageWrapper = ({ area }) => {
  return (
    <WorkspacePageWrapper area={area}>
      <ChatListPage />
    </WorkspacePageWrapper>
  );
};

export default ChatListPageWrapper;
