import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../assets/icons/Spinner";
import TrashIcon from "../../../assets/icons/TrashIcon";

import Message from "../../../i18n/Message";
import { linkToEndpoints } from "../../../routes";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";

import EndpointRequestList from "./request/EndpointRequestList";
import { endpointSelectors } from "../store/endpointReducer";
import {
  deleteEndpoint,
  loadEndpoint,
  loadEndpointRequests,
} from "../store/endpointActionCreators";
import ApiConsole from "./api-console/ApiConsole";

import "./EndpointDetailsPage.scss";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import EditEndpointDialog from "../edit/EditEndpointDialog";

const EndpointDetailsPage = () => {
  const { endpointId, workspaceId } = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const endpoint = useSelector(endpointSelectors.getEndpoint);
  const requestCounter = useSelector(
    endpointSelectors.getEndpointRequestsCounter
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (!endpoint || endpoint?.uid !== endpointId) {
      dispatch(loadEndpoint(intl, workspaceId, endpointId));
    }
  }, [endpoint, endpointId, workspaceId, dispatch, intl]);

  useEffect(() => {
    dispatch(loadEndpointRequests(intl, workspaceId, endpointId));
  }, [endpointId, requestCounter, workspaceId, dispatch, intl]);

  const onDeleteConfirmed = () => {
    const onSuccess = () => {
      setShowDeleteConfirm(false);
      nav(linkToEndpoints(workspaceId));
    };
    dispatch(
      deleteEndpoint(intl, workspaceId, endpointId, onSuccess, () =>
        setShowDeleteConfirm(false)
      )
    );
  };
  console.log("Endpoint", endpoint, endpoint?.uid === endpointId);
  const item = !!endpoint && endpoint?.uid === endpointId ? endpoint : null;

  return (
    <WorkspaceLayout contentPanelClassName="endpoint_details_main">
      {!item && (
        <div className="spinner_container">
          <Spinner />
        </div>
      )}
      {item && (
        <>
          <div className="title_row card">
            <div className="title_top_panel">
              <div className="title_column">
                <div className="campaign_title">/{endpoint.name}</div>
                <div className="metadata_row">
                  <div className="meta_name">
                    <Message id="created_on" />
                  </div>
                  <div className="meta_value">
                    {dayjs(item.dateCreated).format("LLL")}
                  </div>
                </div>

                {/*
              <div>
                <ToggleSwitch
                  onChange={onStatusChanged}
                  value={status}
                  optionLeft={{ label: "campaign_offline", value: "DRAFT" }}
                  optionRight={{ label: "campaign_online", value: "ONLINE" }}
                />
              </div>
      */}
              </div>
              <div className="title_actions">
                <div className="confirm_container">
                  {showDeleteConfirm && (
                    <>
                      <div
                        className="cover"
                        onClick={() => setShowDeleteConfirm(false)}
                      ></div>
                      <div className="confirm_panel">
                        <button
                          className="btn bg-gradient-primary btn-sm"
                          onClick={onDeleteConfirmed}
                        >
                          <Message id="confirm_delete" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <button
                  className="btn no-btn tile-action"
                  onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                >
                  <TrashIcon size="16" color="#344767" />
                  <Message id="delete" />
                </button>
                <button
                  className="btn no-btn tile-action"
                  onClick={() => setShowEdit(true)}
                >
                  <Edit2Icon size="16" color="#344767" />
                  <Message id="edit" />
                </button>
              </div>
            </div>
            <ApiConsole endpoint={endpoint} />
          </div>
          <EndpointRequestList endpoint={endpoint} />
          {/*
  
          <CampaignSubmissions campaign={campaign} submissions={submissios} />
                */}
        </>
      )}
      {showEdit && (
        <EditEndpointDialog
          endpoint={endpoint}
          onClose={() => setShowEdit(false)}
        />
      )}
    </WorkspaceLayout>
  );
};

export default EndpointDetailsPage;
