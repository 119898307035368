export const linkToAccountSettings = () => {
  return "/account";
};

export const linkToSubscription = () => {
  return "/subscription";
};

export const linkToTeam = () => {
  return "/team";
};

export const linkToSettings = (wokrspaceId) => {
  return `/w/${wokrspaceId}/settings`;
};

export const linkToCampaigns = (wokrspaceId) => {
  return `/w/${wokrspaceId}/campaigns`;
};

export const linkToCampaign = (wokrspaceId, campaignId) => {
  return `/w/${wokrspaceId}/campaigns/${campaignId}`;
};
export const linkToChats = (wokrspaceId) => {
  return `/w/${wokrspaceId}/chats`;
};
export const linkToChat = (wokrspaceId, id) => {
  return `/w/${wokrspaceId}/chats/${id}/edit`;
};
export const linkToChatFolder = (wokrspaceId, id) => {
  return `/w/${wokrspaceId}/chats/${id}`;
};
export const linkToApiKeys = (wokrspaceId) => {
  return `/w/${wokrspaceId}/keys`;
};

export const linkToPrompts = (wokrspaceId) => {
  return `/w/${wokrspaceId}/prompts`;
};

export const linkToPrompt = (wokrspaceId, promptId) => {
  return `/w/${wokrspaceId}/prompts/${promptId}`;
};

export const linkToPromptVersion = (wokrspaceId, promptId, versionId) => {
  return `/w/${wokrspaceId}/prompts/${promptId}/${versionId}`;
};

export const linkToForms = (wokrspaceId) => {
  return `/w/${wokrspaceId}/forms`;
};

export const linkToForm = (wokrspaceId, id) => {
  return `/w/${wokrspaceId}/forms/${id}`;
};

export const linkToEndpoints = (wokrspaceId) => {
  return `/w/${wokrspaceId}/endpoints`;
};

export const linkToEndpoint = (wokrspaceId, id) => {
  return `/w/${wokrspaceId}/endpoints/${id}`;
};

export const linkToFlows = (wokrspaceId) => {
  return `/w/${wokrspaceId}/flows`;
};

export const linkToFlow = (wokrspaceId, id) => {
  return `/w/${wokrspaceId}/flows/${id}`;
};

export const linkToSubmission = (wokrspaceId, campaignId, uid) => {
  return `/w/${wokrspaceId}/campaigns/${campaignId}/submissions/${uid}`;
};

export const linkToUserSubmission = (campaignId, submissionId) => {
  return `/interview/${campaignId}/${submissionId}`;
};

export const linkToHubStart = () => {
  return "/hub/start";
};
export const linkToHubModels = () => {
  return "/hub/models";
};
export const linkToHubModel = (handle) => {
  return "/hub/models/" + handle;
};

export const linkToHubModelDetails = (handle) => {
  return "/hub/models/" + handle;
};

export const linkToHubJobDetails = (handle, jobId) => {
  return "/hub/models/" + handle + "/jobs/" + jobId;
};

export const linkToOther = () => {
  return "/other";
};

export const linkToChatbotList = () => {
  //return "/ai/chatbots";
  return "/";
};

export const linkToChatbot = (id, tabId) => {
  return "/ai/chatbots/" + id + "/" + tabId;
};
