import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Message from "../../../i18n/Message";

import CreateChatDialog from "./CreateChatDialog";

import "./ChatListPage.scss";
import { chatSelectors } from "../store/chatReducer";
import { loadChatFolder, loadChatList } from "../store/chatActionCreators";
import ChatListItem from "./item/ChatListItem";
import Spinner from "../../../assets/icons/Spinner";
import FolderPlusIcon from "../../../assets/icons/FolderPlusIcon";
import CreateFolderDialog from "./CreateFolderDialog";

import ChevronLeftIcon from "../../../assets/icons/ChevronLeftIcon";
import ChatActions from "../store/chatActions";
import label from "../../../i18n/label";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const ChatListPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const nav = useNavigate();
  const { folderId } = useParams();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  let chats = useSelector(chatSelectors.getList);
  const listFolderId = useSelector(chatSelectors.getListFolderId);
  const counter = useSelector(chatSelectors.getListCounter);
  const folder = useSelector(chatSelectors.getFolder);
  const [showCreate, setShowCreate] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      const onFinished = () => setLoading(false);
      dispatch(
        loadChatList(
          intl,
          workspaceId,
          folderId,
          keyword,
          onFinished,
          onFinished
        )
      );
    }
  }, [dispatch, intl, workspaceId, counter, folderId, keyword, setLoading]);

  useEffect(() => {
    if (workspaceId && folderId) {
      dispatch(loadChatFolder(intl, workspaceId, folderId));
    }
    if (workspaceId && !folderId) {
      dispatch({
        type: ChatActions.FOLDER_LOADED,
        folder: { chat: null },
      });
    }
  }, [dispatch, intl, workspaceId, folderId]);
  /*
  const keyList = useSelector(apiKeySelectors.getList);
  const listCounter = useSelector(apiKeySelectors.getListCounter);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadApiKeys(intl, workspaceId));
    }
  }, [dispatch, intl, workspaceId, listCounter]);
  */
  console.log("Loaded chats", chats);
  if (chats && folderId && folderId !== listFolderId) {
    chats = null;
  }

  return (
    <>
      <div className="chat_list_main">
        <div className="content_header">
          <div>
            <div className="breadcrumbs">
              {folder && (
                <>
                  <div
                    onClick={() =>
                      nav(
                        folder.parentFolderUid
                          ? linkBuilder.linkToChatFolder(folder.parentFolderUid)
                          : linkBuilder.linkToChats()
                      )
                    }
                  >
                    <ChevronLeftIcon
                      size={16}
                      strokeWidth="2"
                      color="rgb(103, 116, 142)"
                    />
                    <Message
                      id={
                        folder.parentFolderUid
                          ? "back_to_folder"
                          : "back_to_chats"
                      }
                    />
                  </div>
                </>
              )}
              {!folder && <>&nbsp;</>}
            </div>
            <h1>
              {folder && folder.name}
              {!folder && <Message id="chats_title" />}
            </h1>
          </div>

          <div className="toolbar_actions">
            <div className="action_buttons">
              <button
                className="btn bg-gradient-primary"
                onClick={() => setShowCreate(true)}
              >
                <PlusIcon size="18" color="#fff" />
                <Message id="create_chat" />
              </button>
              <button
                type="submit"
                className="btn btn-outline-dark "
                onClick={() => setShowCreateFolder(true)}
              >
                <FolderPlusIcon color="#344767" size="18" />
                <Message id="new_folder" />
              </button>
            </div>
            <div className="right_panel">
              {loading && (
                <div className="loading_container">
                  <Spinner />
                </div>
              )}

              <input
                className="form-control"
                type="text"
                required
                maxLength="255"
                placeholder={label(intl, "chat_search_placeholder")}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            </div>
          </div>
        </div>
        <div className="chat_list_tiles">
          {!chats && <Spinner />}
          {chats &&
            chats.map((item) => <ChatListItem key={item.uid} chat={item} />)}
        </div>
      </div>
      {showCreate && <CreateChatDialog onClose={() => setShowCreate(false)} />}
      {showCreateFolder && (
        <CreateFolderDialog onClose={() => setShowCreateFolder(false)} />
      )}
    </>
  );
};

export default ChatListPage;
