import { useSelector } from "react-redux";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";
import "./ReplyErrorState.scss";
import { chatSelectors } from "../store/chatReducer";

const ReplyErrorState = ({ replyError }) => {
  return (
    <div className={"reply_error_state "}>
      <div className="avatar">
        <div className="openai_avatar">
          <LogoOpenAI color="#fff" />
        </div>
      </div>
      <div className="message_item">
        <div>{replyError.message}</div>
      </div>
    </div>
  );
};

export default ReplyErrorState;
