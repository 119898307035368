import React, { useState } from "react";
import Spinner from "../../assets/icons/Spinner";
import backend from "../../backend/backend";
import Message from "../../i18n/Message";
import ModalDialog from "./ModalDialog";

import "./FastspringIntegration.scss";

window.isCreditsOrder = false;

window.onPaymentFinished = (orderRef) => {
  if (orderRef && orderRef.id) {
    console.log("order: ", orderRef);
    window.fastspring.builder.reset();
    //window.fsShowDialog();
    const prom = window.isCreditsOrder
      ? backend.processCreditsOrder
      : backend.processOrder;

    prom(orderRef.id, orderRef.reference)
      .then(() => {
        //window.fsHideDialog();
        window.location.reload();
      })
      .catch((err) => {
        console.log("Failed to process the order: ", orderRef, err);
        window.fsHideDialog && window.fsHideDialog();
        window.isCreditsOrder = false;
        //TODO: toast
      });
    /*
         var template = "<@spring.message "page.pricing.thank_you" />";
         
         var text = template.replace(/order_number/g, orderRef.reference);         
         $('#order_thanks').html(text);
         $('#order_thanks').show();
         */
  } else {
    window.fsShowError();
    //canceled checkout
    //console.log("no order ID");
  }
};

window.showPaymentWindow = (user, productPath) => {
  //console.log("User: ", user);
  console.log("productPath: ", productPath);
  var productList = [{ quantity: 1, path: productPath }];
  var session = { products: productList, reset: true, checkout: true };
  window.gAiknUser = user;
  window.gAiknProd = productPath;
  window.fastspring.builder.recognize({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  });
  window.fastspring.builder.push(session);
};

const FastspringIntegration = () => {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  window.fsShowDialog = () => setShow(true);
  window.fsHideDialog = () => setShow(false);
  window.fsShowError = () => setShowError(true);
  return (
    <>
      {show && (
        <ModalDialog onClose={() => setShow(false)}>
          <div className="spinner_container">
            <Spinner />
          </div>
        </ModalDialog>
      )}
      {showError && (
        <ModalDialog onClose={() => setShowError(false)}>
          <div className="fs_error_text error_alert mtop-24">
            <Message id="payment_problem" />
          </div>
          <div className="flex-center">
            <button
              onClick={() =>
                window.showPaymentWindow(window.gAiknUser, window.gAiknProd)
              }
              className="btn btn-dark"
            >
              <Message id="add_card" />
            </button>
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default FastspringIntegration;
