import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";
import { loadEndpoints } from "../store/endpointActionCreators";
import EditEndpointDialog from "../edit/EditEndpointDialog";
import EndpointTile from "./EndpointTile";

import "./EndpointListPage.scss";
import { endpointSelectors } from "../store/endpointReducer";
import { loadPrompts } from "../../prompt/store/promptActionCreators";

const EndpointListPage = () => {
  const [showCreate, setShowCreate] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();
  const endpointList = useSelector(endpointSelectors.getList);
  const listCounter = useSelector(endpointSelectors.getListCounter);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadEndpoints(intl, workspaceId));
    }
  }, [dispatch, intl, workspaceId, listCounter]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadPrompts(intl, workspaceId));
    }
  }, [dispatch, intl, workspaceId]);

  return (
    <WorkspaceLayout contentPanelClassName="campaign_list_main">
      <div className="body_content card">
        <div className="content_header">
          <h1>
            <Message id="endpoints_title" />
          </h1>
          <div>
            <button
              className="btn bg-gradient-primary"
              onClick={() => setShowCreate(true)}
            >
              <PlusIcon size="18" color="#fff" />
              <Message id="create_endpoint" />
            </button>
          </div>
        </div>
        <div className="campaign_tiles">
          {!endpointList && <Spinner />}
          {endpointList &&
            endpointList.map((item) => (
              <EndpointTile key={item.uid} endpoint={item} />
            ))}
        </div>
      </div>
      {showCreate && (
        <EditEndpointDialog onClose={() => setShowCreate(false)} />
      )}
    </WorkspaceLayout>
  );
};

export default EndpointListPage;
