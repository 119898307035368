import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { authSelectors } from "../../auth/store/authReducer";

import FastspringIntegration from "../FastspringIntegration";

import { useIntl } from "react-intl";
import { workspaceSelectors } from "./store/workspaceReducer";
import { loadWorkspaces } from "./store/workspaceActionCreators";
import SettingsIcon from "../../../assets/icons/SettignsIcon";
import ArrowOutIcon from "../../../assets/icons/ArrowOutIcon";

import UserCircleIcon from "../../../assets/icons/UserCircleIcon";
import Message from "../../../i18n/Message";

import TrialExpiredDialog from "../trial-expired/TrialExpiredDialog";
import { logout } from "../../auth/store/actionCreators";

import {
  linkToAccountSettings,
  linkToApiKeys,
  linkToChats,
  linkToHubStart,
  linkToOther,
  linkToSubscription,
  linkToTeam,
} from "../../../routes";

import "./GlobalLayout.scss";
import GridIcon from "../../../assets/icons/GridIcon";
import { isTeamAdmin } from "../../../backend/memberRole";
import UsersIcon from "../../../assets/icons/UsersIcon";
import CreditCardIcon from "../../../assets/icons/CreditCardIcon";

const GlobalLayout = ({ children, contentPanelClassName = "" }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const intl = useIntl();
  const workspaces = useSelector(workspaceSelectors.getWorkspaces);
  const user = useSelector(authSelectors.getUser);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const headerMenuRef = useRef(null);
  const submenuRef = useRef(null);
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(
    window.innerWidth < 1505
  );
  const [wasCollapsed, setWasCollapsed] = useState(false);
  const [showTrialExpired, setShowTrialExpired] = useState(false);

  useEffect(() => {
    if (!workspaces) {
      dispatch(loadWorkspaces(intl));
    }

    //navigate(linkToApiKeys(workspaces[0].uid));
  }, [workspaces, dispatch, navigate, intl]);

  useEffect(() => {
    if (showHeaderMenu) {
      const onClickAway = (e) => {
        if (
          submenuRef.current &&
          e.target !== submenuRef.current &&
          !submenuRef.current.contains(e.target)
        ) {
          setShowHeaderMenu(false);
        }
      };
      document.addEventListener("click", onClickAway);
      return () => document.removeEventListener("click", onClickAway);
    }
  }, [showHeaderMenu, submenuRef]);

  const trialDaysLeft = user.subscription.daysLeft;

  useEffect(() => {
    if (trialDaysLeft === 0) {
      setShowTrialExpired(true);
    }
  }, [trialDaysLeft, setShowTrialExpired]);

  let trialSummaryClass = "";
  let trialButtonClass = "app-btn-green";
  if (trialDaysLeft < 3) {
    trialSummaryClass = "expired";
  } else if (trialDaysLeft < 8) {
    trialSummaryClass = "soon";
  }

  const onLeftPanelMouseOver = () => {
    if (leftPanelCollapsed) {
      setLeftPanelCollapsed(false);
      setWasCollapsed(true);
    }
  };

  const onLeftPanelMouseLeave = () => {
    if (wasCollapsed) {
      setWasCollapsed(false);
      setLeftPanelCollapsed(true);
    }
  };

  const LogoWrapper = ({ children }) => <a href={"/app"}>{children}</a>;

  return (
    <div className="workspace_layout">
      <div
        className={
          "main_layout " + (leftPanelCollapsed ? "left_menu_collapsed" : "")
        }
      >
        <header>
          <div className="logo">
            <LogoWrapper>
              <img src="/images/promptleo-logo-grey.svg" width="190" />
            </LogoWrapper>
          </div>
          {
            <>
              <div className="header_menu" ref={headerMenuRef}>
                {user.subscription.plan !== "free" &&
                  (user.subscription.trialInProgress ||
                    user.subscription.daysLeft === 0) && (
                    <div className="item hidden-xs">
                      <div className={"trial_summary " + trialSummaryClass}>
                        <div className="trial_text">
                          <span className="days_highlighted">
                            {trialDaysLeft >= 1 ? (
                              <Message
                                id="trial_days_left"
                                values={{ count: trialDaysLeft }}
                              />
                            ) : (
                              <Message id="trial_expired" />
                            )}
                          </span>
                          .
                          <Message id="trial_summary_text" />
                        </div>
                        <div className="trial_button">
                          <button
                            onClick={() =>
                              window.showPaymentWindow(
                                user,
                                user.subscription.planCode
                              )
                            }
                            className={
                              "btn btn-sm btn-outline-primary  " +
                              trialButtonClass
                            }
                          >
                            <Message id="add_card" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="item">
                  <div
                    className="entry"
                    onClick={() => navigate(linkToHubStart())}
                  >
                    <Message id="hub_menu_api" />
                  </div>
                </div>
                <div className="item">
                  <div
                    className="entry"
                    onClick={() => navigate(linkToOther())}
                  >
                    <Message id="hub_menu_workspace" />
                  </div>
                </div>
                <div className="item">
                  <div
                    className="entry"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowHeaderMenu(!showHeaderMenu);
                    }}
                  >
                    <UserCircleIcon color="#344767" strokeWidth="1.5" />
                    <span className="hidden-xs">{user ? user.name : ""}</span>
                  </div>
                  <div className="submenu_container">
                    {showHeaderMenu && (
                      <div className="submenu" ref={submenuRef}>
                        <div onClick={() => navigate(linkToAccountSettings())}>
                          <SettingsIcon color="#333" size="20" />{" "}
                          <Message id="account" />
                        </div>
                        <div onClick={() => navigate(linkToSubscription())}>
                          <CreditCardIcon color="#333" size="20" />{" "}
                          <Message id="plan" />
                        </div>

                        {isTeamAdmin(user) && (
                          <>
                            <div onClick={() => navigate(linkToTeam())}>
                              <UsersIcon color="#333" size="20" />{" "}
                              <Message id="users" />
                            </div>
                          </>
                        )}

                        <div onClick={() => dispatch(logout())}>
                          <ArrowOutIcon color="#333" size="20" />{" "}
                          <Message id="menu_logout" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="break"></div>
              <div className="mobile_trial visible-xs-flex">
                {/*user.subscription.plan !== "free" &&
                  (user.subscription.trialInProgress ||
                    user.subscription.daysLeft === 0) && (
                    <div className="item">
                      <div className={"trial_summary " + trialSummaryClass}>
                        <div className="trial_text">
                          <span className="days_highlighted">
                            {trialDaysLeft >= 1 ? (
                              <Message
                                id="trial_days_left"
                                values={{ count: trialDaysLeft }}
                              />
                            ) : (
                              <Message id="trial_expired" />
                            )}
                          </span>
                          .
                          <Message id="trial_summary_text" />
                        </div>
                        <div className="trial_button">
                          <button
                            onClick={() =>
                              window.showPaymentWindow(
                                user,
                                user.subscription.planCode
                              )
                            }
                            className={"app-btn " + trialButtonClass}
                          >
                            <Message id="add_card" />
                          </button>
                        </div>
                      </div>
                    </div>
                          )*/}
              </div>
            </>
          }
        </header>
        <div className="left_panel_mobile visible-xs"></div>
        <div
          className={
            "left_panel hidden-xs " + (leftPanelCollapsed ? "collapsed" : "")
          }
          onMouseOver={onLeftPanelMouseOver}
          onMouseLeave={onLeftPanelMouseLeave}
        >
          <hr className="horizontal dark"></hr>
          <div className="sidebar-items">
            <NavLink
              to={linkToAccountSettings()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <SettingsIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="account" />
              </span>
            </NavLink>
            <NavLink
              to={linkToSubscription()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <CreditCardIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="subscription" />
              </span>
            </NavLink>
            <NavLink
              to={linkToTeam()}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <UsersIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="users" />
              </span>
            </NavLink>
            <NavLink
              to={linkToChats(workspaces?.[0]?.uid)}
              className={(navData) => (navData.isActive ? "selected" : "")}
            >
              <div className="icon_wrapper">
                <GridIcon color="#67748e" size="18" />
              </div>
              <span>
                <Message id="menu_workspaces" />
              </span>
            </NavLink>
          </div>
          {/*<div className="collapse_control">
            {leftPanelCollapsed || wasCollapsed ? (
              <button
                type="button"
                className="no-btn"
                onClick={() => {
                  setWasCollapsed(false);
                  setLeftPanelCollapsed(false);
                }}
              >
                <ChevronRightIcon color="#383838" />
                <span>
                  <Message id="menu_expand" />
                </span>
              </button>
            ) : (
              <button type="button" className="no-btn" onClick={() => setLeftPanelCollapsed(true)}>
                <ChevronLeftIcon color="#383838" />
                <Message id="collapse" />
              </button>
            )}
            </div>*/}
        </div>
        <div className={`content_panel ${contentPanelClassName}`}>
          {children}
        </div>
      </div>
      <ToastContainer
        closeButton={true}
        className="notification-box"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <FastspringIntegration />
      {showTrialExpired && <TrialExpiredDialog user={user} />}
    </div>
  );
};

export default GlobalLayout;
