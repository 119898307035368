const FormActions = {
  LOADED: "form/loaded",
  UPDATED: "form/updated",
  LIST_LOADED: "forms/loaded",
  LIST_CHANGED: "forms/changed",
  SUBMISSIONS_LOADED: "form-submissions/loaded",
  SUBMISSIONS_CHANGED: "form-submissions/changed",
  RESPONSE_LOADED: "form-response/loaded",
  SUBMISSION_LOADED: "form-submission/loaded",
};

export default FormActions;
