import produce from "immer";
import reducerUtils from "../../../../store/reducerUtils";

import SharedFormActions from "./SharedFormActions";

const initialState = {
  data: {
    form: null,
    promptVersion: null,
    formResponse: null,
    formSubmission: null,
  },
};

export const sharedFormSelectors = {
  getForm: (state) => state.sharedForm.data.form,
  getPromptVersion: (state) => state.sharedForm.data.promptVersion,
  getFormResponse: (state) => state.sharedForm.data.formResponse,
  getFormSubmission: (state) => state.sharedForm.data.formSubmission,
};

const onFormLoaded = produce((draft, action) => {
  const obj = action.form;
  draft.data.form = obj;
});

const onFormResponseLoaded = produce((draft, action) => {
  const obj = action.formResponse;
  draft.data.formResponse = obj;
});

const onFormSubmissionLoaded = produce((draft, action) => {
  const obj = action.formSubmission;
  draft.data.formSubmission = obj;
});

const onPromptVersionLoaded = produce((draft, action) => {
  const obj = action.version;
  draft.data.promptVersion = obj;
});

const handlers = {
  [SharedFormActions.LOADED]: onFormLoaded,
  [SharedFormActions.RESPONSE_LOADED]: onFormResponseLoaded,
  [SharedFormActions.SUBMISSION_LOADED]: onFormSubmissionLoaded,
  [SharedFormActions.PROMPT_VERSION_LOADED]: onPromptVersionLoaded,
};

const sharedFormReducer = reducerUtils.createReducer(initialState, handlers);

export default sharedFormReducer;
