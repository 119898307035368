import { createContext, useContext } from "react";
const defaultValue = {}; // LinkBuilder class instance

const LinkBuilderContext = createContext(defaultValue);

export const useLinkBuilder = () => {
  return useContext(LinkBuilderContext);
};

export default LinkBuilderContext;
