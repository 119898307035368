import { useSelector } from "react-redux";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import Spinner from "../../../assets/icons/Spinner";
import MessageTextContainer from "../../../components/chats/details/MessageTextContainer";
import Message from "../../../i18n/Message";
import { chatbotWidgetSelectors } from "./store/chatbotWidgetReducer";

import "./ChatbotReplyLoadingState.scss";
import { Bot } from "lucide-react";

const ChatbotReplyLoadingState = () => {
  const replyPart = useSelector(chatbotWidgetSelectors.getReplyPart);

  return (
    <div className={"chatbot_reply_loading_state "}>
      <div className="avatar">
        <div className="openai_avatar">
          <Bot color="#fff" />
        </div>
      </div>
      <div className="message_item">
        <div>
          <MessageTextContainer>{replyPart}</MessageTextContainer>
        </div>
        <div className="reply_spinner">
          <Message id="widget_reply_loading_state" /> <Spinner />
        </div>
      </div>
    </div>
  );
};

export default ChatbotReplyLoadingState;
