import { Bot } from "lucide-react";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import "./ChatbotReplyErrorState.scss";

const ChatbotReplyErrorState = ({ replyError }) => {
  return (
    <div className={"chatbot_reply_error_state "}>
      <div className="avatar">
        <div className="openai_avatar">
          <Bot color="#fff" />
        </div>
      </div>
      <div className="message_item">
        <div>{replyError.message}</div>
      </div>
    </div>
  );
};

export default ChatbotReplyErrorState;
