import React from "react";
import { FormattedMessage as Message, useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import label from "../../i18n/label";
import CopyIcon from "../../assets/icons/CopyIcon";
import Notify from "../shared/Notify";

import "./SettingsAPIKey.scss";

const SettingsAPIKey = ({ user }) => {
  const intl = useIntl();

  return (
    <div className="api_key_panel">
      <h3>
        <Message id="account_settings_api_key" />
      </h3>

      <div className="api_key">{user.apiToken}</div>

      <button
        type="button"
        className="btn btn-outline-dark btn-sm"
        onClick={() => {
          copy(user.apiToken);
          Notify.success(label(intl, "copy_success"));
        }}
      >
        <CopyIcon color="rgb(52, 71, 103)" />
        <Message id="copy" />
      </button>
    </div>
  );
};
export default SettingsAPIKey;
