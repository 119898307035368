import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import { cloneDeep } from "lodash";
import PromptActions from "./promptActions";

const initialState = {
  data: {
    prompts: null,
    promptsCounter: 0,

    prompt: null,
    versions: null,
    versionsCounter: 0,
    version: null,
  },
};

export const promptSelectors = {
  getList: (state) => state.prompts.data.prompts,
  getListCounter: (state) => state.prompts.data.promptsCounter,
  getPrompt: (state) => state.prompts.data.prompt,
  getVersions: (state) => state.prompts.data.versions,
  getVersionsCounter: (state) => state.prompts.data.versionsCounter,
  getVersion: (uid) => (state) =>
    state.prompts.data.versions?.find((item) => item.uid === uid),
  getCurrentVersion: (state) => state.prompts.data.version,
};

const onLoadList = produce((draft, action) => {
  draft.data.prompts = action.prompts;
});

const onListChanged = produce((draft) => {
  draft.data.promptsCounter++;
});

const onPromptLoaded = produce((draft, action) => {
  const obj = action.prompt;
  /*
  const body = JSON.parse(obj.body);
  obj.body = body;
  */
  draft.data.prompt = obj;
});

const onPromptVersionsLoaded = produce((draft, action) => {
  draft.data.versions = action.versions;
});

const onPromptVersionsChanged = produce((draft) => {
  draft.data.versionsCounter++;
});

const onPromptVersionLoaded = produce((draft, action) => {
  draft.data.version = action.version;
});

/*
const onSubmissionLoaded = produce((draft, action) => {
  if (typeof action.submission.body === "string") {
    const obj = cloneDeep(action.submission);
    const body = JSON.parse(obj.body);
    obj.body = body;
    draft.data.submission = obj;
  } else {
    const obj = cloneDeep(action.submission);
    draft.data.submission = obj;
  }
});
*/

const handlers = {
  [PromptActions.LIST_LOADED]: onLoadList,
  [PromptActions.LIST_CHANGED]: onListChanged,
  [PromptActions.LOADED]: onPromptLoaded,
  [PromptActions.UPDATED]: onPromptLoaded,
  [PromptActions.VERSIONS_LOADED]: onPromptVersionsLoaded,
  [PromptActions.VERSIONS_CHANGED]: onPromptVersionsChanged,
  [PromptActions.VERSION_LOADED]: onPromptVersionLoaded,
};

const campaignsReducer = reducerUtils.createReducer(initialState, handlers);

export default campaignsReducer;
