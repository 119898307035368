import { createContext, useContext } from "react";
const defaultValue = {}; // LinkBuilder class instance

const WorkspaceContext = createContext(defaultValue);

export const useWorkspaceId = () => {
  return useContext(WorkspaceContext).uid;
};

export default WorkspaceContext;
