import WorkspacePageWrapper from "../../workspace/WorkspacePageWrapper";
import ChatPage from "./ChatPage";

const ChatPageWrapper = ({ area }) => {
  return (
    <WorkspacePageWrapper contentClassName="chat_details_main" area={area}>
      <ChatPage />
    </WorkspacePageWrapper>
  );
};

export default ChatPageWrapper;
