import dayjs from "dayjs";

import Message from "../../../i18n/Message";
import "./HubModelJobs.scss";
import { cropText, formatDurationMs } from "../utils/hubUtils";
import { useNavigate } from "react-router-dom";
import { linkToHubJobDetails } from "../../../routes";
import ActivityIcon from "../../../assets/icons/ActivityIcon";

const HubModelJobs = ({ model, jobs }) => {
  const navigate = useNavigate();
  return (
    <div className="model_job_list card">
      <div className="job_list_title">
        <ActivityIcon color="#344767" />
        <Message id="hub_recent_jobs" />
      </div>
      {!jobs ||
        (jobs.length === 0 && (
          <div className="jobs_help">
            <Message id="hub_model_jobs_help" />
          </div>
        ))}
      {jobs && jobs.length > 0 && (
        <div className="recent_jobs_for_model">
          <div className="recent_jobs_list">
            <div className="job_list_headers">
              <div className="">
                <Message id="hub_recent_jobs_header_input" />
              </div>
              <div className="">
                <Message id="hub_recent_jobs_header_status" />
              </div>
              <div className="">
                <Message id="hub_recent_jobs_header_queued" />
              </div>
              <div className="">
                <Message id="hub_recent_jobs_header_running" />
              </div>
              <div className="">
                <Message id="hub_recent_jobs_header_created" />
              </div>
            </div>
            {jobs.map((job) => {
              let statusClass = "bg-gradient-secondary";
              if (job.status === "FINISHED") {
                statusClass = "bg-dark";
              } else if (job.status === "RUNNING") {
                statusClass = "bg-warning";
              }
              return (
                <div
                  className="job_row border-radius-lg"
                  onClick={() =>
                    navigate(linkToHubJobDetails(model.handle, job.uid))
                  }
                >
                  <div className="input_column" title={job.input}>
                    {cropText(job.input, 320)}
                  </div>
                  <div className="status">
                    <span className={"badge badge-sm " + statusClass}>
                      {job.status}
                    </span>
                  </div>
                  <div className="timing">{formatDurationMs(job.queuedMs)}</div>
                  <div className="timing">
                    {formatDurationMs(job.runningMs)}
                  </div>
                  <div className="created">
                    {dayjs.utc(job.dateCreated).fromNow()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HubModelJobs;
