import AuthActions from "./actionTypes";
import reducerUtils from "../../../store/reducerUtils";
import produce from "immer";
import dayjs from "dayjs";

const initialState = {
  user: null,
  loadedAt: null,
};

export const authSelectors = {
  getUser: (state) => state.auth.user,
  getMinutesSinceLoaded: (state) => {
    const time = state.auth.loadedAt;
    if(time) {
      return dayjs().diff(time, "minute");
    }
    return null;
  }
};

const onLogin = produce((draft, action) => {
  draft.user = action.user;
  draft.loadedAt = dayjs();
});

const handlers = {
  [AuthActions.LOGGED_IN]: onLogin,
};

const authReducer = reducerUtils.createReducer(initialState, handlers);

export default authReducer;
