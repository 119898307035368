import Message from "../../../i18n/Message";
import "./ZeroState.scss";

const ZeroState = ({ icon, textKey, buttonKey, onClick }) => {
  return (
    <div className="zero_state_wrapper">
      <div className="zero_state_icon_wrapper">
        {/*<FlowsIcon color="#67748e" size="36" />*/}
        {icon}
      </div>
      <div className="zero_state_text">
        <Message id={textKey} />
      </div>
      {onClick && (
        <div className="zero_state_button">
          <button className="btn btn-outline-dark" onClick={onClick}>
            <Message id={buttonKey} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ZeroState;
