import React, { useState } from "react";

import ModalDialog from "../../shared/ModalDialog";

import "./CreateChatbotDialog.scss";

import CreateFirstStep from "./CreateFirstStep";
import CreateTrainStep from "./CreateTrainStep";

const CreateChatbotDialog = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [chatbot, setChatbot] = useState(null);

  const onFirstStepFinished = (newChatbot) => {
    console.log("onFirstStepFinished", newChatbot);
    setChatbot(newChatbot);
    setStep(2);
  };
  return (
    <ModalDialog
      onClose={onClose}
      className="create_campaign_dialog create_chatbot_dialog"
    >
      {step === 1 && (
        <CreateFirstStep onClose={onClose} onNext={onFirstStepFinished} />
      )}
      {step === 2 && <CreateTrainStep chatbot={chatbot} onClose={onClose} />}
    </ModalDialog>
  );
};

export default CreateChatbotDialog;
