import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import HubActions from "./HubActions";

export const loadAccountBalance = (intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getAccountCredits()
      .then((data) => {
        console.log("loaded credits", data);
        dispatch({
          type: HubActions.ACCOUNT_BALANCE_LOADED,
          data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadJobs = (intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getHubJobs()
      .then((data) => {
        console.log("loaded hub jobs", data);
        dispatch({
          type: HubActions.JOBS_LOADED,
          jobs: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadJobsByModel = (model, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getHubJobsByModel(model)
      .then((data) => {
        console.log("loaded hub jobs", data);
        dispatch({
          type: HubActions.MODEL_JOBS_LOADED,
          jobs: data,
          modelId: model,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadJob = (jobId, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getHubJob(jobId)
      .then((data) => {
        console.log("loaded hub job", data);
        dispatch({
          type: HubActions.JOB_DETAILS_LOADED,
          job: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load forms", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
