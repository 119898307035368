import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import Select from "react-select";

import ModalDialog from "../../shared/ModalDialog";
import { createApiKey, updateApiKey } from "../store/apiKeyActionCreators";

import "./CreateApiKeyDialog.scss";
import apiKeyProvider from "../model/apiKeyProvider";

const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#f7f7ff", // for option hover bg-color
    primary: "#644EF3", // for selected option bg-color
    neutral10: "#644EF3", // for tags bg-color
    neutral20: "#e1e1ed", // for input border-color
    neutral30: "#e1e1ed", // for input hover border-color
  },
});

const providerOptions = [
  { value: apiKeyProvider.OPENAI_CHATGPT, label: "OpenAI: ChatGPT" },
  { value: apiKeyProvider.OPENAI_GPT3, label: "OpenAI: GPT 3.5" },
  { value: apiKeyProvider.OPENAI_GPT4, label: "OpenAI: GPT 4" },
];

const findVendorOption = (vendorId) => {
  return providerOptions.find((item) => item.value === vendorId);
};

const CreateApiKeyDialog = ({ apiKey, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(apiKey?.name ?? "");
  const [content, setContent] = useState(apiKey?.content ?? "");

  const [formElem, setFormElem] = useState(null);
  const [nameInput, setNameInput] = useState(null);
  const [contentInput, setContentInput] = useState(null);
  const [provider, setProvider] = useState(
    !apiKey ? providerOptions[0] : findVendorOption(apiKey.vendor)
  );

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  const onCreate = () => {
    const valid = nameInput.checkValidity() && contentInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      onClose();
    };
    const newObject = {
      name,
      content,
      vendor: provider.value,
    };

    if (apiKey) {
      dispatch(
        updateApiKey(intl, workspaceId, apiKey.uid, newObject, onSuccess, () =>
          setLoading(false)
        )
      );
    } else {
      dispatch(
        createApiKey(intl, newObject, workspaceId, onSuccess, () =>
          setLoading(false)
        )
      );
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_api_key_dialog">
      <div className="modal_title">
        <Message id="create_api_key_dialog_title" />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="dialog_body">
          <Select
            theme={selectThemeColors}
            className="react-select"
            classNamePrefix="select"
            value={provider}
            name="teamSize"
            options={providerOptions}
            onChange={(val) => setProvider(val)}
            isClearable={false}
            isSearchable={false}
          />

          <input
            className="form-control"
            type="text"
            ref={(val) => setNameInput(val)}
            required
            maxLength="120"
            placeholder={label(intl, "api_key_name_placeholder")}
            onChange={(e) => setName(e.target.value)}
            value={name}
            readOnly={loading}
          />
          <textarea
            ref={(val) => setContentInput(val)}
            autoFocus={true}
            rows="3"
            onChange={(e) => setContent(e.target.value)}
            value={content}
            required
            placeholder={label(intl, "api_key_content_placeholder")}
            className="form-control"
          ></textarea>
        </div>

        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id="create_prompt_button" />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default CreateApiKeyDialog;
