import SettingsIcon from "../../../assets/icons/SettignsIcon";
import Message from "../../../i18n/Message";
import "./FirstMessage.scss";

const FirstMessage = ({ chatbot }) => {
  return (
    <div className="first_message">
      <div className="avatar">
        <div className="avatar_wrapper">
          <SettingsIcon color="#555" />
        </div>
      </div>
      <div className="message_body">
        <div>
          <Message
            id="model_message_model_name"
            values={{
              name: "Model name",
            }}
          />
        </div>
        <div className="system_prompt">
          <Message id="model_message_system_prompt" />: {chatbot.systemPrompt}
        </div>
      </div>
    </div>
  );
};

export default FirstMessage;
