import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";

import "./FlowCanvas.scss";

const initialNodes = [
  { id: "1", position: { x: 200, y: 0 }, data: { label: "Start with input" } },
  { id: "2", position: { x: 200, y: 100 }, data: { label: "Memory Lookup" } },
  {
    id: "3",
    position: { x: 200, y: 200 },
    data: { label: "Prompt Engineering" },
  },
  { id: "4", position: { x: 200, y: 300 }, data: { label: "ChatGPT" } },
  { id: "5", position: { x: 200, y: 400 }, data: { label: "Memorize" } },
  { id: "7", position: { x: 200, y: 500 }, data: { label: "Output" } },
];
const initialEdges = [
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-3", source: "2", target: "3" },
  { id: "e34", source: "3", target: "4" },
  { id: "e45", source: "4", target: "5" },
  { id: "e57", source: "5", target: "7" },
];

const FlowCanvas = ({ flow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div className="preview_row card">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};

export default FlowCanvas;
