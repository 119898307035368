import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";

import ModalDialog from "../../shared/ModalDialog";

import "./CreateChatDialog.scss";
import modelDescriptors, {
  getDescriptorById,
  modelVendor,
} from "../../../model/model-providers/modelDescriptors";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import backend from "../../../backend/backend";
import Notify from "../../shared/Notify";
import ChatActions from "../store/chatActions";
import { localKeysRegistry } from "../../../model/model-keys/modelKeysRegistry";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";
import LogoAnthropic from "../../../assets/icons/LogoAnthropic";
import LogoAmazon from "../../../assets/icons/LogoAmazon";

const CreateChatDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const nav = useNavigate();
  const { folderId } = useParams();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [modelId, setModelId] = useState(modelDescriptors[0].id);

  const [formElem, setFormElem] = useState(null);
  const [nameInput, setNameInput] = useState(null);

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  const onCreate = async () => {
    const valid = nameInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;

    setLoading(true);

    const onSuccess = () => {
      setLoading(false);
      onClose();
    };

    backend
      .createChat(workspaceId, {
        name,
        modelId,
        folderId,
        systemPrompt:
          "You are a helpful AI assistant. Follow the user's instructions carefully. Respond using markdown.",
      })
      .then((chat) => {
        console.log("Created chat: ", chat);
        dispatch({
          type: ChatActions.LOADED,
          chat,
        });
        onSuccess();
        nav(linkBuilder.linkToChat(chat.chat.uid));
      })
      .catch((err) => {
        console.error("Chat creation failed ", err);
        Notify.error(label(intl, "error_create_chat"));
        setLoading(false);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_chat_dialog">
      <div className="modal_title">
        <Message id={"create_chat_title"} />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="dialog_body">
          <input
            className="form-control"
            type="text"
            ref={(val) => setNameInput(val)}
            required
            maxLength="120"
            placeholder={label(intl, "chat_name_placeholder")}
            onChange={(e) => setName(e.target.value)}
            value={name}
            readOnly={loading}
          />
          <div className="model_selector">
            {modelDescriptors.map((item) => {
              return (
                <div
                  key={item.id}
                  className={
                    "model_item " + (modelId === item.id ? " selected" : "")
                  }
                  onClick={() => setModelId(item.id)}
                >
                  <div className="logo">
                    {item.vendor === modelVendor.OPENAI && (
                      <LogoOpenAI size={32} />
                    )}
                    {item.vendor === modelVendor.ANTHROPIC && (
                      <LogoAnthropic size={32} />
                    )}
                    {item.vendor === modelVendor.AMAZON && (
                      <LogoAmazon size={32} />
                    )}
                  </div>
                  <div className="name_column">
                    <div className="name">{item.name}</div>
                    <div className="context">{item.contextWindow}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id={"create_chat_button"} />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default CreateChatDialog;
