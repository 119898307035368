import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import ChatbotActions from "./chatbotActions";

export const loadChatbotList = (intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getChatbotList()
      .then((data) => {
        console.log("loaded chatbots", data);
        dispatch({
          type: ChatbotActions.CHATBOT_LIST_LOADED,
          chatbots: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chatbots", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadChatbot = (uid, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getChatbot(uid)
      .then((data) => {
        console.log("loaded chatbot", data);
        dispatch({
          type: ChatbotActions.CHATBOT_LOADED,
          chatbot: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chatbot", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const createChatbot = (data, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createChatbot(data)
      .then((data) => {
        console.log("created chatbot", data);
        dispatch({
          type: ChatbotActions.CHATBOT_LOADED,
          chatbot: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create chatbot", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateChatbot = (data, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .updateChatbot(data)
      .then((data) => {
        console.log("updated chatbot", data);
        dispatch({
          type: ChatbotActions.CHATBOT_LOADED,
          chatbot: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update chatbot", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteChatbot = (uid, intl, onSuccess, onError) => {
  return () => {
    backend
      .deleteChatbot(uid)
      .then((data) => {
        console.log("deleted chatbot", data);
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete chatbot", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadChatMessages = (
  chatbotUid,
  chatUid,
  intl,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getChatMessages(chatbotUid, chatUid)
      .then((data) => {
        console.log("loaded chat messages", data);
        dispatch({
          type: ChatbotActions.MESSAGES_LOADED,
          messages: data,
          chatbotUid,
          chatUid,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load chat messages", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadKbpages = (chatbotUid, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getKbpages(chatbotUid)
      .then((data) => {
        console.log("loaded kbpages", data);
        dispatch({
          type: ChatbotActions.KBPAGES_LOADED,
          kbpages: data,
          chatbotId: chatbotUid,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load kbpages", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
