import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import AccountActions from "./actionTypes";

export const changePassword =
  (intl, current, next, onSuccess, onError) => () => {
    backend
      .changePassword(current, next)
      .then((data) => {
        onSuccess && onSuccess();
      })
      .catch((err) => {
        console.error("Failed to change a password", err);
        onError && onError(err);
      });
  };

export const loadAccountOrders = (intl) => (dispatch) => {
  backend
    .getOrders()
    .then((data) => {
      dispatch({
        type: AccountActions.ORDERS_LOADED,
        orders: data,
      });
    })
    .catch((err) => {
      console.error("Failed to change a password", err);
      Notify.error(label(intl, "error_unexpected"));
    });
};

export const upgradeSubscription =
  (intl, planKey, onSuccess, onError) => () => {
    backend
      .upgradeSubscription(planKey)
      .then((data) => {
        console.log("Upgrade resp", data);
        onSuccess && onSuccess();
      })
      .catch((err) => {
        console.error("Failed to upgrade subscription", err);
        onError && onError();
        Notify.error(label(intl, "error_unexpected"));
      });
  };

export const loadInvitation = (intl, uid) => (dispatch) => {
  backend
    .getInvitation(uid)
    .then((data) => {
      dispatch({
        type: AccountActions.INVITATION_LOADED,
        invitation: data,
      });
    })
    .catch((err) => {
      console.error("Failed to load an invitation", err);
      Notify.error(label(intl, "error_unexpected"));
    });
};
