import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import ChatbotActions from "./chatbotActions";

const initialState = {
  data: {
    // list
    chatbots: null,
    // details
    chatbot: null,

    chatMessages: null,
    chatMessagesChatUid: null,

    kbpages: null,
    kbpagesChatbotId: null,
  },
};

export const chatbotSelectors = {
  getChatbots: (state) => state.chatbot.data.chatbots,
  getChatbot: (state) => state.chatbot.data.chatbot,
  getChatMessages: (state) => state.chatbot.data.chatMessages,
  getChatMessagesChatUid: (state) => state.chatbot.data.chatMessagesChatUid,
  getKbpages: (state) => state.chatbot.data.kbpages,
  getKbpagesChatbotId: (state) => state.chatbot.data.kbpagesChatbotId,
};

const onChatbotsLoaded = produce((draft, action) => {
  draft.data.chatbots = action.chatbots;
});

const onChatbotLoaded = produce((draft, action) => {
  draft.data.chatbot = action.chatbot;
});

const onChatMessagesLoaded = produce((draft, action) => {
  draft.data.chatMessages = action.messages;
  draft.data.chatMessagesChatUid = action.chatUid;
});

const onKbpagesLoaded = produce((draft, action) => {
  draft.data.kbpages = action.kbpages;
  draft.data.kbpagesChatbotId = action.chatbotId;
});

const handlers = {
  [ChatbotActions.CHATBOT_LIST_LOADED]: onChatbotsLoaded,
  [ChatbotActions.CHATBOT_LOADED]: onChatbotLoaded,
  [ChatbotActions.MESSAGES_LOADED]: onChatMessagesLoaded,
  [ChatbotActions.KBPAGES_LOADED]: onKbpagesLoaded,
};

const chatbotReducer = reducerUtils.createReducer(initialState, handlers);

export default chatbotReducer;
