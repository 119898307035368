import FolderIcon from "../../../../assets/icons/FolderIcon";
import "./FolderItem.scss";

import { useNavigate } from "react-router";
import { shortenName } from "./nameUtils";
import { useLinkBuilder } from "../../../shared/link-builder/linkBuilderContext";

const FolderItem = ({ chat }) => {
  const nav = useNavigate();
  const linkBuilder = useLinkBuilder();
  return (
    <div
      className="chat_list_item"
      onClick={() => nav(linkBuilder.linkToChatFolder(chat.uid))}
    >
      <div className="item_icon">
        <FolderIcon color="rgb(145,155,173)" size={48} strokeWidth="1.5" />
        {/*<FileTextIcon color="rgb(145,155,173)" size={36} strokeWidth="1.5" />*/}
      </div>
      <div className="item_name">{shortenName(chat.name)}</div>
    </div>
  );
};

export default FolderItem;
