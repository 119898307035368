import Message from "../../../i18n/Message";
import TrashIcon from "../../../assets/icons/TrashIcon";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { deletePrompt } from "../store/promptActionCreators";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import PromptStatusBadge from "./PromptStatusBadge";

import "./PromptTile.scss";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const PromptTile = ({ prompt }) => {
  const workspaceId = useWorkspaceId();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const linkBuilder = useLinkBuilder();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const onDeleteConfirmed = () => {
    const onFinished = () => {
      setShowDeleteConfirm(false);
    };
    dispatch(
      deletePrompt(intl, workspaceId, prompt.uid, onFinished, onFinished)
    );
  };

  return (
    <div
      className={
        "campaign_tile prompt_tile border-radius-lg d-flex " +
        (prompt.approved ? "approved" : "draft")
      }
    >
      <div
        className="d-flex flex-column tile_body"
        onClick={() => nav(linkBuilder.linkToPrompt(prompt.uid))}
      >
        <div className="tile-name">{prompt.name}</div>
        <div className="metadata_row">
          <div className="meta_name">
            <Message id="created_on" />
          </div>
          <div className="meta_value">
            {dayjs(prompt.dateCreated).format("LLL")}
          </div>
        </div>
        <div className="status_row"></div>
      </div>
      <div className="d-flex tile-actions">
        <div>
          <PromptStatusBadge prompt={prompt} />
        </div>
        <div className="confirm_container">
          {showDeleteConfirm && (
            <>
              <div
                className="cover"
                onClick={() => setShowDeleteConfirm(false)}
              ></div>
              <div className="confirm_panel">
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              </div>
            </>
          )}
        </div>
        <button
          className="no-btn tile-action"
          onClick={() => setShowDeleteConfirm(true)}
        >
          <TrashIcon size="16" color="#344767" />
          <Message id="delete" />
        </button>
        <button
          className="no-btn tile-action"
          onClick={() => nav(linkBuilder.linkToPrompt(prompt.uid))}
        >
          <Edit2Icon size="16" color="#344767" />
          <Message id="edit" />
        </button>
      </div>
    </div>
  );
};

export default PromptTile;
