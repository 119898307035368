import { useState } from "react";
import CheckIcon from "../../../assets/icons/CheckIcon";
import ClipboardIcon from "../../../assets/icons/ClipboardIcon";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import UserIcon from "../../../assets/icons/UserIcon";
import MessageTextContainer from "../../../components/chats/details/MessageTextContainer";
import Notify from "../../../components/shared/Notify";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";
import "./WidgetChatMessage.scss";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Bot } from "lucide-react";

const WidgetChatMessage = ({ message }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { chatbotId } = useParams();

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [content, setContent] = useState(message.content);
  const [messageElem, setMessageElem] = useState(false);
  const [messageHeight, setMessageHeight] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [messageCopied, setMessageCopied] = useState(false);

  const onCopy = () => {
    if (!navigator.clipboard) {
      Notify.error(label(intl, "clipboard_copy_failed"));
      return;
    }

    navigator.clipboard.writeText(message.content).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 3000);
    });
  };

  return (
    <>
      <div
        className={
          "widget_chat_message " +
          (message.role === "user" ? "user" : "") +
          (message.role === "assistant" ? "assistant" : "")
        }
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="avatar">
          {message.role === "user" && (
            <div className="user_avatar">
              <UserIcon color="#777" />
            </div>
          )}
          {message.role === "assistant" && (
            <div className="openai_avatar">
              <Bot color="#fff" />
            </div>
          )}
        </div>

        <div className="message_item" ref={(item) => setMessageElem(item)}>
          <MessageTextContainer>{message.content}</MessageTextContainer>
        </div>

        <div className="message_metadata">
          {!!message.filename && (
            <>
              <div className="message_metadata_item">
                <FileTextIcon color="rgb(109, 123, 147)" size="16" />
                {message.filename}
              </div>
            </>
          )}
        </div>
        {!editing && hovering && (
          <div className="message_cover">
            <div className="message_actions">
              <button
                className="btn btn-sm no-btn tile-action"
                onClick={onCopy}
              >
                {messageCopied && (
                  <CheckIcon size="16" color="#344767" strokeWidth="2" />
                )}
                {!messageCopied && <ClipboardIcon size="16" color="#344767" />}
                <Message id="copy" />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetChatMessage;
