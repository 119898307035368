import React from "react";
import { useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import CopyIcon from "../../../../assets/icons/CopyIcon";

import "./CopyLinkDialog.scss";
import ModalDialog from "../../../shared/ModalDialog";
import Message from "../../../../i18n/Message";
import Notify from "../../../shared/Notify";
import label from "../../../../i18n/label";

const CopyLinkDialog = ({ chat, onClose }) => {
  const intl = useIntl();

  const link =
    window.location.protocol +
    "//" +
    window.location.host +
    "/share/" +
    chat.uid;

  const onCopy = () => {
    copy(link);
    Notify.success(label(intl, "copy_success"));
    onClose();
  };

  return (
    <ModalDialog onClose={onClose} className="copy_link_dialog">
      <div className="modal_title">
        <Message id="share_dialog_title" />
      </div>

      <div className="dialog_body">
        <div className="share_notice">
          <Message id="Messages you send after creating your link will also be shared later. Anyone with the link will be able to view the shared chat." />
        </div>
        <input
          className="form-control"
          type="text"
          value={link}
          readOnly={true}
        />
      </div>

      <div className="buttons">
        <button className="btn bg-gradient-dark" type="button" onClick={onCopy}>
          <CopyIcon color="#fff" strokeWidth="2" size="18" />
          <Message id="copy_link_button" />
        </button>
        <button className="btn no-btn" onClick={onClose}>
          <Message id="cancel" />
        </button>
      </div>
    </ModalDialog>
  );
};

export default CopyLinkDialog;
