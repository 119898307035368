import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";
import PromptTile from "./PromptTile";
import CreatePromptDialog from "./CreatePromptDialog";
import { loadPrompts } from "../store/promptActionCreators";
import { promptSelectors } from "../store/promptReducer";

import "./PromptListPage.scss";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";

const PromptListPage = () => {
  const [showCreate, setShowCreate] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const workspaceId = useWorkspaceId();
  const promptList = useSelector(promptSelectors.getList);
  const listCounter = useSelector(promptSelectors.getListCounter);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadPrompts(intl, workspaceId));
    }
  }, [dispatch, intl, workspaceId, listCounter]);

  return (
    <>
      <div className="breadcrumbs">&nbsp;</div>
      <h1>
        <Message id="menu_prompts" />
      </h1>
      <div className="actions_panel">
        <button
          className="btn bg-gradient-primary"
          onClick={() => setShowCreate(true)}
        >
          <PlusIcon size="18" color="#fff" />
          <Message id="create_prompt" />
        </button>
      </div>
      <div className="body_content card">
        <div className="content_header">
          <div></div>
        </div>
        <div className="campaign_tiles">
          {!promptList && <Spinner />}
          {promptList &&
            promptList.map((prompt) => (
              <PromptTile key={prompt.uid} prompt={prompt} />
            ))}
        </div>
      </div>
      {showCreate && (
        <CreatePromptDialog onClose={() => setShowCreate(false)} />
      )}
    </>
  );
};

export default PromptListPage;
