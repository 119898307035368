import { useEffect, useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import { materialDark } from "@uiw/codemirror-theme-material";
import { EditorView } from "@codemirror/view";
import { json } from "@codemirror/lang-json";
import { useIntl } from "react-intl";
import "./ModelApiConsole.scss";
import Message from "../../../i18n/Message";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import Spinner from "../../../assets/icons/Spinner";
import ArrowInIcon from "../../../assets/icons/ArrowInIcon";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import { ModelType } from "../models/modelRegistry";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import { sendModelApiRequest } from "./sendRequest";
import { useDispatch } from "react-redux";
import { loadJobs, loadJobsByModel } from "../store/hubActionCreators";

const editorHeight = "270px";

const buildAPIRequest = (model) => {
  const req = {
    model: model.vendor + "/" + model.handle,
  };
  if (model.type === ModelType.IMAGE) {
    req.prompt =
      "Scene of a giant ancient tortoise with a fantasy city built on its back. The tortoise's shell is covered in lush, dense forest with towering trees and a hidden, misty village nestled in the foliage. The city consists of intricately designed buildings that blend seamlessly with the natural environment, featuring rope bridges connecting different sections of the city";
  } else if (model.type === ModelType.TEXT_BASE) {
    req.prompt = "I believe the meaining of life is ";
  } else if (model.type === ModelType.TEXT_INSTRUCT) {
    req.messages = [
      {
        role: "system",
        content: "You are helpful AI assistant.",
      },
      {
        role: "user",
        content: "When daylight saving time was introduced?",
        // "Mary has one brother and one sister. How many sisters do Mary's siblings have?",
      },
    ];
  }

  return req;
};

const ModelApiConsole = ({ model }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [requestJson, setRequestJson] = useState("");
  const [responseJson, setResponseJson] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (model) {
      const req = buildAPIRequest(model);
      setRequestJson(JSON.stringify(req, null, 2));
    }
  }, [model]);

  const onSendClicked = () => {
    let req = {};
    try {
      req = JSON.parse(requestJson);
    } catch (e) {
      Notify.error(label(intl, "api_console_json_error"));
      return;
    }
    setInProgress(true);
    setResponseJson("");
    sendModelApiRequest(
      req,
      intl,
      (res) => {
        setResponseJson(JSON.stringify(res, null, 2));
        setResponse(res);
        setInProgress(false);
        dispatch(loadJobsByModel(model.vendor + "/" + model.handle, intl));
        dispatch(loadJobs(intl));
      },
      () => setInProgress(false)
    );
  };

  return (
    <div className="model_api_console">
      <div className="url_container">
        <span className="http-method">POST</span>
        <span className="rest-endpoint">https://api.promptleo.com/v1/jobs</span>
      </div>
      <div className="request_container">
        <div className="request">
          <div className="label">
            <Message id="api_console_label_request" />
          </div>
          <CodeMirror
            value={requestJson}
            theme={materialDark}
            height={editorHeight}
            extensions={[json(), EditorView.lineWrapping]}
            onChange={(value) => setRequestJson(value)}
          />
          {/* 
          <CodeMirror
            value={requestJson}
            options={requestEditorOptions}
            onBeforeChange={(editor, data, value) => {
              setRequestJson(value);
            }}
            onChange={(editor, data, value) => {}}
          />
          */}
        </div>
        <div className="between">
          <ChevronRightIcon />
        </div>
        <div className="response">
          <div className="label">
            <Message id="api_console_label_response" />
          </div>
          <CodeMirror
            value={responseJson}
            theme={materialDark}
            height={editorHeight}
            extensions={[json(), EditorView.lineWrapping]}
          />
          {/* 
          <CodeMirror
            value={responseJson}
            options={requestEditorOptions}
            onBeforeChange={(editor, data, value) => {}}
            onChange={(editor, data, value) => {}}
          />
          */}
          {inProgress && (
            <div className="progress">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      <div className="request_actions">
        <div className="buttons">
          <div>
            <button
              className="btn bg-gradient-dark"
              disabled={inProgress}
              onClick={onSendClicked}
            >
              {inProgress ? <Spinner /> : <ArrowInIcon color="#fff" />}
              <Message id="api_console_send_request" />
            </button>
          </div>

          <div>
            <a
              href="https://promptleo.com/api-docs/"
              target="_blank"
              rel="noreferrer"
            >
              <FileTextIcon color="#333" />{" "}
              <Message id="api_console_doc_link" />
            </a>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ModelApiConsole;
