import reducerUtils from "../../../store/reducerUtils";
import produce from "immer";
import AccountActions from "./actionTypes";

const initialState = {
  orders: null,
  invitation: null,
};

export const accountSelectors = {
  getOrders: (state) => state.account.orders,
  getInvitation: (state) => state.account.invitation,
};

const onOrdersLoaded = produce((draft, action) => {
  draft.orders = action.orders;
});

const onInvitationLoaded = produce((draft, action) => {
  draft.invitation = action.invitation;
});

const handlers = {
  [AccountActions.ORDERS_LOADED]: onOrdersLoaded,
  [AccountActions.INVITATION_LOADED]: onInvitationLoaded,
};

const accountReducer = reducerUtils.createReducer(initialState, handlers);

export default accountReducer;
