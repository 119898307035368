import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";
import ApiKeyTile from "./ApiKeyTile";
import CreateApiKeyDialog from "./CreateApiKeyDialog";
import { loadApiKeys } from "../store/apiKeyActionCreators";
import { apiKeySelectors } from "../store/apiKeyReducer";

import "./ApiKeyListPage.scss";

const ApiKeyListPage = () => {
  const [showCreate, setShowCreate] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { workspaceId } = useParams();
  const keyList = useSelector(apiKeySelectors.getList);
  const listCounter = useSelector(apiKeySelectors.getListCounter);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadApiKeys(intl, workspaceId));
    }
  }, [dispatch, intl, workspaceId, listCounter]);

  return (
    <WorkspaceLayout contentPanelClassName="campaign_list_main">
      <div className="body_content card">
        <div className="content_header">
          <h1>
            <Message id="api_keys_title" />
          </h1>
          <div>
            <button
              className="btn bg-gradient-primary"
              onClick={() => setShowCreate(true)}
            >
              <PlusIcon size="18" color="#fff" />
              <Message id="create_api_key" />
            </button>
          </div>
        </div>
        <div className="campaign_tiles">
          {!keyList && <Spinner />}
          {keyList &&
            keyList.map((key) => <ApiKeyTile key={key.uid} apiKey={key} />)}
        </div>
      </div>
      {showCreate && (
        <CreateApiKeyDialog onClose={() => setShowCreate(false)} />
      )}
    </WorkspaceLayout>
  );
};

export default ApiKeyListPage;
