import backend from "../../../backend/backend";
import label from "../../../i18n/label";
import Notify from "../../shared/Notify";
import EndpointActions from "./EndpointActions";

export const createEndpoint = (
  intl,
  endpoint,
  workspaceId,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .createEndpoint(endpoint, workspaceId)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: EndpointActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to create an endpoint", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadEndpoints = (intl, workspaceId, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getEndpoints(workspaceId)
      .then((data) => {
        console.log("loaded Endpoints", data);
        dispatch({
          type: EndpointActions.LIST_LOADED,
          list: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load Endpoints", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadEndpoint = (intl, workspaceId, id, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getEndpoint(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: EndpointActions.LOADED,
          endpoint: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load an Endpoint", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const loadEndpointRequests = (
  intl,
  workspaceId,
  id,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .getEndpointRequests(workspaceId, id)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: EndpointActions.REQUESTS_LOADED,
          requests: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to load endpoint requests", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const updateEndpoint = (
  intl,
  workspaceId,
  uid,
  endpoint,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .updateEndpoint(workspaceId, uid, endpoint)
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: EndpointActions.UPDATED,
          endpoint: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to update an Endpoint", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const deleteEndpoint = (intl, workspaceId, uid, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .deleteEndpoint(workspaceId, uid)
      .then((data) => {
        dispatch({
          type: EndpointActions.LIST_CHANGED,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to delete an Endpoint", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const execEndpoint = (
  intl,
  workspaceId,
  uid,
  name,
  request,
  onSuccess,
  onError
) => {
  return (dispatch) => {
    backend
      .execEndpoint(workspaceId, uid, name, request)
      .then((data) => {
        /*
        dispatch({
          type: EndpointActions.REQUESTS_CHANGED,
        });
        */
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        console.error("Failed to exec an Endpoint", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};
