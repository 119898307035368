import FlowsIcon from "../../../assets/icons/FlowsIcon";
import Message from "../../../i18n/Message";
import WorkspaceLayout from "../../shared/layout/WorkspaceLayout";

import "./FlowWaitlistPage.scss";

const FlowWaitlistPage = () => {
  return (
    <WorkspaceLayout contentPanelClassName="flow_waitlist_main">
      <div className="body_content card">
        <div className="content_header">
          <h1>
            <Message id="flows_header" />
          </h1>
          <div></div>
        </div>
        <div className="waitlist_body">
          <div className="message_wrapper">
            <div className="message_icon_wrapper">
              <FlowsIcon color="#67748e" size="36" />
            </div>
            <div className="message_text">
              <Message id="flows_waitlist_text" />
            </div>
            <div className="message_button">
              <a
                href="/p/contact"
                className="btn bg-gradient-primary"
                target="_blank"
                rel="noopener"
              >
                <Message id="flow_waitlist_join" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </WorkspaceLayout>
  );
};

export default FlowWaitlistPage;
