class LinkBuilder {
  constructor(area, workspaceId, workspaceSubdomain) {
    this.area = area;
    this.workspaceId = workspaceId;
    this.workspaceSubdomain = workspaceSubdomain;
  }
  linkToChats() {
    if (this.area) {
      return `/chats`;
    } else {
      return `/w/${this.workspaceId}/chats`;
    }
  }
  linkToChat(id) {
    if (this.area) {
      return `/chats/${id}/edit`;
    } else {
      return `/w/${this.workspaceId}/chats/${id}/edit`;
    }
  }
  linkToChatFolder(id) {
    if (this.area) {
      return `/chats/${id}`;
    } else {
      return `/w/${this.workspaceId}/chats/${id}`;
    }
  }
  linkToPrompts() {
    if (this.area) {
      return `/prompts`;
    } else {
      return `/w/${this.workspaceId}/prompts`;
    }
  }
  linkToPrompt(promptId) {
    if (this.area) {
      return `/prompts/${promptId}`;
    } else {
      return `/w/${this.workspaceId}/prompts/${promptId}`;
    }
  }
  linkToPromptVersion(promptId, versionId) {
    if (this.area) {
      return `/prompts/${promptId}/${versionId}`;
    } else {
      return `/w/${this.workspaceId}/prompts/${promptId}/${versionId}`;
    }
  }
}

export default LinkBuilder;
