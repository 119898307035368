import { useState } from "react";
import "./EndpointMetadata.scss";
import Message from "../../../i18n/Message";
import { useIntl } from "react-intl";
import label from "../../../i18n/label";
import TrashIcon from "../../../assets/icons/TrashIcon";

const EndpointMetadata = ({ metadata, setMetadata }) => {
  const intl = useIntl();
  const [newProp, setNewProp] = useState("");
  const [newValue, setNewValue] = useState("");

  const onPropChange = (oldValue, newValue) => {
    const propValue = metadata[oldValue];
    const newMetadata = { ...metadata, [newValue]: propValue };
    delete newMetadata[oldValue];
    setMetadata(newMetadata);
  };
  const onValueChange = (prop, newValue) => {
    const newMetadata = { ...metadata, [prop]: newValue };
    setMetadata(newMetadata);
  };
  const onPropDelete = (prop) => {
    const newMetadata = { ...metadata };
    delete newMetadata[prop];
    setMetadata(newMetadata);
  };

  const onAddNew = () => {
    if (!newProp || !newValue) return;
    const newMetadata = { ...metadata, [newProp]: newValue };
    setMetadata(newMetadata);
    setNewProp("");
    setNewValue("");
  };
  return (
    <div className="metadata_container">
      {Object.keys(metadata).map((prop, index) => {
        return (
          <div className="metadata_tile">
            <div className="metadata_prop">
              <input
                className="form-control"
                type="text"
                value={prop}
                onChange={(e) => onPropChange(prop, e.target.value)}
              />
              :
            </div>
            <div>
              <input
                className="form-control"
                type="text"
                value={metadata[prop]}
                onChange={(e) => onValueChange(prop, e.target.value)}
              />
            </div>
            <div>
              <button className="btn no-btn" onClick={() => onPropDelete(prop)}>
                <TrashIcon size="18" color="#344767" />
              </button>
            </div>
          </div>
        );
      })}
      <div className="metadata_tile">
        <div>
          <input
            className="form-control"
            type="text"
            value={newProp}
            onChange={(e) => setNewProp(e.target.value)}
            placeholder={label(intl, "metadata_prop_placeholder")}
          />
        </div>
        <div>
          <input
            className="form-control"
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            placeholder={label(intl, "metadata_value_placeholder")}
          />
        </div>
        <div>
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={onAddNew}
          >
            <Message id="add_metadata" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndpointMetadata;
