export const scrollToChatBottom = () => {
  const elem = document.querySelector(".content_container_absolute");
  if (elem) {
    elem.scrollTop = elem.scrollHeight;
  }
};

export const scrollToWidgetChatBottom = () => {
  const elem = document.querySelector(".messages_container_wrapper");
  if (elem) {
    elem.scrollTop = elem.scrollHeight;
  }
};
