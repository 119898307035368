function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}
function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function generateGuid() {
  var result, i, j;
  result = "";
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + "-";
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
}

export const getClientId = function () {
  const existing = getCookie("plmcbr");
  if (existing) return existing;
  const newId = crypto?.randomUUID ? crypto.randomUUID() : generateGuid();
  setCookie("plmcbr", newId, 365 * 5);
  return newId;
};

export const getDevInfo = function () {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const screenAvailWidth = window.screen.availWidth;
  const screenAvailHeight = window.screen.availHeight;
  const ua = navigator.userAgent;
  const lang = navigator.language;
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzo = new Date().getTimezoneOffset() / -60;

  return {
    screenWidth,
    screenHeight,
    screenAvailWidth,
    screenAvailHeight,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    ua,
    lang,
    tz,
    tzo,
  };
};
