export const versionStatus = {
  APPROVED: "APPROVED",
  DRAFT: "DRAFT",
};

export const getVersionClass = (version) => {
  if (version.status === versionStatus.APPROVED) {
    return "approved";
  } else if (version.status === versionStatus.DRAFT) {
    return "draft";
  }
};
