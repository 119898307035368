import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";

import ModalDialog from "../../shared/ModalDialog";
import { createPrompt } from "../store/promptActionCreators";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import { useLinkBuilder } from "../../shared/link-builder/linkBuilderContext";

const CreatePromptDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  const linkBuilder = useLinkBuilder();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [input, setInput] = useState(null);
  const [formElem, setFormElem] = useState(null);

  useEffect(() => {
    if (input) input.focus();
  }, [input]);

  const onCreate = () => {
    const valid = input.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;
    setLoading(true);
    const onSuccess = (newPrompt) => {
      setLoading(false);
      navigate(linkBuilder.linkToPrompt(newPrompt.uid));
      onClose();
    };
    dispatch(
      createPrompt(intl, name, workspaceId, onSuccess, () => setLoading(false))
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_campaign_dialog">
      <div className="modal_title">
        <Message id="create_prompt_dialog_title" />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="campaign_form">
          <div className="dialog_body">
            <input
              className="form-control"
              type="text"
              ref={(val) => setInput(val)}
              required
              maxLength="120"
              placeholder={label(intl, "prompt_name_placeholder")}
              onChange={(e) => setName(e.target.value)}
              value={name}
              readOnly={loading}
            />
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id="create_prompt_button" />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default CreatePromptDialog;
