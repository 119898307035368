import WorkspacePageWrapper from "../../workspace/WorkspacePageWrapper";
import PromptListPage from "./PromptListPage";

const PromptListPageWrapper = ({ area }) => {
  return (
    <WorkspacePageWrapper contentClassName="prompt_list_page" area={area}>
      <PromptListPage />
    </WorkspacePageWrapper>
  );
};

export default PromptListPageWrapper;
