import produce from "immer";
import reducerUtils from "../../../../store/reducerUtils";
import { WorkspaceActions } from "./workspaceActions";

const initialState = {
  data: {
    workspaces: null,
  },
};

export const workspaceSelectors = {
  getWorkspaces: (state) => state.workspace.data.workspaces,
  getCurrent: (workspaceId) => {
    return (state) => {
      const workspaces = state.workspace.data.workspaces;
      if (!workspaces || workspaces.length === 0) {
        return null;
      }
      return workspaces.find((item) => item.uid === workspaceId);
    };
  },
};

const onLoadList = produce((draft, action) => {
  draft.data.workspaces = action.workspaces;
});

const handlers = {
  [WorkspaceActions.WORKSPACES_LOADED]: onLoadList,
};

const workspaceReducer = reducerUtils.createReducer(initialState, handlers);

export default workspaceReducer;
