import AuthActions from "./actionTypes";
import { assignApiKey } from "../../../backend/httpAPI";
import authStore from "../AuthStore";
import Config from "../../../shared/Config";
//import Notify from "../../shared/notify/Notify";

export const checkAuth = (onError = () => {}) => {
  return (dispatch) => {
    const options = {
      credentials: "same-origin",
    };
    assignApiKey(options);
    fetch(Config.restUrl("/auth/info"), options)
      .then((res) => {
        if (!res.ok) {
          onError();
          return;
        }
        return res;
      })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.uid) {
          initChat(resp);
          dispatch({ type: AuthActions.LOGGED_IN, user: resp });
        } else {
          onError();
        }
      })
      .catch((error) => {
        onError();
      });
  };
};

export const login = () => {
  return (dispatch) => {
    const options = {
      credentials: "same-origin",
    };
    assignApiKey(options);
    fetch(Config.restUrl("/auth/info"), options)
      .then((res) => {
        //console.log("Login response ", res.ok);
        if (!res.ok) {
          console.log("Login response - not ok");
          //window.location = "/b/login";
          window.location = "/app/";
          //throw Error(res.statusText);
        }
        const result = res.json();
        //console.log("Got login response ", result);
        return result;
      })
      .then((resp) => {
        //console.log("Receieved response: ", resp);
        if (resp.uid) {
          initChat(resp);
          dispatch({ type: AuthActions.LOGGED_IN, user: resp });
        } else {
          //window.location = "/b/login";
          window.location = "/app/";
        }
      })
      .catch((error) => {
        console.error("Error", error);
        //window.location = "/b/login";
        window.location = "/app/";
      });
  };
};

const initChat = (resp) => {
  //console.log("initChat", window.Tawk_API);
  if (!window.Tawk_API) {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    window.Tawk_API.onLoad = function () {
      //console.log("initChat onLoad", window.Tawk_API);
      window.Tawk_API.setAttributes(
        {
          name: resp.name + " (" + resp.email + ")",
          email: resp.email,
          hash: resp.uid,
        },
        function (error) {}
      );
    };
    window.Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/6440fe9831ebfa0fe7f9677a/1gueu50sl";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();

    /*
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes(
        {
          name: resp.name + " (" + resp.email + ")",
          email: resp.email,
          hash: resp.uid,
        },
        function (error) {},
      );
    };
    */
    /*
    window.Tawk_API.visitor = {
      name : resp.name + " (" + resp.email + ")",
      email : resp.email,
      hash: resp.uid,
    };
    */
  }
};

export const loginWithPassword = () => {
  return (dispatch) => {
    fetch(Config.restUrl("/auth/login"), {
      credentials: "same-origin",
    })
      .then((res) => {
        //console.log("Login response ", res.ok);
        if (!res.ok) {
          console.log("Login response - not ok");
          //window.location = "/b/login";
          window.location = "/app/";
          //throw Error(res.statusText);
        }
        const result = res.json();
        //console.log("Got login response ", result);
        return result;
      })
      .then((resp) => {
        //console.log("Receieved response: ", resp);
        if (resp.uid) {
          initChat(resp);
          dispatch({ type: AuthActions.LOGGED_IN, user: resp });
        } else {
          //  window.location = "/b/login";
          window.location = "/app/";
        }
      })
      .catch((error) => {
        console.error("Error", error);
        //window.location = "/b/login";
        window.location = "/app/";
      });
  };
};

export const logout = () => {
  return () => {
    authStore.setToken("");
    // cookies are http only
    //Cookies.remove('JSESSIONID', { path: '/b' });
    //Cookies.remove('JSESSIONID', { path: '/' });
    window.location = "/";
  };
};
