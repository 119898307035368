export function parseUrls(input) {
  if (!input || typeof input !== "string") {
    throw new Error("Input must be a non-empty string");
  }

  // Split on commas, spaces, or newline characters
  const urls = input
    .split(/[\s,]+/)
    .map((url) => url.trim())
    .filter((url) => url !== ""); // Remove any empty strings

  const urlPattern =
    /^(https?:\/\/)[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=]+[a-zA-Z0-9]+[\/]?$/;

  if (!urls.every((url) => urlPattern.test(url))) {
    throw new Error("Invalid URL format detected");
  }

  return urls;
}
