import backend from "../../../../backend/backend";
import label from "../../../../i18n/label";
import Notify from "../../Notify";
import { WorkspaceActions } from "./workspaceActions";

export const loadWorkspaces = (intl) => {
  return (dispatch) => {
    backend
      .getWorkspaces()
      .then((data) => {
        //console.log("loaded workspaces", data);
        dispatch({
          type: WorkspaceActions.WORKSPACES_LOADED,
          workspaces: data,
        });
      })
      .catch((err) => {
        console.error("Failed to load workspaces", err);
        Notify.error(label(intl, "error_unexpected"));
      });
  };
};

export const createWorkspace = (intl, name, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .createWorkspace(name)
      .then((data) => {
        console.log("created workspace", data);
        dispatch(loadWorkspaces(intl));
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        console.error("Failed to create a workspace", err);
        Notify.error(label(intl, "error_unexpected"));
        onError && onError();
      });
  };
};

export const deleteWorkspace = (intl, uid, onSuccess, onError) => {
  return () => {
    backend
      .deleteWorkspace(uid)
      .then((data) => {
        console.log("deleted workspace", data);
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        console.error("Failed to delete a workspace", err);
        Notify.error(label(intl, "error_unexpected"));
        onError && onError();
      });
  };
};

export const updateWorkspace = (intl, uid, request, onSuccess, onError) => {
  return () => {
    backend
      .updateWorkspace(uid, request)
      .then((data) => {
        console.log("updated workspace", data);
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        console.error("Failed to updated a workspace", err);
        Notify.error(label(intl, "error_unexpected"));
        onError && onError();
      });
  };
};
