import "./WidgetPromptControl.scss";
import WidgetPromptTextarea from "./WidgetPromptTextarea";

const WidgetPromptControl = ({ onSubmit }) => {
  return (
    <div className="widget_prompt_control">
      <div className="prompt_control_body">
        <WidgetPromptTextarea onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default WidgetPromptControl;
