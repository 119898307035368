import { useIntl } from "react-intl";
import Message from "../../../i18n/Message";
import "./CreateTrainStep.scss";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { CheckSquare } from "lucide-react";
import Spinner from "../../../assets/icons/Spinner";
import { parseUrls } from "../chatbot-details/train/trainUtils";
import backend from "../../../backend/backend";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import { loadChatbot, loadChatbotList } from "../store/chatbotActionCreators";
import { useNavigate } from "react-router-dom";
import { linkToChatbot } from "../../../routes";
import ChatbotTab from "../chatbot-details/chatbotTab";

const CreateTrainStep = ({ chatbot, onClose }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sources, setSources] = useState("");
  const [parseError, setParseError] = useState(false);
  const [indexInProgress, setIndexInProgress] = useState(false);
  const [formElem, setFormElem] = useState(null);

  console.log("Create train step with chatbot: ", chatbot);

  const onSourcesSave = () => {
    if (!sources) {
      if (formElem) formElem.reportValidity();
      return;
    }
    const urls = parseUrls(sources);
    console.log("Extracted URLs: ", urls);
    if (!urls || urls.length === 0) {
      setParseError(true);
      return;
    }
    setParseError(false);
    setIndexInProgress(true);
    backend
      .updateChatbot({
        ...chatbot.chatbot,
        kbSources: JSON.stringify(urls),
      })
      .then((resp) => {
        console.log("Saved chatbot ", resp);
        Notify.success(label(intl, "kb_sources_save_success"));
        const proceed = () => {
          backend
            .indexChatbot(resp.chatbot)
            .then((indexResp) => {
              console.log("Indexing resp: ", indexResp);
              setIndexInProgress(true);
              const checkStatus = () => {
                backend
                  .getChatbot(resp.chatbot.uid)
                  .then((newResp) => {
                    console.log("Checking running status ", newResp);
                    if (newResp.chatbot.kbSyncStatus === "RUNNING") {
                      setTimeout(checkStatus, 1500);
                    } else {
                      setIndexInProgress(false);
                      dispatch(loadChatbot(resp.chatbot.uid, intl));
                      dispatch(loadChatbotList());
                      navigate(
                        linkToChatbot(resp.chatbot.uid, ChatbotTab.EMBED)
                      );
                    }
                  })
                  .catch(() => {
                    Notify.error(label(intl, "error_unexpected"));
                  });
              };
              setTimeout(checkStatus, 1000);
            })
            .catch((indexErr) => {
              console.error("Failed to save chatbot ", indexErr);
              Notify.error(label(intl, "error_unexpected"));
              setIndexInProgress(false);
            });
        };
        setTimeout(proceed, 700);
      })
      .catch((err) => {
        console.error("Failed to save chatbot ", err);
        Notify.error(label(intl, "error_unexpected"));
        setIndexInProgress(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSourcesSave();
  };

  return (
    <div className="create_train_step">
      <div className="modal_title">
        <Message id="create_chatbot_train_title" />
      </div>
      <p>
        <Message id="kb_init_help" />
      </p>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <textarea
          rows="7"
          value={sources}
          onChange={(e) => setSources(e.target.value)}
          disabled={indexInProgress}
          className="form-control"
          required
        ></textarea>
        <div className="widget_code_button_container">
          <button
            className="btn btn-outline-dark"
            disabled={indexInProgress}
            type="submit"
          >
            <CheckSquare color="#344767" size={18} />
            <Message id="save" />
          </button>
          {parseError && (
            <div className="error_notification " role="alert">
              <Message id="kb_sources_parse_error" />
            </div>
          )}
          {indexInProgress && (
            <div className="indexing_status">
              <Spinner />
              <Message id="create_chatbot_train_index_in_progress" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateTrainStep;
