import produce from "immer";
import reducerUtils from "../../../../store/reducerUtils";
import ChatbotWidgetActions from "./chatbotWidgetActions";

const initialState = {
  data: {
    chatbot: null,

    chat: null,
    messages: [],

    replyInProgress: false,
    replyPart: "",
    replyError: null,
  },
};

export const chatbotWidgetSelectors = {
  getChatbot: (state) => state.chatbotWidget.data.chatbot,

  getChat: (state) => state.chatbotWidget.data.chat,
  getMessages: (state) => state.chatbotWidget.data.messages,

  getReplyInProgress: (state) => state.chatbotWidget.data.replyInProgress,
  getReplyPart: (state) => state.chatbotWidget.data.replyPart,
  getReplyError: (state) => state.chatbotWidget.data.replyError,
};

const onChatbotLoaded = produce((draft, action) => {
  draft.data.chatbot = action.chatbot;
  draft.data.chat = action.chatbot.chats?.[0];
  draft.data.messages = draft.data.chat?.messages ?? [];
});

const onChatLoaded = produce((draft, action) => {
  draft.data.chat = action.chat;
});

const onMessagePosted = produce((draft, action) => {
  const message = action.message;
  draft.data.messages.push({ role: "user", content: message });
  draft.data.replyInProgress = true;
  draft.data.replyError = null;
  draft.data.replyPart = "";
});

const onGotMessagePart = produce((draft, action) => {
  draft.data.replyPart = (draft.data.replyPart ?? "") + action.content;
  //console.log("onGotMessagePart ", draft.data.replyPart, action);
});

const onFinishedMessageParts = produce((draft, action) => {
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.messages = [
    ...(draft.data.messages ?? []).filter((item) => item.uid),
    ...action.response,
  ];
});

const onMessagePartError = produce((draft, action) => {
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.replyError = action.error;
});

const onGotMessageReply = produce((draft, action) => {
  const messages = action.reply.messages;
  draft.data.replyInProgress = false;
  draft.data.replyPart = "";
  draft.data.messages = [...draft.data.messages, ...messages];
});

const handlers = {
  [ChatbotWidgetActions.CHATBOT_LOADED]: onChatbotLoaded,
  [ChatbotWidgetActions.CHAT_LOADED]: onChatLoaded,
  [ChatbotWidgetActions.POSTED_MESSAGE]: onMessagePosted,
  [ChatbotWidgetActions.GOT_MESSAGE_PART]: onGotMessagePart,
  [ChatbotWidgetActions.FINISHED_MESSAGE_PARTS]: onFinishedMessageParts,
  [ChatbotWidgetActions.MESSAGE_PART_ERROR]: onMessagePartError,
  [ChatbotWidgetActions.GOT_MESSAGE_RELY]: onGotMessageReply,
};

const chatbotWidgetReducer = reducerUtils.createReducer(initialState, handlers);

export default chatbotWidgetReducer;
