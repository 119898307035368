const EndpointActions = {
  LOADED: "endpoint/loaded",
  UPDATED: "endpoint/updated",
  LIST_LOADED: "endpoints/loaded",
  LIST_CHANGED: "endpoints/changed",
  REQUESTS_LOADED: "endpoint-requests/loaded",
  REQUESTS_CHANGED: "endpoint-requests/changed",
};

export default EndpointActions;
