import backend from "../../../../backend/backend";
import Notify from "../../../../components/shared/Notify";
import label from "../../../../i18n/label";
import ChatbotWidgetActions from "./chatbotWidgetActions";

export const loadWidgetChatbot = (uid, clientId, intl, onSuccess, onError) => {
  return (dispatch) => {
    backend
      .getWidgetChatbot(uid, clientId)
      .then((data) => {
        console.log("loaded chatbot", data);
        dispatch({
          type: ChatbotWidgetActions.CHATBOT_LOADED,
          chatbot: data,
        });
        onSuccess && onSuccess(data);
      })
      .catch((err) => {
        onError && onError(err);
        if (!err.status || err.status !== 404) {
          console.error("Failed to load chatbot", err);
          Notify.error(label(intl, "error_unexpected"));
        }
      });
  };
};
