import { toast } from "react-toastify";

window.toast = toast;

const Notify = {
  success: (message) => {
    toast.success(message, { autoClose: 4000 });
  },
  error: (message) => {
    toast.error(message, { autoClose: false });
  },
  info: (message) => {
    toast.info(message, { autoClose: true });
  },
};

export default Notify;
