import "./EmailConfirmedPage.scss";

import { useNavigate } from "react-router-dom";
import Message from "../../../../i18n/Message";

const EmailConfirmedPage = () => {
  const nav = useNavigate();

  return (
    <div className="email_confirmed_page">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      <div className="container">
        <div className="header">
          <h5>
            <Message id="email_confirmed_title" />
          </h5>
          <div className="success_message">
            <Message id="email_confirmed_text" />
          </div>
        </div>

        <div className="field">
          <button type="button" className="btn" onClick={() => nav("/")}>
            <Message id="login_button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmedPage;
