import Message from "../../i18n/Message";
import label from "../../i18n/label";
import { useIntl } from "react-intl";
import { useState } from "react";
import Notify from "../shared/Notify";
import backend from "../../backend/backend";
import { useDispatch } from "react-redux";
import { login } from "../auth/store/actionCreators";
import authStore from "./AuthStore";

import "./LoginPage.scss";
import Spinner from "../../assets/icons/Spinner";
import { useNavigate } from "react-router-dom";

const LoginPage = ({ loadingState = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [passwordForm, setPasswordForm] = useState(null);
  const [apiKeyForm] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiKey] = useState("");
  const onPasswordSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  /*
  const onApiKeySubmit = () => {
    onSubmit();
  };
*/
  const onButtonClicked = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (email && password && passwordForm.checkValidity()) {
      setLoading(true);
      backend
        .loginWithPassword(email, password)
        .then((resp) => {
          authStore.setToken(resp.apiKey);
          setLoading(false);
          window.location = "/app/";
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error in login", error);
          Notify.error(label(intl, "login_error"));
        });
    } else if (apiKey && apiKeyForm.checkValidity()) {
      authStore.setToken(apiKey);
      dispatch(login());
    } else {
      passwordForm.reportValidity();
    }
  };

  return (
    <div className="login_layout">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      {!loadingState && (
        <div className="form">
          {/*
          <div className="separator first_separator">
            <Message id="login_via_social" />
          </div>
          <div className="social_buttons">
            <a href="/b/social/google/login" className="app-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 640 640"
              >
                <path d="M326.331 274.255v109.761h181.49c-7.37 47.115-54.886 138.002-181.49 138.002-109.242 0-198.369-90.485-198.369-202.006 0-111.509 89.127-201.995 198.369-201.995 62.127 0 103.761 26.516 127.525 49.359l86.883-83.635C484.99 31.512 412.741-.012 326.378-.012 149.494-.012 6.366 143.116 6.366 320c0 176.884 143.128 320.012 320.012 320.012 184.644 0 307.256-129.876 307.256-312.653 0-21-2.244-36.993-5.008-52.997l-302.248-.13-.047.024z" />
              </svg>
              <Message id="login_via_google" />
            </a>
            <a href="/b/social/facebook/login" className="app-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
              <Message id="login_via_facebook" />
            </a>
          </div>
          <div className="separator">
            <Message id="login_or" />
          </div>
        */}
          <form
            onSubmit={onPasswordSubmit}
            ref={(elem) => setPasswordForm(elem)}
          >
            <div className="field">
              <input
                type="email"
                autoComplete="email"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={label(intl, "login_email")}
              />
            </div>
            <div className="field">
              <input
                type="password"
                autoComplete="current-password"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={label(intl, "login_password")}
              />
            </div>
            <div style={{ position: "relative" }}>
              <input
                type="submit"
                name="submit"
                style={{ position: "absolute", left: "-10000px" }}
              />
            </div>
          </form>
          {/*
          <div className="separator">
            <Message id="login_or" />
          </div>
          <div className="field">
            <form onSubmit={onApiKeySubmit} ref={(elem) => setApiKeyForm(elem)}>
              <input
                type="text"
                autoComplete="api_key"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                required
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder={label(intl, "login_api_key")}
              />
            </form>
          </div>
           */}

          <div className="field">
            <button
              type="button"
              disabled={loading}
              className="btn-3d btn-sm bg-accent shadow-accent-volume bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              onClick={onButtonClicked}
            >
              {loading ? <Spinner /> : <Message id="login_button" />}
            </button>
          </div>
          <div className="forgot_password">
            <a href="/app/forgot-password">
              <Message id="login_forgot_password" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
