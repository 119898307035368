import { useSelector } from "react-redux";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import Spinner from "../../../assets/icons/Spinner";
import Message from "../../../i18n/Message";
import "./ReplyLoadingState.scss";
import { chatSelectors } from "../store/chatReducer";
import MessageTextContainer from "./MessageTextContainer";

const ReplyLoadingState = () => {
  const replyPart = useSelector(chatSelectors.getReplyPart);

  return (
    <div className={"reply_loading_state "}>
      <div className="avatar">
        <div className="openai_avatar">
          <LogoOpenAI color="#fff" />
        </div>
      </div>
      <div className="message_item">
        <div>
          <MessageTextContainer>{replyPart}</MessageTextContainer>
        </div>
        <Message id="reply_loading_state" /> <Spinner />
      </div>
    </div>
  );
};

export default ReplyLoadingState;
