import ChatItem from "./ChatItem";
import "./ChatListItem.scss";
import FolderItem from "./FolderItem";

const ChatListItem = ({ chat }) => {
  if (chat.folder) {
    return <FolderItem chat={chat} />;
  } else {
    return <ChatItem chat={chat} />;
  }
};

export default ChatListItem;
