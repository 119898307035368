import React from "react";

const Spinner = ({ size = 24, color = "#000000", className = "spinner" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 50 50" width={size} height={size} stroke={color}>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  );
};

export default Spinner;
