import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Spinner from "../../../assets/icons/Spinner";
import label from "../../../i18n/label";
import Message from "../../../i18n/Message";

import ModalDialog from "../../shared/ModalDialog";

import "./CreateFolderDialog.scss";
import backend from "../../../backend/backend";
import Notify from "../../shared/Notify";
import ChatActions from "../store/chatActions";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";

const CreateFolderDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { folderId } = useParams();
  const workspaceId = useWorkspaceId();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [formElem, setFormElem] = useState(null);
  const [nameInput, setNameInput] = useState(null);

  useEffect(() => {
    if (nameInput) nameInput.focus();
  }, [nameInput]);

  const onCreate = () => {
    const valid = nameInput.checkValidity();
    if (formElem) formElem.reportValidity();
    if (!valid) return;

    setLoading(true);

    backend
      .createChat(workspaceId, {
        name,
        folder: true,
        folderId,
      })
      .then((resp) => {
        console.log("Created folder ", resp);
        dispatch({
          type: ChatActions.LIST_CHANGED,
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        console.error("Creation failed ", err);
        Notify.error(label(intl, "error_creating_folder"));
        setLoading(false);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <ModalDialog onClose={onClose} className="create_folder_dialog">
      <div className="modal_title">
        <Message id={"create_folder_title"} />
      </div>
      <form onSubmit={onSubmit} ref={(val) => setFormElem(val)}>
        <div className="dialog_body">
          <input
            className="form-control"
            type="text"
            ref={(val) => setNameInput(val)}
            required
            maxLength="120"
            placeholder={label(intl, "folder_name_placeholder")}
            onChange={(e) => setName(e.target.value)}
            value={name}
            readOnly={loading}
          />
        </div>

        <div className="buttons">
          <button
            className="btn bg-gradient-dark"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="18" />
            ) : (
              <PlusIcon color="#fff" strokeWidth="2" size="18" />
            )}
            <Message id={"create_folder_button"} />
          </button>
          <button className="btn no-btn" onClick={onClose}>
            <Message id="cancel" />
          </button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default CreateFolderDialog;
