import { useEffect, useState } from "react";

const useChatbotWebsocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    var ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss://" : "ws://") +
        window.location.host +
        "/backend/ws/chatbot"
    );
    ws.onopen = function (event) {
      console.log("[WS] Connected to: " + event.currentTarget.url);
    };
    ws.onclose = (error) => {
      // reconnect?
      console.log("[WS] closed: " + error);
    };

    setSocket(ws);
    return () => {
      ws.close();
    };
  }, [setSocket]);

  return socket;
};

export default useChatbotWebsocket;
