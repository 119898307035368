import { useDispatch, useSelector } from "react-redux";
import DeviceDetector from "device-detector-js";
import ReactCountryFlag from "react-country-flag";
import dayjs from "dayjs";

import "./ChatsTab.scss";

import ChatsZeroState from "./ChatsZeroState";
import { chatbotSelectors } from "../../store/chatbotReducer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  loadChatbot,
  loadChatMessages,
} from "../../store/chatbotActionCreators";
import { useIntl } from "react-intl";
import Spinner from "../../../../assets/icons/Spinner";
import Message from "../../../../i18n/Message";
import ChatMessageFeed from "./ChatMessageFeed";
import { ClockIcon, RefreshCwIcon } from "lucide-react";
import DeviceTypeIcon from "./DeviceTypeIcon";
import DeviceVendorIcon from "./DeviceVendorIcon";

const deviceDetector = new DeviceDetector();

const cutText = (text, maxLength) => {
  if (!text) return "";

  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + "...";
};

const ChatsTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { chatbotId } = useParams();
  const chatbot = useSelector(chatbotSelectors.getChatbot);
  const [chat, setChat] = useState(null);
  const chatMessages = useSelector(chatbotSelectors.getChatMessages);
  const chatMessagesChatUid = useSelector(
    chatbotSelectors.getChatMessagesChatUid
  );
  const [reloadInProgress, setReloadInProgress] = useState(false);

  useEffect(() => {
    if (!chat) {
      dispatch(
        loadChatbot(chatbotId, intl, (resp) => {
          console.log("loadChatbot: ", resp);
          if (resp?.chats?.length > 0) {
            setChat(resp.chats[0]);
          }
        })
      );
    }
  }, [dispatch, chatbotId, chat, intl]);

  useEffect(() => {
    if (chat && (!chatMessages || chat?.uid !== chatMessagesChatUid)) {
      dispatch(loadChatMessages(chatbotId, chat.uid));
    }
  }, [chatMessages, chatMessagesChatUid, chat, dispatch, chatbotId]);

  const reloadChats = () => {
    setReloadInProgress(true);
    dispatch(
      loadChatbot(
        chatbotId,
        intl,
        (resp) => {
          if (resp?.chats?.length > 0) {
            setChat(resp.chats[0]);
          }
          setReloadInProgress(false);
        },
        () => {
          setReloadInProgress(false);
        }
      )
    );
  };

  if (!chatbot) {
    return (
      <div className="card content_panel_body chats_zero_state_card">
        <div className="card_header">
          <div className="card_title">
            <Message id="widget_code_chats_title" />
          </div>
          <div className="card_actions"></div>
        </div>
        <div className="chats_zero_state_body">
          <Spinner />
        </div>
      </div>
    );
  }
  if (chatbot && chatbot.chats.length === 0) {
    return <ChatsZeroState />;
  }

  const messages =
    chat && chatMessagesChatUid === chat.uid ? chatMessages : null;

  return (
    <div className="card content_panel_body chats_tab">
      <div className="card_header">
        <div className="card_title">
          <Message id="widget_code_chats_title" />
        </div>
        <div className="card_actions">
          <button
            className="btn btn-sm btn-outline-dark"
            disabled={reloadInProgress}
            onClick={reloadChats}
          >
            {reloadInProgress && <Spinner size={18} />}
            {!reloadInProgress && <RefreshCwIcon size={18} />}
            <Message id="chats_reload_button" />
          </button>
        </div>
      </div>
      <div className="chats_area_container">
        <div className="chat_list">
          <div className="chat_list_wrapper">
            {chatbot.chats.map((item) => {
              let metadata = {};

              if (item?.user?.metadata) {
                metadata = JSON.parse(item.user.metadata);
              }
              let ua = null;
              if (metadata.ua) {
                ua = deviceDetector.parse(metadata.ua);
                console.log("USER AGENT = ", ua);
              }
              console.log("User lang ", item.user.lang);
              return (
                <div
                  key={item.uid}
                  className={
                    "chat_item " + (item.uid === chat?.uid ? "selected" : "")
                  }
                  onClick={() => setChat(item)}
                >
                  <div className="item_user_first_message">
                    {cutText(item.firstUserMessage, 80)}
                  </div>
                  <div className="item_time">
                    {item?.user?.countryIsoCode && (
                      <ReactCountryFlag
                        countryCode={item.user.countryIsoCode}
                        svg
                        style={{
                          width: "1.4em",
                          height: "1.4em",
                        }}
                      />
                    )}
                    {ua && (
                      <DeviceTypeIcon
                        name={ua.device.type}
                        color="#67748e"
                        size="18"
                      />
                    )}
                    {ua && (
                      <DeviceVendorIcon
                        name={ua.device.brand}
                        color="#67748e"
                        size="18"
                      />
                    )}
                    {/*<ClockIcon size={16} />*/}
                    {dayjs(item.dateCreated).fromNow()}
                  </div>
                </div>
              );
            })}
          </div>

          <div key={"space_filler"} className="space_filler"></div>
        </div>
        <ChatMessageFeed messages={messages} />
      </div>
    </div>
  );
};

export default ChatsTab;
