import React, { useState } from "react";
import Spinner from "../../assets/icons/Spinner";
import UserXIcon from "../../assets/icons/UserXIcon";

import Message from "../../i18n/Message";
import ModalDialog from "../shared/ModalDialog";

const DeleteMemberDialog = ({ onClose, onDelete, member }) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    onDelete(member, onClose, () => setLoading(false));
  };

  return (
    <ModalDialog onClose={onClose} className="delete_member_dialog">
      <h1>
        <Message id="delete_member_dialog_title" />
      </h1>
      <div className="dialog_text">
        <Message
          id="delete_member_dialog_text"
          values={{ email: member.user.email }}
        />
      </div>
      <div className="buttons">
        <button
          className="btn bg-gradient-dark"
          onClick={onConfirm}
          disabled={loading}
        >
          {loading ? <Spinner /> : <UserXIcon color="#fff" />}
          <Message id="delete" />
        </button>
        <button className="btn no-btn" onClick={onClose}>
          <Message id="cancel" />
        </button>
      </div>
    </ModalDialog>
  );
};

export default DeleteMemberDialog;
