import { useState } from "react";
import LogoOpenAI from "../../../assets/icons/LogoOpenAI";
import TrashIcon from "../../../assets/icons/TrashIcon";
import ArrowUpIcon from "../../../assets/icons/ArrowUpIcon";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import UserIcon from "../../../assets/icons/UserIcon";
import Message from "../../../i18n/Message";

import "./ChatMessage.scss";

import MessageTextContainer from "./MessageTextContainer";
import Edit2Icon from "../../../assets/icons/Edit2Icon";
import { useDispatch } from "react-redux";
import { useWorkspaceId } from "../../shared/workspace/workspaceContext";
import {
  deleteChatMessage,
  updateChatMessage,
} from "../store/chatActionCreators";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import CrossIcon from "../../../assets/icons/CrossIcon";
import CheckIcon from "../../../assets/icons/CheckIcon";
import Spinner from "../../../assets/icons/Spinner";
import ClipboardIcon from "../../../assets/icons/ClipboardIcon";
import Notify from "../../shared/Notify";
import label from "../../../i18n/label";
import FileTextIcon from "../../../assets/icons/FileTextIcon";

const ChatMessage = ({ message }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const workspaceId = useWorkspaceId();
  const { chatId } = useParams();

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [content, setContent] = useState(message.content);
  const [messageElem, setMessageElem] = useState(false);
  const [messageHeight, setMessageHeight] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [messageCopied, setMessageCopied] = useState(false);

  const onDeleteConfirmed = () => {
    dispatch(deleteChatMessage(intl, workspaceId, chatId, message.uid));
    setDeleting(false);
  };
  const startEditing = () => {
    if (messageElem) {
      const messageHeight = messageElem.getBoundingClientRect().height;
      setMessageHeight(messageHeight + 32);
    }
    setEditing(true);
  };
  const cancelEditing = () => {
    setMessageHeight(null);
    setContent(message.content);
    setEditing(false);
  };
  const onContentChanged = (e) => {
    console.log("Content changed: ", e.target.value);
    setContent(e.target.value);
  };
  const saveEditing = () => {
    console.log("On saving...");
    if (!content) return;

    const onSuccess = () => {
      setMessageHeight(null);
      setEditing(false);
      setSaveInProgress(false);
      setHovering(false);
    };
    const onError = () => {
      setSaveInProgress(false);
    };
    setSaveInProgress(true);
    dispatch(
      updateChatMessage(
        intl,
        workspaceId,
        chatId,
        message.uid,
        content,
        onSuccess,
        onError
      )
    );
  };
  const onTextareaBlur = (e) => {
    console.log(
      "onTextareaBlur ======> ",
      messageElem,
      e.target,
      messageElem.contains(e.target),
      document.activeElement
    );
    if (content === message.content) {
      cancelEditing();
    }
  };
  //
  const onCopy = () => {
    if (!navigator.clipboard) {
      Notify.error(label(intl, "clipboard_copy_failed"));
      return;
    }

    navigator.clipboard.writeText(message.content).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 3000);
    });
  };

  const startDeleting = () => {
    setDeleting(true);
    setTimeout(() => setDeleting(false), 5000);
  };
  return (
    <>
      <div
        className={
          "chat_message " +
          (message.role === "user" ? "user" : "") +
          (message.role === "assistant" ? "assistant" : "")
        }
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="avatar">
          {message.role === "user" && (
            <div className="user_avatar">
              <UserIcon color="#777" />
            </div>
          )}
          {message.role === "assistant" && (
            <div className="openai_avatar">
              <LogoOpenAI color="#fff" />
            </div>
          )}
        </div>
        <div className="message_item" ref={(item) => setMessageElem(item)}>
          {!editing && (
            <>
              <MessageTextContainer>{message.content}</MessageTextContainer>
            </>
          )}
          {editing && (
            <div className="editing_container">
              <textarea
                className="form-control "
                autoFocus={true}
                disabled={saveInProgress}
                style={{
                  height: messageHeight ? messageHeight + "px" : "auto",
                }}
                onChange={onContentChanged}
                onBlur={onTextareaBlur}
                value={content}
              ></textarea>
              <div className="edit_actions">
                <button
                  className="btn btn-sm bg-gradient-dark tile-action"
                  disabled={saveInProgress}
                  onClick={saveEditing}
                >
                  {!saveInProgress && (
                    <CheckIcon size="16" color="#fff" strokeWidth="2" />
                  )}
                  {saveInProgress && <Spinner size="16" color="#fff" />}
                  <Message id="save" />
                </button>
                <button
                  className="btn btn-sm no-btn tile-action"
                  onClick={cancelEditing}
                >
                  <CrossIcon size="16" color="#344767" />
                  <Message id="cancel" />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="message_metadata">
          {!!message.filename && (
            <>
              <div className="message_metadata_item">
                <FileTextIcon color="rgb(109, 123, 147)" size="16" />
                {message.filename}
              </div>
            </>
          )}
          {!!message.inputTokenCount && (
            <div className="message_metadata_item">
              <ArrowUpIcon color="rgb(109, 123, 147)" size="16" />{" "}
              {message.inputTokenCount} <Message id="message_tokens" />
            </div>
          )}
          {!!message.outputTokenCount && (
            <div className="message_metadata_item">
              <ArrowDownIcon color="rgb(109, 123, 147)" size="16" />{" "}
              {message.outputTokenCount} <Message id="message_tokens" />
            </div>
          )}
        </div>
        {!editing && hovering && (
          <div className="message_cover">
            <div className="message_actions">
              <button
                className="btn btn-sm no-btn tile-action"
                onClick={onCopy}
              >
                {messageCopied && (
                  <CheckIcon size="16" color="#344767" strokeWidth="2" />
                )}
                {!messageCopied && <ClipboardIcon size="16" color="#344767" />}
                <Message id="copy" />
              </button>
              <button
                className="btn btn-sm no-btn tile-action"
                onClick={startEditing}
              >
                <Edit2Icon size="16" color="#344767" />
              </button>
              {!deleting && (
                <button
                  className="btn btn-sm no-btn tile-action"
                  onClick={startDeleting}
                >
                  <TrashIcon size="16" color="#344767" />
                </button>
              )}
              {deleting && (
                <button
                  className="btn bg-gradient-primary btn-sm"
                  onClick={onDeleteConfirmed}
                >
                  <Message id="confirm_delete" />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatMessage;
