import Edit2Icon from "../../../assets/icons/Edit2Icon";
import SettingsIcon from "../../../assets/icons/SettignsIcon";
import Message from "../../../i18n/Message";
import modelDescriptors from "../../../model/model-providers/modelDescriptors";
import "./ModelMessage.scss";

const ModelMessage = ({ chat, setShowSettings }) => {
  return (
    <div className="model_message">
      <div className="avatar">
        <div className="avatar_wrapper">
          <SettingsIcon color="#555" />
        </div>
      </div>
      <div className="message_body">
        <div>
          <Message
            id="model_message_model_name"
            values={{
              name: modelDescriptors.find((item) => item.id === chat.modelId)
                .name,
            }}
          />
        </div>
        <div className="system_prompt">
          <Message id="model_message_system_prompt" />: {chat.systemPrompt}
        </div>
      </div>
      <div className="message_actions">
        <button
          className="btn no-btn tile-action"
          onClick={() => setShowSettings(true)}
        >
          <Edit2Icon size="16" color="#344767" />
          <Message id="edit" />
        </button>
      </div>
    </div>
  );
};

export default ModelMessage;
