import Message from "../../../i18n/Message";
import label from "../../../i18n/label";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Notify from "../../shared/Notify";
import backend from "../../../backend/backend";
import { useDispatch } from "react-redux";
import { login } from "../../auth/store/actionCreators";
import authStore from "../../auth/AuthStore";

import "./RegisterPage.scss";
import ChatActions from "../../chats/store/chatActions";
import { useNavigate } from "react-router-dom";
import { linkToChat } from "../../../routes";
import Spinner from "../../../assets/icons/Spinner";
import { assignApiKey } from "../../../backend/httpAPI";
import Config from "../../../shared/Config";

const RegisterPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [passwordForm, setPasswordForm] = useState(null);

  const [name, setName] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingState, setLoadingState] = useState(true);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  useEffect(() => {
    const options = {
      credentials: "same-origin",
    };

    assignApiKey(options);
    fetch(Config.restUrl("/auth/info"), options)
      .then((res) => {
        if (!res.ok) {
          setLoadingState(false);
          return;
        } else {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const chatId = urlParams.get("cid");
          if (chatId) {
            backend
              .cloneChat(chatId)
              .then((newChat) => {
                console.log("Got new chat ", newChat);
                dispatch({
                  type: ChatActions.LOADED,
                  chat: newChat,
                });
                window.location =
                  "/app" + linkToChat(newChat.workspaceUid, newChat.chat.uid);
              })
              .catch((err) => {
                console.error("Error cloning the chat " + chatId, err);
                Notify.error(label(intl, "register_error"));
              });
          } else {
            window.location = "/app/";
          }
        }
      })
      .catch(() => {
        setLoadingState(false);
      });
  }, [setLoadingState]);

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  /*
  const onApiKeySubmit = () => {
    onSubmit();
  };
*/
  const onButtonClicked = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (email && password && passwordForm.checkValidity()) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      setSubmitInProgress(true);
      backend
        .register({
          email,
          password,
          name,
          plan: urlParams.get("plan"),
          period: urlParams.get("period"),
        })
        .then((resp) => {
          authStore.setToken(resp.token);
          let redirected = false;

          const onFinished = () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const chatId = urlParams.get("cid");
            if (chatId) {
              backend
                .cloneChat(chatId)
                .then((newChat) => {
                  console.log("Got new chat ", newChat);
                  dispatch({
                    type: ChatActions.LOADED,
                    chat: newChat,
                  });
                  setSubmitInProgress(false);
                  window.location =
                    "/app" + linkToChat(newChat.workspaceUid, newChat.chat.uid);
                })
                .catch((err) => {
                  console.error("Error cloning the chat " + chatId, err);
                  Notify.error(label(intl, "register_error"));
                  setSubmitInProgress(false);
                });
            } else {
              setSubmitInProgress(false);
              window.location = "/app/";
            }
          };

          const timeout = setTimeout(() => {
            if (!redirected) {
              //dispatch(login());
              onFinished();
            }
          }, 500);
          window.gtag("event", "sign_up", {
            event_callback: function () {
              redirected = true;
              clearTimeout(timeout);
              //dispatch(login());
              //window.location = "/app/";
              onFinished();
            },
          });
        })
        .catch((error) => {
          setSubmitInProgress(false);
          console.error("Error in registration", error);
          Notify.error(label(intl, "register_error"));
        });
    } else {
      passwordForm.reportValidity();
    }
  };

  return (
    <div className="register_page">
      <div className="logo">
        <img src="/images/promptleo-logo.svg" alt="PromptLeo" width="210" />
      </div>
      <div className="container">
        <div className="header">
          <h5>
            <Message id="register_title" />
          </h5>
        </div>
        {loadingState && <Spinner />}
        {!loadingState && (
          <div className="form">
            {/*
          <div className="separator first_separator">
            <Message id="login_via_social" />
          </div>
          <div className="social_buttons">
            <a href="/b/social/google/login" className="app-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 640 640"
              >
                <path d="M326.331 274.255v109.761h181.49c-7.37 47.115-54.886 138.002-181.49 138.002-109.242 0-198.369-90.485-198.369-202.006 0-111.509 89.127-201.995 198.369-201.995 62.127 0 103.761 26.516 127.525 49.359l86.883-83.635C484.99 31.512 412.741-.012 326.378-.012 149.494-.012 6.366 143.116 6.366 320c0 176.884 143.128 320.012 320.012 320.012 184.644 0 307.256-129.876 307.256-312.653 0-21-2.244-36.993-5.008-52.997l-302.248-.13-.047.024z" />
              </svg>
              <Message id="login_via_google" />
            </a>
            <a href="/b/social/facebook/login" className="app-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
              <Message id="login_via_facebook" />
            </a>
          </div>
          <div className="separator">
            <Message id="login_or" />
          </div>
        */}
            <form
              onSubmit={onPasswordSubmit}
              ref={(elem) => setPasswordForm(elem)}
            >
              <div className="field">
                <input
                  type="text"
                  autoComplete="name"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={label(intl, "register_name")}
                />
              </div>
              <div className="field">
                <input
                  type="email"
                  autoComplete="email"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={label(intl, "login_email")}
                />
              </div>
              <div className="field">
                <input
                  type="password"
                  autoComplete="current-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={label(intl, "login_password")}
                />
              </div>
              <div className="terms">
                <input
                  type="checkbox"
                  value={agreed}
                  id="flexCheckDefault"
                  checked={agreed}
                  required
                  onChange={() => setAgreed(!agreed)}
                />
                <label htmlFor="flexCheckDefault">
                  <Message id="register_agree" />{" "}
                  <a href="/p/terms" target="_blank" rel="noopener">
                    <Message id="register_terms" />
                  </a>{" "}
                  <Message id="register_and" />{" "}
                  <a href="/p/privacy-policy" target="_blank" rel="noopener">
                    <Message id="register_privacy" />
                  </a>
                </label>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="submit"
                  name="submit"
                  style={{ position: "absolute", left: "-10000px" }}
                />
              </div>
            </form>
            {/*
          <div className="separator">
            <Message id="login_or" />
          </div>
          <div className="field">
            <form onSubmit={onApiKeySubmit} ref={(elem) => setApiKeyForm(elem)}>
              <input
                type="text"
                autoComplete="api_key"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                required
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder={label(intl, "login_api_key")}
              />
            </form>
          </div>
           */}

            <div className="field">
              <button
                type="button"
                className="btn"
                disabled={submitInProgress}
                onClick={onButtonClicked}
              >
                {submitInProgress && <Spinner />}
                {!submitInProgress && <Message id="register_button" />}
              </button>
            </div>
            <div className="forgot_password">
              <a href="/app/">
                <Message id="login_login" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterPage;
