import Message from "../../../i18n/Message";

const PromptStatusBadge = ({ prompt }) => {
  if (prompt.approved) {
    return (
      <span className="badge badge-sm bg-success">
        <Message id="badge_approved" />
      </span>
    );
  } else {
    return (
      <span className="badge badge-sm bg-gradient-secondary">
        <Message id="badge_draft" />
      </span>
    );
  }
};

export default PromptStatusBadge;
