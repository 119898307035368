import Message from "../../../i18n/Message";

const PromptVersionsStatusBadge = ({ versions }) => {
  const approved = versions.find((item) => item.approved);

  if (approved) {
    return (
      <span className="badge badge-sm bg-success">
        <Message id="badge_approved" />
      </span>
    );
  } else {
    return (
      <span className="badge badge-sm bg-gradient-secondary">
        <Message id="badge_draft" />
      </span>
    );
  }
};

export default PromptVersionsStatusBadge;
