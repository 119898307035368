import produce from "immer";
import reducerUtils from "../../../store/reducerUtils";
import EndpointActions from "./EndpointActions";

const initialState = {
  data: {
    list: null,
    listCounter: 0,

    endpoint: null,
    requests: null,
    requestsCounter: 0,
  },
};

export const endpointSelectors = {
  getList: (state) => state.endpoints.data.list,
  getListCounter: (state) => state.endpoints.data.listCounter,
  getEndpoint: (state) => state.endpoints.data.endpoint,
  getEndpointRequests: (state) => state.endpoints.data.requests,
  getEndpointRequestsCounter: (state) => state.endpoints.data.requestsCounter,
};

const onLoadList = produce((draft, action) => {
  draft.data.list = action.list;
});

const onListChanged = produce((draft) => {
  draft.data.listCounter++;
});

const onEndpointLoaded = produce((draft, action) => {
  const obj = action.endpoint;
  draft.data.endpoint = obj;
});

const onEndpointUpdated = produce((draft, action) => {
  const obj = action.endpoint;
  draft.data.endpoint = obj;
  draft.data.listCounter++;
});

const onEndpointRequestsLoaded = produce((draft, action) => {
  draft.data.requests = action.requests;
});

const onEndpointRequestsChanged = produce((draft) => {
  draft.data.requestsCounter++;
});

const handlers = {
  [EndpointActions.LIST_LOADED]: onLoadList,
  [EndpointActions.LIST_CHANGED]: onListChanged,
  [EndpointActions.LOADED]: onEndpointLoaded,
  [EndpointActions.UPDATED]: onEndpointUpdated,
  [EndpointActions.REQUESTS_LOADED]: onEndpointRequestsLoaded,
  [EndpointActions.REQUESTS_CHANGED]: onEndpointRequestsChanged,
};

const endpointReducer = reducerUtils.createReducer(initialState, handlers);

export default endpointReducer;
